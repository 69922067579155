import http from '@/core/http'
import { map } from 'lodash'

const url = 'terminais/instalacao'

const state = {
  itens: [],
  item: {
    entity: {
      entityId: '',
    },
    chip: {
      chipId: '',
    },
    device: {
      deviceId: ''
    },
    password: '',
    passwordConfirm: ''
  },
  lista: '',
  itemAddPermission: [],
  acl: null,
  itemSelect: [],
  itemView: [],
  totalItens: 0,
  totalItemSelect: 0
}

const actions = {
  getItens: async ({ commit }, payload) => {
    const result = await http.get(url, { params: Object.assign(payload, { order: { 'installation.id': 'desc' }})})
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },

  cadastrarItem: async (_, payload) => {
    const result = await http.post(url, payload)
    return Promise.resolve(result)
  },

  editarItem: async (_, payload) => {
    const result = await http.put(url, payload)
    return Promise.resolve(result)
  },

  blockItem: async (_, payload) => {
    const result = await http.put(`${url}/bloquear`, payload)
    return Promise.resolve(result)
  },

  deletarItem: async (_, payload) => {
    const result = await http.delete(url, { params: payload })
    // if (!result.data.success) throw result
    return Promise.resolve(result)
  },

  getItemView: async ({ commit }, payload) => {
    const result = await http.get(`${url}/view`, { params: payload })
    commit('VIEW_ITEM', result.data)
    return Promise.resolve(result)
  },
  
  getItem: ({ commit }, payload) => {
    commit('LISTA_ITEM', payload)
  },

  limparItem: ({ commit }) => {
    commit('LIMPAR_ITEM')
  },

  obterItensSelectEstabelecimento: async ({ commit }, payload) => {
    let objFiltro = {}
    if (payload) {
      objFiltro = payload
    }

    const resultCaixa = await http.get(`entidades`,{ params: Object.assign(objFiltro, { order: { 'cod': 'ASC' }, entityTypeId: 5 }, ) })
    const resultEstabelecimento = await http.get(`entidades`,{ params: Object.assign(objFiltro, { order: { 'cod': 'ASC' }, entityTypeId: 4 }, ) })
    commit('LISTA_ITENS_SELECT', resultCaixa.data.resultMap.concat(resultEstabelecimento.data.resultMap))
  },

  clearItens: ({ commit }) => {
    commit('CLEAR_ITENS')
    commit('LIMPAR_ITEM')
  }
}

const mutations = {
  'LISTA_ITENS' (state, payload) {
    state.itens = payload.resultMap
    state.totalItens = payload.totalItens
  },

  'LISTA_ITEM' (state, payload) {
    state.item = Object.assign({}, payload)
  },

  'LIMPAR_ITEM' (state) {
    state.item = {
      entity: {
        entityId: '',
      },
      chip: {
        chipId: '',
      },
      device: {
        deviceId: ''
      },
      password: '',
      passwordConfirm: ''
    }
  },

  'LISTA_ITENS_SELECT' (state, payload) {
    state.itemSelect = payload
    // state.totalItemSelect = payload.totalItens
  },

  'VIEW_ITEM' (state, payload) {
    state.itemView = payload
  },

  'CLEAR_ITENS' (state) {
    state.itens = []
    state.totalItens = 0
  }
}

const getters = {
  listaItens: state => state.itens,
  totalItens: (state) => state.totalItens,
  totalItemSelect: (state) => state.totalItemSelect,
  item: state => state.item,
  itemView: state => state.itemView,
  listaItensSelect: (state) => {
    return map(state.itemSelect, item => {
      return {
        text: `${item.cod} - (${item.name})`,
        value: item.id
      }
    })
  }
}

export default { namespaced: true, state, actions, mutations, getters }