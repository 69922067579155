import http from '@/core/http'
const url = 'metas/listar'

const state = {
  itens: [],
  item: {
    refDate: '',
    salesReadjustmentIndex: '',
    debitIndex: '',
    quantityGoalValue: '',
    minimumResellerSalesValue: ''
  },
  itensSelect: []
}

const actions = {
   getItens: async ({ commit }, payload) => {
    
    const result = await http.get(url, { params: payload})
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },

  cadastrarItem: async (_, payload) => {
    const result = await http.post(url, payload)
    return Promise.resolve(result)
  },

  editarItem: async (_, payload) => {
    const result = await http.put(url, payload)
    return Promise.resolve(result)
  },

  deletarItem: async (_, payload) => {
    const result = await http.delete(url, { params: payload })
    return Promise.resolve(result)
  },

  buscarItem: async ({ commit }, payload) => {
    const result = await http.post(`${url}/view`, payload)
    commit('LISTA_ITEM', result.data)
  },

  getItem: ({ commit }, payload) => {
    commit('LISTA_ITEM', payload)
  },

  limparItem: ({ commit }) => {
    commit('LIMPAR_ITEM')
  },

  clearItens: ({ commit }) => {
    commit('CLEAR_ITENS')
    commit('LIMPAR_ITEM')
  }
}

const mutations = {  
  'LISTA_ITENS' (state, payload) {
    state.itens = payload.resultMap[0]
    state.totalItens = payload.resultMap[1]
  },

  'LISTA_ITEM' (state, payload) {
    state.item = Object.assign({}, payload)
  },

  'LIMPAR_ITEM' (state) {
    state.item = {
      refDate: '',
      salesReadjustmentIndex: '',
      debitIndex: '',
      quantityGoalValue: '',
      minimumResellerSalesValue: ''
    }
  },

  'CLEAR_ITENS' (state) {
    state.itens = []
    state.totalItens = 0
  }
}

const getters = {
  listaItens: state => {
    return state.itens
  },
  totalItens: (state) => state.totalItens,
  item: state => state.item
}

export default { namespaced: true, state, actions, mutations, getters }