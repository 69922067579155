export default [
  {
    path: 'consultas',
    name: 'consultas',
    component: () => import('./index'),
    children: [],
    meta: {
      visible: false,
      title: 'Consultas',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Consultas' },
      ]
    }
  },
]