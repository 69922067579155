export default [
  {
    path: '/gestao/loterias/resultados/cadastro-resultados/',
    name: 'cadastro-resultados',
    component: () => import('./index'),
    children: [],
    meta: {
      visible: true,
      title: 'cadastro-resultados'
    }
  },
]
// export default [
//   {
//     path: 'gestao/loterias/resultados/cadastro-resultados/',
//     name: 'cadastro-resultados',
//     component: () => import('./index'),
//     meta: {
//       visible: true,
//       title: 'Resultados',
//       // breadcrumb: [
//       //   { name: 'Home', link: 'home' },
//       //   { name: 'Gestão', link: 'gestao' },
//       //   { name: 'Loterias', link: 'loterias' },
//       //   { name: 'Cadastro Resultados' }
//       // ]
//     }
//   }
// ]