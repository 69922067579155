import http from '@/core/http'

import { map, compact, forEach, merge } from 'lodash'

const url = 'terminais/chips'

const state = {
  itens: [],
  item: {
    iccid: '',
    phoneNumber: '',
    imsi: '',
    chipCarrierId: '',
    chipContractId: ''
  },
  lista: '',
  itemAddPermission: [],
  acl: null,
  itemSelect: [],
  totalItens: 0,
  totalItemSelect: 0,
  listCarriers: [],
  listContracts: []
}

const actions = {
  // getItens: async ({ commit }, payload) => {
  //   const result = await http.get(url, { params: Object.assign(payload, { order: { id: 'desc' }})})
  //   commit('LISTA_ITENS', result.data)
  //   return Promise.resolve(result)
  // },

  getItens: async ({ commit, rootGetters }, payload) => {
    if (!payload) payload = {}

    let _filter = { 'deleted_>=':'01/01/1971' }

    if (payload.hasOwnProperty('_filter') && rootGetters['globalStore/isStatus']) {
      payload._filter = merge(payload._filter, _filter)
    } else if (rootGetters['globalStore/isStatus']) {
      payload = Object.assign(payload, { _filter })
    }

    const result = await http.get(
      url, 
      { params: Object.assign(payload, { order: { id: 'desc' }})}
    )
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },

  getItensNotInstallations: async ({ commit }, payload) => {
    const result = await http.get(`${url}/list-available`, { params: payload })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },

  cadastrarItem: async (_, payload) => {
    const result = await http.post(url, payload)
    return Promise.resolve(result)
  },

  editarItem: async (_, payload) => {
    const result = await http.put(url, payload)
    return Promise.resolve(result)
  },

  deletarItem: async (_, payload) => {
    const result = await http.delete(url, { params: payload })
    return Promise.resolve(result)
  },

  buscarItem: async ({ commit }, payload) => {
    const result = await http.post(`${url}/view`, payload)
    commit('LISTA_ITEM', result.data)
  },

  getItem: ({ commit }, payload) => {
    commit('LISTA_ITEM', payload)
  },

  limparItem: ({ commit }) => {
    commit('LIMPAR_ITEM')
  },

  obterItensSelect: async ({ commit }) => {
    const result = await http.get(`entidades/list-ats`)
    commit('LISTA_ITENS_SELECT', result.data)
  },

  getCarriers: async ({ commit }) => {
    const result = await http.get(`${url}/operadoras/`)
    commit('LIST_CARRIERS', result.data)
  },

  getContracts: async ({ commit }) => {
    const result = await http.get(`${url}/contratos/`)
    commit('LIST_CONTRACTS', result.data)
  },

  clearItens: ({ commit }) => {
    commit('CLEAR_ITENS')
    commit('LIMPAR_ITEM')
  }
}

const mutations = {
  'LISTA_ITENS' (state, payload) {
    state.itens = payload.resultMap
    state.totalItens = payload.totalItens
  },

  'LISTA_ITEM' (state, payload) {
    state.item = Object.assign({}, payload)
  },

  'LIMPAR_ITEM' (state) {
    state.item = {
      iccid: '',
      phoneNumber: '',
      imsi: '',
      chipCarrierId: '',
      chipContractId: ''
    }
  },

  'LISTA_ITENS_SELECT' (state, payload) {
    state.itemSelect = payload.resultMap
    state.totalItemSelect = payload.totalItens
  },

  'LIST_CARRIERS' (state, payload) {
    delete payload.date
    state.listCarriers = payload
  },

  'LIST_CONTRACTS' (state, payload) {
    delete payload.date
    state.listContracts = payload
  },

  'CLEAR_ITENS' (state) {
    state.itens = []
    state.totalItens = 0
  }
}

const getters = {
  listaItens: state => state.itens,
  totalItens: (state) => state.totalItens,
  totalItemSelect: (state) => state.totalItemSelect,
  item: state => state.item,
  listaItensSelect: (state) => {
    return map(state.itens, item => {
      return {
        text: item.phoneNumber,
        value: item.id
      }
    })
  },
  listaItensSelectFilter: (state, getters, rootState, rootGetters) => {
    let chipsInstalations = [] 
    forEach(rootGetters['installations/listaItens'], item => {
      chipsInstalations.push(item.chip.chipId)
    })
  
    return compact(map(state.itens, item => {
      if (!chipsInstalations.includes(item.id)) {
        return {
          text: item.phoneNumber,
          value: item.id
        }
      }
    }))
  },
  listCarriers: state => map(state.listCarriers, item => ({
      text: item.name,
      value: item.id
  })),
  listContracts: state => map(state.listContracts, item => ({
      text: item.name,
      value: item.id
  }))
}

export default { namespaced: true, state, actions, mutations, getters }