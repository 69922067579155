import http from '@/core/http'
import moment from 'moment'

const url = 'lista-bonificacoes'

const state = {
  itens: [],
  item: {
    aliquot: '',
    cod: '',
    name: '',
    dtEnd: '',
    dtStart: '',
    entityId: '',
    sizeLimit: '',
    value: ''
  },
  lista: '',
  itemAddPermission: [],
  acl: null,
  itensSelect: [],
  totalItens: 0,
  credits: [],
  entities: []
}

const actions = {
  getItens: async ({ commit }, payload) => {
    const result = await http.get(url, { params: Object.assign(payload, { order: { id: 'desc' }}) })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },

  getBonificationsCredits: async ({ commit }, payload) => {
    const result = await http.get('bonificacoes/credits', { params: { bonificationId: payload.id } })
    commit('LISTA_CREDITS', result.data)
    return Promise.resolve(result)
  },

  getBonificationsEntities: async ({ commit }, payload) => {
    const result = await http.get('bonificacoes/entities', { params: { bonificationId: payload.id } })
    commit('LISTA_ENTITIES', result.data)
    return Promise.resolve(result)
  },
  
  cadastrarItem: async (_, payload) => {
    const result = await http.post(url, payload)
    return Promise.resolve(result)
  },

  editarItem: async (_, payload) => {
    const result = await http.put(url, payload)
    return Promise.resolve(result)
  },

  deletarItem: async (_, payload) => {
    const result = await http.delete(url, {params: payload})
    return Promise.resolve(result)
  },

  getItem: ({ commit }, payload) => {
    commit('LISTA_ITEM', payload)
  },

  limparItem: ({ commit }) => {
    commit('LIMPAR_ITEM')
  },

  clearItensModal: ({ commit }) => {
    commit('CLEAR_ITENS_MODAL')
  },

  obterItensSelect: async ({ commit }, payload = {}) => {
    if (payload && payload.hasOwnProperty('_filter')) {
      payload['_filter'] = Object.assign(payload['_filter'], { 'entityTypeId_In': '1,2,3,4' })
    } else {
      payload['_filter'] = { 'entityTypeId_In': '1,2,3,4' }
    }

    const result = await http.get('entidades/list-tree', { params: payload, order: 'DESC' })
    commit('LISTA_ITENS_SELECT', result.data)
    return Promise.resolve(result)
  }
}

const mutations = {
  'LISTA_ITENS' (state, payload) {
    state.itens = payload.resultMap
    state.totalItens = payload.totalItens
  },

  'LISTA_CREDITS' (state, payload) {
    state.credits = payload.resultMap
  },

  'LISTA_ENTITIES' (state, payload) {
    state.entities = payload.resultMap
  },

  'LISTA_ITEM' (state, payload) {
    state.item = Object.assign({}, payload)
    state.item.dtEnd = moment(state.item.dtEnd, 'DD/MM/YYYY HH:mm').toDate()
    state.item.dtStart = moment(state.item.dtStart, 'DD/MM/YYYY HH:mm').toDate()
  },

  'LIMPAR_ITEM' (state) {
    state.item = {
      aliquot: '',
      name: '',
      cod: '',
      dtEnd: '',
      dtStart: '',
      entityId: '',
      sizeLimit: '',
      value: ''
    }
  },

  'CLEAR_ITENS_MODAL' (state) {
    state.credits = []
    state.entities = []
  },

  'LISTA_ITENS_SELECT' (state, payload) {
    state.itensSelect = payload
  }
}

const getters = {
  listaItens: state => state.itens,
  totalItens: (state) => state.totalItens,
  item: state => state.item,
  itensSelect: state => state.itensSelect,
  credits: state => state.credits,
  entities: state => state.entities,
  listItensSelect: state => state.itens.map(item => ({ text: item.cod, value: item.id }))
}

export default { namespaced: true, state, actions, mutations, getters }