export default [
  {
    path: 'g-fin',
    name: 'g-fin',
    component: () => import('./index'),
    children: [],
    meta: {
      visible: false,
      title: 'Financeiro',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Financeiro' }
      ]
    }
  },
]