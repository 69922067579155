import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from '@/views/routes'
import before from './before'
import after from './after'
import permissaoModulos from './permissaoModulos'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach(before)
router.beforeEach(permissaoModulos)
router.afterEach(after)

export default router