const state = {
  isStatus: false
}

const mutations = {
  SWITCH_STATUS: state => state.isStatus = !state.isStatus,
  CLEAR_SWITCH_STATUS: state => state.isStatus = false
}

const actions = {
  switchStatus ({ commit }) {
    commit('SWITCH_STATUS')
  },
  clearSwitchStatus ({ commit }) {
    commit('CLEAR_SWITCH_STATUS')
  }
}

const getters = {
  isStatus: state => state.isStatus
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
