import http from '@/core/http'
import { map } from 'lodash'
import moment from 'moment'

const state = {
  holidays: [],
  holidaysDates: []
}
const actions = {
  getHolidays: async ({commit}) => {
    const result = await http.get('/feriados') 
    commit('HOLIDAYS', result.data.result)
  },
  addHoliday: async (_, payload) => {
    const result = await http.post('/feriados', {...payload})
    return Promise.resolve(result)
  },
  getHolidaysDates: async ({commit}, payload) => {
    const result = await http.get('/feriados/datas', {...payload})
    commit('HOLIDAYS_DATES', result.data.holidaysDates)
    return Promise.resolve(result.data.holidaysDates)
  }
}
const mutations = {
  'HOLIDAYS' (state, payload) {
    state.holidays = payload
  },
  'HOLIDAYS_DATES' (state, payload) {
    state.holidaysDates = payload
  }
}
const getters = {
  holidaysDates: state => state.holidaysDates,
  holidays: state => {
    return map(state.holidays, holiday => {
      return {
        ref_date: moment(holiday.ref_date).format('DD/MM/YYYY'),
        description: holiday.description
      } 
    })
  }
}

export default { namespaced: true, state, actions, mutations, getters }