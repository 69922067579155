export default [
  {
    path: 'esportes',
    name: 'esportes',
    component: () => import('./index'),
    meta: {
      visible: false,
      title: 'Esportes',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Esportes' }
      ]
    }
  }
]