const state = {
  page: 1,
}

const actions = {
  setPage: ({ commit }, payload) => {
    commit('SET_PAGE', payload)
  },

  clearPage: ({ commit }, payload) => {
    commit('CLEAR_PAGE', payload)
  }
}

const mutations = {
  'SET_PAGE' (state, payload) {
    state.page = payload
  },

  'CLEAR_PAGE' (state) {
    state.page = 1
  }
}

const getters = {
  page: state => state.page,
}

export default { namespaced: true, state, actions, mutations, getters }