export default [
  {
    path: 'gestao/loterias/cotacao-dinamica',
    name: 'cotacao-dinamica',
    component: () => import('./index'),
    meta: {
      visible: true,
      title: 'Cotação Dinâmica',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Gestão', link: 'gestao' },
        { name: 'Loterias', link: 'loterias' },
        { name: 'Cotação Dinâmica' }
      ]
    }
  }
]