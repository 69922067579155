import http from '@/core/http'
import { each, map } from 'lodash'
import Vue from 'vue'

const url = 'ganhadores'

const state = {
  itens: [],
  item: [],
  itemSelect: [],
  totalItens: 0,
  userList: [],
  resultInst: null,
}

const actions = {
  clearItens: async ({commit}) => {
    commit('CLEAR_ALLL')
    return Promise.resolve(true)
  },
  setSubTable: async ({commit}, payload) => { // para premios web
    if (payload) {
      let params = { ids: map(payload.lista, v => v.winnerId) }
      // eslint-disable-next-line no-unused-vars
      const result = await http.get(`${url}/premio-web`, { params })
      console.log(result.data.resultMap)
      let itens = []
      each(result.data.resultMap, (obj) => {
        each(obj.guessWinners, v => {
          itens.push({
            modality: obj.modality.name,
            guess: { guess: v.value },
            prizeRange: v.prizeRange,
            guessValue: v.guessValue,
            prizeValue: v.prizeValue,
            guesses: v.guesses,
            lottery: obj.sweep.cod,
            games:{ gameNumber: obj.games.gameNumber, guessWinners: [{}]},
            totais: {
              guessValue: 0
            }
          })
        })
      })
      payload.format = itens
      commit('ADD_WINNERS', payload)
    }
  },
  getItens: async ({ commit }, payload) => { // lista premios baixos
    const result = await http.get(url, { params: payload })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },

  getPremioAltos: async ({ commit }, payload) => {
    const result = await http.get(`${url}/premios-altos`, { params: payload })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },

  limparItem: ({ commit }) => {
    commit('LIMPAR_ITEM')
  },

  getUsers: async ({ commit }, payload) => {
    const headers = { moduloname: 'gestao' }
    const result = await http.get('/autenticacao/usuarios', { params: payload, headers })
    commit('GET_USERS', result.data.mapResult)
    return Promise.resolve(result.data.mapResult)
  },

  filterWinners: async ({ commit }, payload) => {
    const result = await http.get('ganhadores/filter', { params: payload })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result.data)
  },

  getResultsInst: async ({ commit }, payload) => {
    const result = await http.get('ganhadores/resultado-instantanea', { params: payload })
    commit('SET_RESULT_INST', result.data.data)
    return Promise.resolve(result.data)
  }
}

const mutations = {
  'LISTA_ITENS' (state, payload) {
    state.itens = payload.resultMap
    state.totalItens = payload.totalItens
  },

  'LISTA_ITEM' (state, payload) {
    state.item = Object.assign({}, payload)
  },

  'LIMPAR_ITEM' (state) {
    state.item = {
      name: '',
      produtoId: '',
      product: {
        name: ''
      }
    }
  },

  'LISTA_ITENS_SELECT' (state, payload) {
    state.itemSelect = payload
  },

  'SET_RESULT_INST' (state, payload) {
    state.resultInst = payload
  },

  'ADD_WINNERS' (state, payload) {
    Vue.set(state.itens[payload.key], 'new', payload.format)
  },
  'CLEAR_ALLL' (state) {
    state.itens = []
    state.item = []
    state.itemSelect = []
  },

  'GET_USERS' (state, payload) { state.userList = payload }
}

const getters = {
  listaItens: state => state.itens,
  totalItens: (state) => state.totalItens,
  item: state => state.item,
  userList: state => state.userList.map(it => ({
    text: it.name,
    value: it.id
  })),
  listaItensSelect: (state) => {
    return map(state.itens, item => {
      return {
        text: item.name,
        value: item.id
      }
    })
  },
  resultInst: state => mapResult(state.resultInst),
}

function mapResult (results) {
  if (!results) return null

  const resultadoNumber = results.filter(it => it.data.number).map(v => {
    v.maxResult = false
    v.numberFormat = String(v.data.number).trim().split('')
    v.groupFormat = String(v.group)
    return v
  })

  const resultadoMax = results.filter(it => it.data.max).map(v => {
    v.maxResult = false
    v.numberFormat = String(v.data.max).match(/.{1,2}/g)
    v.groupFormat = String(v.group)
    return v
  })

  return [resultadoNumber, resultadoMax]
}

export default { namespaced: true, state, actions, mutations, getters }