export default [
  {
    path: 'gestao/jogos',
    name: 'jogos',
    component: () => import('./index'),
    children: [],
    meta: {
      visible: true,
      title: 'Jogos',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Gestão', link: 'gestao' },
        { name: 'Cancelamento', },
      ]
    }
  },
]