export default [
  {
    path: 'esportes/bilhetes',
    name: 'bilhetes',
    component: () => import('./index'),
    children: [],
    meta: {
      visible: true,
      title: 'Bilhetes',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Esportes', link: 'esportes' },
        { name: 'Bilhetes' },
      ]
    }
  },
]