// eslint-disable-next-line no-unused-vars
import http from '@/core/http'
import { map, merge } from 'lodash'
const url = 'resgate-premios'

const state = {
  item: {
    birth: '',
    date: '',
    entityId: '',
    entryId: '',
    id: '',
    name: '',
    pendingOperationTypeId: '',
    pendingOperationTypeName: '',
    status: '',
    prize: {}
  },
  itens: [{
    id: '',
    name: '',
    username: '',
    email: '',
    roles: ''
  }],
  totalItens: 0,
  userEntities: [],
  dados: [],
  baixar: {},
  form: {
    conta: '',
    categoria: ''
  },
  isStatus: false
}

const actions = {
  getItens: async ({ commit }, payload) => {
    if (!payload) payload = {}

    let _filter = { 'pendingOperation:status_ilike': '%B%' }

    if (payload.hasOwnProperty('_filter') && state.isStatus) {
      payload._filter = merge(payload._filter, _filter)
    } else if (state.isStatus) {
      payload = Object.assign(payload, { _filter })
    } else  {
      payload = { _filter: { 'pendingOperation:status_ilike': '%N%' }}
    }

    const result = await http.get(url, { params: { module: 'Prêmios', ...payload , order: {id: 'desc'}} })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },
  cadastrarItem: async (_, payload) => {
    const result = await http.post(url, payload)
    return Promise.resolve(result.data)
  },
  getIten: async ({ commit }) => {
    const result = await http.get(`${url}/view`)
    commit('LISTA_ITEM', result.data)
  },
  editarItem: async (_, payload) => {
    const result = await http.put(url, payload)
    return Promise.resolve(result.data)
  },

  prescreverPremio: async (_, payload) => {
    const result = await http.delete(url, { params: payload })
    return Promise.resolve(result.data)
  },
  editSaveItem: async ({commit }, payload) => {
    const result = await http.put(url, payload)
    commit('EDITAR_ITEM', result )
  },
  deletarItem: async (_, payload) => {
    const result = await http.delete(url, {params: payload})
    return Promise.resolve(result.data)
  },
  limparItem: ({ commit }) => {
    commit('LIMPAR_ITEM')
  },

  // User Entities
  cadastrarUserEntities: async (_, payload) => {
    const result = await http.post('autenticacao/user-entities', payload)
    return Promise.resolve(result.data)
  },

  getItemUsersEntities: async ({ commit }, payload) => {
    // const result = await http.get('user-entities/list-all')
    commit('LIST_ITENS_USERS_ENTITIES', payload)
  },
  listDadosApi: async ({ commit }, payload) => {
    const result = await http.get(`${url}/teste`)
    commit('ADD_DADOS_ORGANIZZE', {baixa: payload, dados: result.data})
  },

  clearItens: ({ commit }) => {
    commit('CLEAR_ITENS')
    commit('LIMPAR_ITEM')
  },

  switchStatus ({ commit }) {
    commit('SWITCH_STATUS')
  },
}

const mutations = {
  'ADD_DADOS_ORGANIZZE' (state, {baixa, dados}) {
    state.dados = dados
    state.baixa = baixa
  },
  'LISTA_ITENS' (state, payload) {
    state.itens = payload.resultMap
    state.totalItens = payload.totalItens
  },

  'LISTA_ITEM' (state, payload) {
    state.item = Object.assign({}, payload)
  },

  'EDITAR_ITEM' (state, payload) {
    state.item = Object.assign({}, payload)
  },

  'LIMPAR_ITEM' (state) {
    state.item = {
      birth: '',
      date: '',
      entityId: '',
      entryId: '',
      id: '',
      name: '',
      pendingOperationTypeId: '',
      pendingOperationTypeName: '',
      status: '',
      value: '',
    },
    state.userEntities = []
  },

  'LIST_ITENS_USERS_ENTITIES' (state, payload) {
    state.userEntities = payload
  },

  'CLEAR_ITENS' (state) {
    state.itens = []
    state.totalItens = 0
  },

  'SWITCH_STATUS' (state) {
    state.isStatus = !state.isStatus
  }
}

const getters = {
  listaItens: (state) => state.itens,
  totalItens: (state) => state.totalItens,
  getItem: (state) => state.item,
  listUserEntities: state => state.userEntities,
  getCategorias: (state) => map(state.dados.listCategorias, v => ({
    name: v.name,
    id: v.id,
    color: v.color
  })),
  getContas: (state) => map(state.dados.listContas, v => ({
    name: v.name,
    id: v.id,
    descricao: v.description
  })),
  isStatus: state => state.isStatus
}

export default { namespaced: true, state, actions, mutations, getters }