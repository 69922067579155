import http from '@/core/http'
import { map } from 'lodash'

const url = 'entidades/caixa'

const state = {
  itens: [],
  item: {
    id: '',
    parent: {
      id: '',
      name: ''
    },
    cod: '',
    name: '',
    type: ''
  },
  itemSelect: [],
  totalItens: 0,
  totalItemSelect: 0
}

const getTypeCaixa = (cod) => {
  const arrayCod = cod.split('-')
  const type = arrayCod[arrayCod.length - 2]
  return type
}

const getCod = (cod) => {
  const arrayCod = cod.split('-')
  return arrayCod[arrayCod.length - 1]
}

const getEstabelecimento = (cod) => {
  const arrayCod = cod.split('-')
  // let concatEstabelcimento = ''
  // arrayCod.forEach((item, index) => {
  //   const limite = arrayCod.length - 2
  //   if (index < limite) {
  //     concatEstabelcimento += item + '-'
  //   }
  // })

  return arrayCod.slice(0, arrayCod.length - 2).join('-')
}

const actions = {
  getItens: async ({ commit }, payload) => {
    const result = await http.get(`${url}`, { params: payload })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },

  cadastrarItem: async (_, payload) => {
    const result = await http.post(`${url}`, payload)
    return Promise.resolve(result)
  },

  editarItem: async (_, payload) => {
    const result = await http.put(`${url}`, payload)
    return Promise.resolve(result)
  },

  deletarItem: async (_, payload) => {
    const result = await http.delete(`${url}`, { params: payload })
    return Promise.resolve(result)
  },

  buscarItem: async ({ commit }, payload) => {
    const result = await http.get(`${url}/view`, payload)
    commit('LISTA_ITEM', result.data)
  },

  getItem: ({ commit }, payload) => {
    commit('LISTA_ITEM', payload)
    return Promise.resolve(payload)
  },

  limparItem: ({ commit }) => {
    commit('LIMPAR_ITEM')
  },

  obterItensSelect: async ({ commit }, payload) => {
    const result = await http.get(`entidades/estabelecimento`, { params: payload })
    commit('LISTA_ITENS_SELECT', result.data)
  },

  clearItens: ({ commit }) => {
    commit('CLEAR_ITENS')
    commit('LIMPAR_ITEM')
  },
}

const mutations = {
  'LISTA_ITENS' (state, payload) {
    state.itens = payload.resultMap
    state.totalItens = payload.totalItens
  },

  'LISTA_ITEM' (state, payload) {
    state.item = Object.assign({}, payload)
    state.item.type = getTypeCaixa(payload.cod)
    state.item.cod = getCod(payload.cod)
    state.item.codEstabelecimento = getEstabelecimento(payload.cod)
    state.item.parentId = {
      text: state.item.parent.jsonData.name,
      value: state.item.parent.id,
      cod: state.item.parent.cod
    }
  },

  'LIMPAR_ITEM' (state) {
    state.item = {
      id: '',
      parent: {
        id: '',
        name: ''
      },
      cod: '',
      name: ''
    }
  },

  'LISTA_ITENS_SELECT' (state, payload) {
    state.itemSelect = payload.resultMap
    state.totalItemSelect = payload.totalItens
  },

  'CLEAR_ITENS' (state) {
    state.itens = []
    state.itensSelect = []
    state.totalItens = 0
  }
}

const getters = {
  listaItens: state => state.itens,
  totalItens: (state) => state.totalItens,
  totalItemSelect: (state) => state.totalItemSelect,
  item: state => state.item,
  listaItensSelect: (state) => {
    return map(state.itemSelect, item => {
      return {
        text: item.name,
        value: item.id,
        cod: item.cod
      }
    })
  }
}

export default { namespaced: true, state, actions, mutations, getters }