export default [
  {
    path: 'g-fin/despacho',
    name: 'despacho',
    component: () => import('./index'),
    children: [],
    meta: {
      visible: false,
      title: 'Despacho',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'G-Fin', link: 'g-fin' },
        { name: 'Despacho' }
      ]
    }
  },
]