import http from '@/core/http'
// eslint-disable-next-line
import { map } from 'lodash'

const endpoint = 'aniversariantes'

const state = {
  birthdays: []
}

const actions = {
  getData: async ({ commit }, payload) => {
    const result = await http.get(endpoint, { params: payload })
    commit('SET_RESULT', result.data)
    return Promise.resolve(result)
  },

  getItem: ({ commit }, payload) => {
    commit('LISTA_ITEM', payload)
  },

  limparItem: ({ commit }) => {
    commit('LIMPAR_ITEM')
  },

  setHasMonthOrDate: ({ commit }, payload) => {
    commit('SET_HAS_MONTH_OR_DATE', payload)
  }
}

const mutations = {
  'SET_RESULT' (state, payload) {
    state.birthdays = payload.resultMap
    state.totalBirthdays = payload.totalItens
  }
}

const getters = {
  data: state => state.birthdays,
  totalItens: (state) => state.totalItens,
  transmissoes: state => state.transmissoes,
}

export default { namespaced: true, state, actions, mutations, getters }