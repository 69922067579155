export default [{
  path: 'd-fin/gefin-financeiro/lancamentos',
  name: 'lancamentos',
  component: () => import('./index'),
  meta: {
    visible: true,
    title: 'Lançamentos',
    order: '01',
    breadcrumb: [
      { name: 'Home', link: 'home' },
      { name: 'GEFIN', link: 'gefin' },
      { name: 'Financeiro', link: 'gefin-financeiro' },
      { name: 'Lançamentos' }
    ]
  }
}]
