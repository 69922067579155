import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify/index'
import router from './core/router'
import store from './core/store'
import Vuelidate from 'vuelidate'
import VueSweetalert2 from 'vue-sweetalert2'
import GetTextPlugin from 'vue-gettext'
import translations from './core/i18n/translations.json'
import filters from '@/core/filters'
import VCurrencyField from 'v-currency-field'
import { VTextField } from 'vuetify/lib'  //Globally import VTextField
import './registerServiceWorker'
import * as VueGoogleMaps from 'vue2-google-maps'
import VCalendar from 'v-calendar'


Vue.component('v-text-field', VTextField)
Vue.use(VCurrencyField, { 
	locale: 'pt-BR',
	decimalLength: 2,
	autoDecimalMode: true,
	min: null,
	max: null,
	defaultValue: 0,
  valueAsInteger: false,
  allowNegative: true
})

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyD1PUja1fvW0QilSCknKKV8QjLdH_GzsgQ',
    libraries: 'places'
  },
})

Vue.use(VCalendar, {
  componentPrefix: 'vc',
})

Vue.use(GetTextPlugin, {
  availableLanguages: {
    en_US: 'American English',
    pt_BR: 'Português',
    es_ES: 'Espanhol'
  },
  defaultLanguage: 'pt_BR',
  silent: true,
  translations: translations
})

import 'sweetalert2/dist/sweetalert2.min.css';
import 'animate.css'

const options = {
  customClass: {
    container: 'container-popup',
    confirmButton: 'br-btn',
    cancelButton: 'br-btn br-btn-cancelar bt-btn-cancelar-popup'
  },
  showClass: {
    popup: 'animated zoomIn'
  },
  hideClass: {
    popup: 'animated zoomOut'
  },
  showCloseButton: true
} 

// add validation system
Vue.use(Vuelidate)

Vue.use(VueSweetalert2, options)

// adiciona filtros personalizados
Vue.use(filters)

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
