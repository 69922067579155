import http from '@/core/http'

const endPoint = 'despesas'

const state = {
  itens: [],
  item: {
    value: '',
    dtReference: '',
    user: null,
    description: '',
    type: ''
  },
  totalItens: 0
}

const actions = {
  getItens: async ({ commit }, payload) => {
    const result = await http.get(endPoint, { params: payload })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },

  cadastrarItem: async (_, payload) => {
    const result = await http.post(endPoint, payload)
    return Promise.resolve(result)
  },

  editarItem: async (_, payload) => {
    const result = await http.put(endPoint, payload)
    return Promise.resolve(result)
  },

  deletarItem: async (_, payload) => {
    const result = await http.delete(endPoint, {params: payload})
    return Promise.resolve(result)
  },

  getItem: ({ commit }, payload) => {
    commit('LISTA_ITEM', payload)
  },

  limparItem: ({ commit }) => {
    commit('LIMPAR_ITEM')
  },

  clearItens: ({ commit }) => {
    commit('CLEAR_ITENS')
    commit('LIMPAR_ITEM')
  }
}

const mutations = {
  'LISTA_ITENS' (state, payload) {
    state.itens = payload.resultMap
    state.totalItens = payload.totalItens
  },

  'LISTA_ITEM' (state, payload) {
    state.item = Object.assign({}, payload)
  },

  'LIMPAR_ITEM' (state) {
    state.item = {
      value: '',
      dtReference: '',
      user: null,
      description: '',
      type: ''
    }
  },

  'LISTA_ITENS_SELECT' (state, payload) {
    state.itemSelect = payload
  },

  'CLEAR_ITENS' (state) {
    state.itens = []
    state.totalItens = 0
  }
}

const getters = {
  listaItens: state => state.itens,
  totalItens: (state) => state.totalItens,
  item: state => state.item,
}

export default { namespaced: true, state, actions, mutations, getters }