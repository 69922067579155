import http from '@/core/http'
import { map } from 'lodash'

const endPoint = 'terminais/device-types'

const state = {
  item: {
    msg: ''
  }
}

const actions = {
  getItens: async ({ commit }, payload) => {
    const result = await http.get('terminais/msg-rodape', { params: payload })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },

  cadastrarItem: async (_, payload) => {
    const result = await http.post('terminais/msg-rodape', payload)
    return Promise.resolve(result)
  },

  editarItem: async (_, payload) => {
    const result = await http.put(endPoint, payload)
    if (!result.data.id) throw result
    return Promise.resolve(result)
  },

  deletarItem: async (_, payload) => {
    const result = await http.delete(endPoint, { params: payload })
    // if (!result.data.success) throw result
    return Promise.resolve(result)
  },

  limparItem: ({ commit }) => {
    commit('LIMPAR_ITEM')
  }
}

const mutations = {
  'LISTA_ITENS' (state, payload) {
    state.item = payload.resultMap[0]
    state.totalItens = payload.totalItens
  },

  'LISTA_ITEM' (state, payload) {
    state.item = Object.assign({}, payload)
  },

  'LIMPAR_ITEM' (state) {
    state.item = {
      deviceVersion: '',
      serial: ''
    }
  },

  'LISTA_ITENS_SELECT' (state, payload) {
    state.itemSelect = payload
  }
}

const getters = {
  listaItens: state => state.itens,
  totalItens: (state) => state.totalItens,
  item: state => state.item,
  listaItensSelect: (state) => {
    return map(state.itens, item => {
      return {
        text: item.name,
        value: item.id
      }
    })
  }
}

export default { namespaced: true, state, actions, mutations, getters }