// eslint-disable-next-line no-unused-vars
import http from '@/core/http'

const url = 'organizze'

const state = {
  dados: [],
  filter: null
}

const actions = {
  getItens: async ({commit} , payload) => {
    const result = await http.get(`${url}/relatorio`, { params: payload })
    // if (result.status !== 200) throw new Error('Pagina não existe')
    commit('ADD_DADOS', result.data.data.data)
    return Promise.resolve(result)
  },

  filterItens: async ({commit} , payload) => {
    commit('ADD_DADOS_FILTER', payload)
  }
}

const mutations = {
  'ADD_DADOS' (state, payload) {
    state.dados = payload
  },

  'ADD_DADOS_FILTER' (state, payload) {
    state.filter = payload
  }
}

const getters = {
  list: (state) => state.dados,
  listFilter: (state) => state.dados.filter(item => {
    if (state.filter) return item.type === state.filter
    return state.dados
  })
}

export default { namespaced: true, state, actions, mutations, getters }