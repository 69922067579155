export default [
  {
    path: 'gestao/entidades/promotor',
    name: 'promotor',
    component: () => import('./index'),
    meta: {
      visible: true,
      title: 'Promotor',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Gestão', link: 'gestao' },
        { name: 'Rede', link: 'entidades' },
        { name: 'Promotor' }
      ]
    }
  }
]