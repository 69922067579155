// eslint-disable-next-line no-unused-vars
import http from '@/core/http'

const url = 'lancamento-financeiro'

const state = {
  item: [],
  itens: [{
    id: '',
    name: '',
    username: '',
    email: '',
    roles: '',
  }],
  userEntities: [],
  totalItens: 0,
  lancamentoFinanceiro: {
    date: '',
    // entity: '',
    estabelecimento: '',
    credenciador: {
      text: '',
      value: null
    },
    value: 0,
    typeOperation: {
      text: '',
      value: ''
    },
    type: ''
  },

  lancamentoFinanceiroIndividual: {
    estabelecimento: {
      text: '',
      value: null
    },
    value: 0,
    typeOperation: ''
  },

  arrayLancamentoFinanceiroIndividual: []
}

const actions = {
  getItens: async ({ commit }, payload) => {
    const result = await http.get(url, { params: { module: 'Revendas', ...payload } })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },
  cadastrarItem: async (_, payload) => {
    const result = await http.post(url, payload)
    return Promise.resolve(result.data)
  },
  setItem: async ({ commit }, payload) => {
    commit('LISTA_ITEM', payload)
  },
  editarItem: async (_, payload) => {
    const result = await http.put(url, payload)
    return Promise.resolve(result.data)
  },

  baixarLancamentoFinanceiro: async (_, payload) => {
    const result = await http.put(`${url}/baixar`, payload)
    return Promise.resolve(result.data)
  },
  editSaveItem: async ({commit }, payload) => {
    const result = await http.put(url, payload)
    commit('EDITAR_ITEM', result)
  },
  deletarItem: async (_, payload) => {
    const result = await http.delete(url, {params: payload})
    return Promise.resolve(result.data)
  },
  limparItem: ({ commit }) => {
    commit('LIMPAR_ITEM')
  },

  // User Entities
  cadastrarUserEntities: async (_, payload) => {
    const headers = {
      moduloname: 'gestao',
    }
    const result = await http.post('autenticacao/user-entities', payload, headers)
    return Promise.resolve(result.data)
  },

  getItemUsersEntities: async ({ commit }, payload) => {
    // const result = await http.get('user-entities/list-all')
    commit('LIST_ITENS_USERS_ENTITIES', payload)
  },

  addLancamentoFinanceiroIndividual: async({ commit }, payload) => {
    commit('ADD_LANCAMENTO_FINANCEIRO_INDIVIDUAL', Object.assign({}, payload))
  },

  setLancamentoParaEditar: async({ commit }, payload) => {
    commit('SET_LANCAMENTO_PARA_EDITAR', Object.assign({}, payload))
  },

  removeLancamentoParaEditar: async({ commit }, payload) => {
    commit('REMOVE_LANCAMENTO_PARA_EDITAR',  payload)
  },

  clearItens: ({ commit }) => {
    commit('CLEAR_ITENS')
    commit('LIMPAR_ITEM')
  },

  limparFormLancamentoFinanceiroIndividual: ({ commit }) => {
    commit('LIMPAR_FORM_INDIVIDUAL')
  }
}

const mutations = {
  'LISTA_ITENS' (state, payload) {
    state.itens = payload.resultMap
    state.totalItens = payload.totalItens
  },

  'LISTA_ITEM' (state, payload) {
    const dados = Object.assign({}, payload)
    state.lancamentoFinanceiro = {
      id: dados.id,
      credenciador: {
        text: `(${dados.entity.cod}) ${dados.entity.jsonData.name}`,
        value: dados.entityId
      },
      value: dados.value,
      typeOperation: {
        text: dados.pendingOperationTypeName,
        value: dados.pendingOperationTypeId
      }
    }

    dados.params.data.forEach(item => {
      state.arrayLancamentoFinanceiroIndividual.push({
        estabelecimento: {
          // text: item.entity.cod,
          value: item.entity.id
        },
        value: item.value,
        typeOperation: {
          // text: item.entryType.cod,
          value: item.entryType.id
        }
      })
    })
  },

  'EDITAR_ITEM' (state, payload) {
    state.item = Object.assign({}, payload)
  },

  'LIMPAR_ITEM' (state) {
    state.item = [],
    state.userEntities = [],
    state.lancamentoFinanceiro = {
      date: '',
      // entity: '',
      estabelecimento: {
        text: '',
        value: null
      },
      credenciador: {
        text: '',
        value: null
      },
      value: 0,
      typeOperation: '',
      type: ''
    },

    state.lancamentoFinanceiroIndividual = {
      estabelecimento: {
        text: '',
        value: null
      },
      value: 0,
      typeOperation: ''
    },

    state.arrayLancamentoFinanceiroIndividual = []
  },

  'LIST_ITENS_USERS_ENTITIES' (state, payload) {
    state.userEntities = payload
  },

  'ADD_LANCAMENTO_FINANCEIRO_INDIVIDUAL' (state, payload) {
    if (payload.idArray >= 0) {
      state.arrayLancamentoFinanceiroIndividual.splice(payload.idArray, 1, payload)
      delete state.lancamentoFinanceiroIndividual.idArray
      state.lancamentoFinanceiroIndividual.estabelecimento = {
        text: '',
        value: null
      }
      state.lancamentoFinanceiroIndividual.value = ''
      state.lancamentoFinanceiroIndividual.typeOperation = ''
      return
    }
    state.arrayLancamentoFinanceiroIndividual.push(payload)
    state.lancamentoFinanceiroIndividual.estabelecimento = {
      text: '',
      value: null
    }
    state.lancamentoFinanceiroIndividual.value = ''
    state.lancamentoFinanceiroIndividual.typeOperation = ''
  },

  'SET_LANCAMENTO_PARA_EDITAR' (state, payload) {
    state.lancamentoFinanceiroIndividual.estabelecimento = payload.item.estabelecimento
    state.lancamentoFinanceiroIndividual.value = payload.item.value
    state.lancamentoFinanceiroIndividual.typeOperation = payload.item.typeOperation
    state.lancamentoFinanceiroIndividual.idArray = payload.index
  },

  'REMOVE_LANCAMENTO_PARA_EDITAR' (state, payload) {
    state.arrayLancamentoFinanceiroIndividual.splice(payload, 1)
  },

  'CLEAR_ITENS' (state) {
    state.itens = []
    state.totalItens = 0
  },

  'LIMPAR_FORM_INDIVIDUAL' (state) {
    state.lancamentoFinanceiroIndividual = {
      estabelecimento: {
        text: '',
        value: null
      },
      value: 0,
      typeOperation: ''
    }
  }
}

const getters = {
  listaItens: (state) => state.itens,
  totalItens: (state) => state.totalItens,
  item: (state) => state.item,
  listUserEntities: state => state.userEntities,
  lancamentoFinanceiro: state => state.lancamentoFinanceiro,
  lancamentoFinanceiroIndividual: state => state.lancamentoFinanceiroIndividual,
  arrayLancamentoFinanceiroIndividual: state => state.arrayLancamentoFinanceiroIndividual
}

export default { namespaced: true, state, actions, mutations, getters }