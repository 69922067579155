import http from '@/core/http'
import { filter } from 'lodash'

const state = {
  contactTypes: [],
  contacts: []
}

const actions = {
  getContactTypes: async ({ commit }) => {
    const result = await http.get('gefin-contatos/contact-types')
    commit('GET_CONTACT_TYPES', result.data.data[0])
    return Promise.resolve(result.data.data[0])
  },

  submit: async (_, payload) => {
    let result =  {}
    if (payload.isEdit) result = await http.put('gefin-contatos', payload.data)
    else result = await http.post('gefin-contatos', payload.data)
    return Promise.resolve(result.data.data[0])
  },

  getContacts: async ({ commit }) => {
    const result = await http.get('gefin-contatos')
    commit('GET_CONTACTS', result.data.data[0])
    return Promise.resolve(result.data.data[0])
  },

  deleteContact: async (_, payload) => {
    const result = await http.delete('gefin-contatos', { params: payload })
    return Promise.resolve(result.data.data)
  }
}

const mutations = {
  'GET_CONTACT_TYPES' (state, payload) { 
    state.contactTypes = payload 
    state.contactTypes.unshift({
      id: 'revenda',
      name: 'Revenda'
    })
  },
  'GET_CONTACTS' (state, payload) { state.contacts = payload }
 }

const getters = {
  contactTypes: state => state.contactTypes,
  allContacts: state => state.contacts,
  customers: state => filter(state.contacts, item => item.contactTypeId === 1),
  providers: state => filter(state.contacts, item => item.contactTypeId === 2),
  others: state => filter(state.contacts, item => item.contactTypeId === 3)
}

export default { namespaced: true, state, actions, mutations, getters }