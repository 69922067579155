import http from '@/core/http'

const url = 'cobranca'

const state = {
  itens: [{
    est: '',
    email: '',
    phone: '',
    value: 0
  }],
  last_charges:[{
    date: '',
    value: '',
    status: ''
  }]
}

const actions = {
  getItens: async ({ commit }, payload) => {
    const result = await http.get(url, { params: { module: 'Revendas', ...payload } })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },
  gerarBoleto: async (_, payload) => {
    return http.post('/cobranca/boleto-bradesco', payload).then((res) => {
      return Promise.resolve(res)
    })
  },
  gerarPicpay: async (_, payload) => {
    return http.post(`${url}/picpay`, payload).then((res) => {
      return Promise.resolve(res)
    })
  },
  getLastCharges: async ({ commit }, payload) => {
    const result = await http.get(`${url}/last-charges`, { params: { module: 'Revendas', entityId: payload, pendingOperationTypeId: 'PCB', pageSize: 5 } })
    commit('LISTA_LAST_CHARGES', result.data)
    return Promise.resolve(result)
  },
  clearItens: ({ commit }) => {
    commit('CLEAR_ITENS')
  }
}

const mutations = {
  'LISTA_ITENS' (state, payload) {
    state.itens = payload.resultMap
  },
  'LISTA_LAST_CHARGES' (state, payload) {
    state.last_charges = payload.resultMap
  },
  'CLEAR_ITENS' (state) {
    state.itens = [],
    state.last_charges = []
  }
}

const getters = {
  listaItens: (state) => state.itens
}

export default { namespaced: true, state, actions, mutations, getters }