// eslint-disable-next-line no-unused-vars
import http from '@/core/http'
// import { map } from 'lodash'

const state = {
  modules: null
}

const actions = {
  getModules: async () => {
    const result = await http.get('autenticacao/module-groups')
    // commit('LISTAR_MODULES', result.data)
    return Promise.resolve(result.data.resultpMapOrder)
  },

  listByGroup: async (_, payload) => {
    const result = await http.get('/autenticacao/role-modules', { params: payload })
    return Promise.resolve(result.data.data)
  },
  
  addAll: async (_, payload) => {
    const result = await http.post('/autenticacao/role-modules', payload)
    return Promise.resolve(result.data)
  }
}

const mutations = {
  'LISTAR_MODULES' (state, payload) {
    state.modules = payload
  }
}

const getters = {
  listaModules: (state) => {
    return state.modules
  }
}

export default { namespaced: true, state, actions, mutations, getters }