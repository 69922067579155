// eslint-disable-next-line
import Events from '@/core/service/events'
import store from '@/core/store'
import { size, compact, map, last } from 'lodash'
const eParametro = val => {
  let item = last(val)
  let num = parseFloat(item)
  if (isNaN(num)) return false
  return typeof num === 'number' ? true : false
}

export default  async (to, from, next) => {
  try {
      if (to.name === 'home') return next()
      if (to.name === 'auth') return next()
      if (to.name === 'TermoConsentimento') return next()
      let name = compact(String(to.path).split('/'))
      if (size(name) === 1) { // verifica os modulos
        await store.dispatch('roles/permiteCategoria', name[0]) // evento verifica se tem o modulo nas permissoes
        next()
      } else {
        let params = eParametro(name) // verifica se tem parametro na rota
        if (params) name.pop()
        let rotas = compact(map(name, (v, k) => {
          if (k !== 0) return v
        })).join('/')
        
        if (!rotas) { 
          next({ path:  'home' }) 
          return 
        } 

        await store.dispatch('roles/permiteRota', {module: name[0], rota: `/${rotas}/` }) // evento verifica se tem o modulo nas permissoes
        // console.log('foi apra subRota', subRotas)
        next()
      }
    } catch (e) {
      console.error(e)
      const msg = {
        toggle: true,
        type: 'error',
        msg: 'Acesso negado !'
      }
      Events.$emit('snackbarCadastro::msg', msg)
    next({ path: from.path })
  }
}
