import http from '@/core/http'

const state = {
  items: [],
  totais: [],
  hasResult: false
}
const actions = {
  submit: async ({commit}, payload) => {
    const result = await http.get(`/entradas/fechamento-caixa`, { params: payload })
    const hasResult = result.data.result.length > 0
    commit('HAS_RESULT', hasResult)
    const data = result.data.result[0]
    commit('ITEMS', data)
    return Promise.resolve(data)
  },

  getPremiosRetidos: async ({commit}, payload) => {
    const result = await http.get(`/entradas/fechamento-caixa-totais`, { params: payload })
    const data = result.data.result[0]
    commit('TOTAIS', data)
    return Promise.resolve(data)
  }
}
const mutations = {
  'ITEMS' (state, payload) { state.items = payload },
  'TOTAIS' (state, payload) { state.totais = payload },
  'HAS_RESULT' (state, payload) { state.hasResult = payload }
}
const getters = {
  items: state => state.items,
  totais: state => state.totais,
  hasResult: state => state.hasResult
}

export default { namespaced: true, state, actions, mutations, getters }