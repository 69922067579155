// eslint-disable-next-line no-unused-vars
import http from '@/core/http'

const url = 'doacoes'

const state = {
  item: [],
  itens: [{
    id: '',
    name: '',
    username: '',
    email: '',
    roles: '',
  }],
  userEntities: [],
  totalItens: 0,
  lancamentoFinanceiro: {
    date: '',
    entity: '',
    value: 0,
    typeOperation: ''
  }
}

const actions = {
  getItens: async ({ commit }, payload) => {
    const result = await http.get(url, { params: { module: 'Usuário WEB', ...payload } })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },
  cadastrarItem: async (_, payload) => {
    const result = await http.post(url, payload)
    return Promise.resolve(result.data)
  },
  getItem: async ({ commit }, payload) => {
    commit('LISTA_ITEM', payload)
  },
  editarItem: async (_, payload) => {
    const result = await http.put(url, payload)
    return Promise.resolve(result.data)
  },
  editSaveItem: async ({commit }, payload) => {
    const result = await http.put(url, payload)
    commit('EDITAR_ITEM', result )
  },
  deletarItem: async (_, payload) => {
    const result = await http.delete(url, {params: payload})
    return Promise.resolve(result.data)
  },
  limparItem: ({ commit }) => {
    commit('LIMPAR_ITEM')
  },

  // User Entities
  cadastrarUserEntities: async (_, payload) => {
    const result = await http.post('autenticacao/user-entities', payload)
    return Promise.resolve(result.data)
  },

  getItemUsersEntities: async ({ commit }, payload) => {
    // const result = await http.get('user-entities/list-all')
    commit('LIST_ITENS_USERS_ENTITIES', payload)
  },

  clearItens: ({ commit }) => {
    commit('CLEAR_ITENS')
    commit('LIMPAR_ITEM')
  }
}

const mutations = {
  'LISTA_ITENS' (state, payload) {
    state.itens = payload.resultMap
    state.totalItens = payload.totalItens
  },

  'LISTA_ITEM' (state, payload) {
    state.item = Object.assign({}, payload)
  },

  'EDITAR_ITEM' (state, payload) {
    state.item = Object.assign({}, payload)
  },

  'LIMPAR_ITEM' (state) {
    state.item = [],
    state.userEntities = [],
    state.lancamentoFinanceiro = {
      date: '',
      entity: '',
      value: 0,
      typeOperation: ''
    }
  },

  'LIST_ITENS_USERS_ENTITIES' (state, payload) {
    state.userEntities = payload
  },

  'CLEAR_ITENS' (state) {
    state.itens = []
    state.totalItens = 0
  }
}

const getters = {
  listaItens: (state) => state.itens,
  totalItens: (state) => state.totalItens,
  item: (state) => state.item,
  listUserEntities: state => state.userEntities,
  lancamentoFinanceiro: state => state.lancamentoFinanceiro
}

export default { namespaced: true, state, actions, mutations, getters }