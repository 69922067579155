// eslint-disable-next-line no-unused-vars
import http from '@/core/http'

const url = 'cadastros-cestas'

const state = {
  item: {
    id: null,
    name: null,
    cpf: null,
    status: null,
    celular: null,
    flags: [],
    data: {
      emprego: null,
      familyIncome: null,
      fone: null,
      birth: null,
      email1: null,
      cep: null,
      city: null,
      state: null,
      address: null,
      complement: null,
      children: null,
      transport: null,
      home: null,
      qtdPeopleResidence: null,
      necessidade: null,
      observation: null
    }
  },
  itens: [{
    id: null,
    name: null,
    cpf: null,
    status: null,
    celular: null,
    data: {
      emprego: null,
      familyIncome: null,
      fone: null,
      birth: null,
      email1: null,
      cep: null,
      city: null,
      state: null,
      address: null,
      complement: null,
      children: null,
      transport: null,
      home: null,
      qtdPeopleResidence: null,
      necessidade: null,
      observation: null
    }
  }],
  totalItens: 0,
  userEntities: [],
  listaOsc: []
}

const actions = {
  getItens: async ({ commit }, payload) => {
    const result = await http.get(url, { params: payload })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },
  cadastrarItem: async (_, payload) => {
    const result = await http.post(url, payload)
    return Promise.resolve(result.data)
  },
  getIten: async ({ commit }, payload) => {
    const result = await http.get(`${url}/view/`, payload)
    commit('LISTA_ITENS', result.data)
  },
  editarItem: async ({ commit }, payload) => {
    commit('EDITAR_ITEM', payload)
  },
  editSaveItem: async ({commit }, payload) => {
    const result = await http.put(url, payload)
    commit('EDITAR_ITEM', result )
  },
  deletarItem: async (_, payload) => {
    const result = await http.delete(url, {params: payload})
    return Promise.resolve(result.data)
  },
  limparItem: ({ commit }) => {
    commit('LIMPAR_ITEM')
  },

  // User Entities
  cadastrarUserEntities: async (_, payload) => {
    const result = await http.post('autenticacao/user-entities', payload)
    return Promise.resolve(result.data)
  },

  getListOsc: async ({ commit }, payload) => {
    const headers = {
      moduloname: 'gestao',
    }
    const result = await http.get('entidades/supervisor', { params: payload, headers })
    commit('LISTA_OSC', result.data)
    return Promise.resolve(result)
  },

  getItemUsersEntities: async ({ commit }, payload) => {
    // const result = await http.get('user-entities/list-all')
    commit('LIST_ITENS_USERS_ENTITIES', payload)
  },

  clearItens: ({ commit }) => {
    commit('CLEAR_ITENS')
    commit('LIMPAR_ITEM')
  }
}

const mutations = {
  'LISTA_ITENS' (state, payload) {
    state.itens = payload.resultMap
    state.totalItens = payload.totalItens
  },

  'LISTA_ITEM' (state, payload) {
    state.item = Object.assign({}, payload)
  },

  'EDITAR_ITEM' (state, payload) {
    state.item = Object.assign({}, payload)
  },

  'LIMPAR_ITEM' (state) {
    state.item = {
      id: null,
      name: null,
      cpf: null,
      status: null,
      celular: null,
      data: {
        emprego: null,
        familyIncome: null,
        fone: null,
        birth: null,
        email1: null,
        cep: null,
        city: null,
        state: null,
        address: null,
        complement: null,
        children: null,
        transport: null,
        home: null,
        qtdPeopleResidence: null,
        necessidade: null,
        observation: null
      }
    },
    state.userEntities = []
  },

  'LIST_ITENS_USERS_ENTITIES' (state, payload) {
    state.userEntities = payload
  },

  'LISTA_OSC' (state, payload) {
    state.listaOsc = payload.resultMap
    // state.totalItens = payload.totalItens
  },

  'CLEAR_ITENS' (state) {
    state.itens = []
    state.totalItens = 0
  }
}

const getters = {
  listaItens: (state) => state.itens,
  totalItens: (state) => state.totalItens,
  getItem: (state) => state.item,
  listaOscSelect: (state) => {
    return state.listaOsc.map(item => {
      return {
        text: item.name,
        value: item.id
      }
    })
  }
}

export default { namespaced: true, state, actions, mutations, getters }