import http from '@/core/http'
import {size, filter} from 'lodash'
const state = {
  lotteries: [],
  cards: []
}
const actions = {
  getSweepstakesResults: async ({commit}, payload) => {
    if (payload.cod === 'TODAS') payload.cod = ''
    const result = await http.get('/loterias/concursos/resultados/', { params: {  ...payload }})
    const cards = filter(result.data.result, v => size(v.prizes) !== 0)
    commit('CARDS', cards)
    return Promise.resolve(cards)
  },
  getLotteries: async ({commit}, payload) => {
    const result = await http.get('/loterias/concursos/loterias/', { params: {  ...payload } })
    const lotteries = ['TODAS'].concat(result.data.lotteries.filter(item => item.ascertained_method_id === 'PROG').map(item => item.cod))
    commit('SWEEPSTAKES_GROUPS', lotteries)
    return Promise.resolve(lotteries)
  }
}
const mutations = {
  'CARDS' (state, payload) {
    state.cards = payload
  },
  'SWEEPSTAKES_GROUPS' (state, payload) {
    state.lotteries = payload
  }
}
const getters = {
  cards: state => state.cards,
  lotteries: state => state.lotteries
}

export default { namespaced: true, state, actions, mutations, getters }