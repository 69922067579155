// eslint-disable-next-line no-unused-vars
import http from '@/core/http'
import { merge } from 'lodash'

const url = '	terminais/instalacao/'

const state = {
  loadingTable: true,
  itens: null,
  totalItens: 0,
  isStatus: false
}

const actions = {
  getItens: async ({ commit }, payload) => {
    if (!payload) payload = {}

    let _filter = { 'deleted_>=':'01/01/1970' }
    if (payload.hasOwnProperty('_filter') && state.isStatus) {
      payload._filter = merge(payload._filter, _filter)
    } else if (state.isStatus) {
      payload = Object.assign(payload, { _filter })
    }

    const result = await http.get(
      url, 
      { params: Object.assign(payload, { order: { 'installation.id': 'desc' }, } ), headers: { moduloname: 'gestao'}}
    )
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },
  getItem: async ({ commit }, payload) => {
    commit('LISTA_ITEM', payload)
  },

  clearItens: ({ commit }) => {
    commit('CLEAR_ITENS')
  },

  switchStatus ({ commit }) {
    commit('SWITCH_STATUS')
  },
}

const mutations = {
  'LISTA_ITENS' (state, payload) {
    state.itens = payload.resultMap
    state.totalItens = payload.totalItens
  },

  'CLEAR_ITENS' () {
    state.item= {
      date:  '',
      created:  '',
      id:  '',
      email:  '',
      deleted:  '',
      status:  '',
      entryType: {
        type: ''
      },
      jsonData: {
        cpf:  '',
        name:  '',
        birth:  '',
        phone:  '',
        influencer:  '',
        bank: '',
        agency: '',
        account: '',
        digit: '',
        accountType: '',
      }
    }
    state.userEntities = []
  },

  'SWITCH_STATUS' (state) {
    state.isStatus = !state.isStatus
  }
}

const getters = {
  loadingTable: (state) => state.loadingTable,
  listaItens: (state) => state.itens,
  totalItens: (state) => state.totalItens,
  item: (state) => state.item,
  listExtratoCredito: (state) => state.listExtratoCredito.filter(item => item.entryTypeId !== 'SRT' && item.entryTypeId !== 'PSR'),
  listExtrataTitulosCapitalizacao: (state) => state.listExtratoCredito.filter(item => item.entryTypeId === 'SRT' || item.entryTypeId === 'PSR'),
  listExtratoPremio: (state) => state.listExtratoPremio,
  listDocuments: (state) => state.listDocuments,
  isStatus: state => state.isStatus
}

export default { namespaced: true, state, actions, mutations, getters }