import http from '@/core/http'
import { map } from 'lodash'

const url = 'entidades/influenciador/comissao'

const state = {
  itens: [],
  item:  {
    dtStart: '',
    dtExpiration: '',
    value: '',
    cod: '',
    image: '',
    entities:[
      { entity: '' }
    ],
    bonificationId: ''
  },
  lista: '',
  itemAddPermission: [],
  itemSelect: [],
  totalItens: 0,
  totalItemSelect: 0
}

const actions = {
  getItens: async ({ commit }, payload) => {
    const result = await http.get(url, { params: Object.assign(payload, { order: { 'Calculations.id': 'desc' }})})
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },

  cadastrarItem: async (_, payload) => {
    const result = await http.post(url, payload)
    return Promise.resolve(result)
  },

  editarItem: async (_, payload) => {
    const result = await http.put(url, payload)
    return Promise.resolve(result)
  },

  deletarItem: async (_, payload) => {
    const result = await http.delete(url, { params: payload })
    return Promise.resolve(result.data)
  },

  getItem: ({ commit }, payload) => {
    commit('LISTA_ITEM', payload)
    return Promise.resolve(payload)
  },

  limparItem: ({ commit }) => {
    commit('LIMPAR_ITEM')
  },

  clearItens: ({ commit }) => {
    commit('CLEAR_ITENS')
    commit('LIMPAR_ITEM')
  },

  validateCod: async (_, payload) => {
    const result = await http.get(`${url}/validate-influencer`, { params: payload })
    return Promise.resolve(result.data)
  }
}

const mutations = {
  'LISTA_ITENS' (state, payload) {
    state.itens = payload
  },

  'LISTA_ITEM' (state, payload) {
    state.item = Object.assign({}, payload)
  },

  'LIMPAR_ITEM' (state) {
    state.item = {
      dtStart: '',
      dtExpiration: '',
      aliquot: '',
      cod: '',
      image: '',
      bonificationId: ''
    }
  },

  'LISTA_ITENS_SELECT' (state, payload) {
    state.itemSelect = payload.resultMap
    state.totalItemSelect = payload.totalItens
  },

  'CLEAR_ITENS' (state) {
    state.itens = []
    state.totalItens = 0
    state.itemSelect = []
  }
}

const getters = {
  listaItens: state => state.itens,
  totalItens: (state) => state.totalItens,
  totalItemSelect: (state) => state.totalItemSelect,
  item: state => state.item,
  listaItensSelect: (state) => {
    return map(state.itemSelect, item => {
      return {
        text: item.name,
        value: item.id
      }
    })
  }
}

export default { namespaced: true, state, actions, mutations, getters }