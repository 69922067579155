import http from '@/core/http'
// import { merge } from 'lodash'

const endPoint = 'entradas'

const state = {
  itens: [],
  item: {
    date: '',
    description: '',
    entityId: '',
    entityName: '',
    entryTypeId: '',
    id: '',
    transaction: '',
    value: ''
  },
  lista: '',
  itemAddPermission: [],
  acl: null,
  itemSelect: [],
  totalItens: 0,
  filterTypeEntry: null,
}

const actions = {
  getItens: async ({ commit }, payload) => {
    if (payload && payload._addlFilter && state.filterTypeEntry) {
      payload._addlFilter = Object.assign(payload._addlFilter,  { 'entryType:type_=': state.filterTypeEntry })
    } else if (state.filterTypeEntry) {
      payload = Object.assign(payload, { _addlFilter: { 'entryType:type_=': state.filterTypeEntry } })
    }

    const result = await http.get(endPoint, { params: payload })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },

  cadastrarItem: async (_, payload) => {
    const result = await http.post(endPoint, payload)
    return Promise.resolve(result)
  },

  editarItem: async (_, payload) => {
    const result = await http.put(endPoint, payload)
    return Promise.resolve(result)
  },

  deletarItem: async (_, payload) => {
    const result = await http.delete(endPoint, {params: payload})
    return Promise.resolve(result)
  },

  getItem: ({ commit }, payload) => {
    commit('LISTA_ITEM', payload)
  },

  setFilterTypeEntry: ({ commit }, payload) => {
    commit('SET_FILTER_TYPE_ENTRY', payload)
  },

  limparItem: ({ commit }) => {
    commit('LIMPAR_ITEM')
  },

  clearItens: ({ commit }) => {
    commit('CLEAR_ITENS')
    commit('LIMPAR_ITEM')
  }
}

const mutations = {
  'LISTA_ITENS' (state, payload) {
    state.itens = payload.resultMap
    state.totalItens = payload.totalItens
  },

  'LISTA_ITEM' (state, payload) {
    state.item = Object.assign({}, payload)
  },

  'LIMPAR_ITEM' (state) {
    state.item = {
      date: '',
      description: '',
      entityId: '',
      entityName: '',
      entryTypeId: '',
      id: '',
      transaction: '',
      value: ''
    }
  },

  'LISTA_ITENS_SELECT' (state, payload) {
    state.itemSelect = payload
  },

  'CLEAR_ITENS' (state) {
    state.itens = []
    state.totalItens = 0
  },

  'SET_FILTER_TYPE_ENTRY' (state, payload) {
    state.filterTypeEntry = payload
  }
}

const getters = {
  listaItens: state => state.itens,
  totalItens: (state) => state.totalItens,
  item: state => state.item
}

export default { namespaced: true, state, actions, mutations, getters }