export default [
  {
    path: 'gestao/loterias/concursos',
    name: 'concursos',
    component: () => import('./index'),
    meta: {
      visible: true,
      title: 'Concursos',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Gestão', link: 'gestao' },
        { name: 'Loterias', link: 'loterias' },
        { name: 'Concursos' }
      ]
    }
  }
]