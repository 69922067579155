import vue from 'vue'

export const Locale = () =>  String(vue.config.language).replace('_', '-')
export const ItensPaginacao = () => 50
export const QuantidadeItensSelects = () => 10
export const ddi = () => {
  switch(vue.config.language) {
    case ('pt_BR'):
      return '55'
    default:
      return '00'
  }
}
