import http from '@/core/http'
// import axios from 'axios'
// import config from '@/core/config'

// eslint-disable-next-line
import { map, size, cloneDeep } from 'lodash'

const url = 'entidades/bonus-cupons'

const state = {
  totalItens: 0,
  totalCupons: 0,
  itens: [],
  cupons: [],
}

const actions = {
  getItens: async ({ commit }, payload) => {
    const result = await http.get(`${url}`,  { params: payload })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },

  buscarItem: async ({ commit }, payload) => {
    const result = await http.get(`${url}/view`, { params: payload })
    commit('LISTA_ITEM_CUPONS', result.data)
  },

  getItem: ({ commit }, payload) => {
    commit('LISTA_ITEM', payload)
  },

  limparItem: ({ commit }) => {
    commit('LIMPAR_ITEM')
  },
  
  clearItens: ({ commit }) => {
    commit('CLEAR_ITENS')
    commit('LIMPAR_ITEM_ENTITY_PRODUCT')
    commit('LIMPAR_ITEM')
  }
}

const mutations = {
  'LISTA_ITENS' (state, payload) {
    state.itens = payload.resultMap[0]
    state.totalItens = payload.resultMap[1]
  },
  'LISTA_ITEM_CUPONS' (state, payload) {
    state.cupons = payload.resultMap[0]
    state.totalCupons = payload.resultMap[1]
  },

  'CLEAR_ITENS' (state) {
    state.itens = []
    state.itensSelect = []
    state.itensPoducts = [],
    state.itensEntityProducts = [],
    state.totalItens = 0
  }
}

const getters = {
  listaItens: state => state.itens,
  items: state => state.item,
  totalItens: (state) => state.totalItens,
  // modal de cupons
  listCupons: state => state.cupons,
  totalCupons: state => state.totalCupons
}

export default { namespaced: true, state, actions, mutations, getters }