import http from '@/core/http'
import { map } from 'lodash'
import moment from 'moment'

const endPoint = 'loterias/concursos'

const state = {
  itens: [],
  item: {
    ascertainedMethodId: '',
    cod: '',
    type: '',
    dtStart: '',
    dtEnd: '',
    dtDraw: '',
    image: '',
    imageCupom:'',
    imageCupomVerso:'',
    regulationSweepstakes:'',
    config: {
      price: 5.0,
      sizeLot: '',
      showResult: false,
      cardConfig: {
        numCols: 5,
        numLines: 5,
        maxNumber: 90,
        centralNumberBonus: false,
        expectedSales: '',
        payoutPercentage: '',
      },
      accumulation: {
        maxBalls: '',
        percentage: ''
      }
    }
  },
  lista: '',
  itemAddPermission: [],
  acl: null,
  itemSelect: [],
  totalItens: 0,
  methods: []

}

const actions = {
  updateConfig: async ({ commit }, payload) => {
    const result = await http.post(`${endPoint}/edit-config`, { params: payload })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },
  getItens: async ({ commit }, payload) => {

    let filter = {
      'Sweepstakes:dtEnd_>=': moment().add(-30, 'minutes').format('YYYY-MM-DD HH:mm:ss')
    }

    let filtros = payload._filter ? Object.keys(payload._filter) : []

    let devemSobrescreverFIltro = ['Sweepstakes:dtEnd', 'Sweepstakes:dtDraw', 'Sweepstakes:dtStart']
    let existe = filtros.map(v => {
      let str = v.split('_')
      if (str.length != 1) {
        return devemSobrescreverFIltro.indexOf(str[0]) !== -1
      }
      return false
    })

    if(existe.indexOf(true) === -1) {
      payload._addlFilter = filter
    }

    const result = await http.get(endPoint, { params: { ...payload, order: { 'Sweepstakes.dtEnd': 'ASC' } }})
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },

  getSweepstakes: async ({ commit }, payload) => {
    const result = await http.get(endPoint, { params: { ...payload, order: { 'Sweepstakes.dtEnd': 'ASC' } }})
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },

  cadastrarItem: async (_, payload) => {
    const result = await http.post(endPoint, payload)
    return Promise.resolve(result)
  },

  editarItem: async (_, payload) => {
    const result = await http.put(endPoint, payload)
    return Promise.resolve(result)
  },

  deletarItem: async (_, payload) => {
    const result = await http.delete(endPoint, {params: payload})
    return Promise.resolve(result)
  },

  getItem: async ({ commit }, payload) => {
    const result = await http.get(`${endPoint}/view`, { params: payload })
    commit('LISTA_ITEM', result.data)
    return Promise.resolve(result)
  },

  setItem: async ({ commit }, payload) => {
    commit('LISTA_ITEM', payload)
  },

  getMethos: async ({ commit }, payload) => {
    const result = await http.get(`${endPoint}/metodos-verificados`, payload)
    commit('LIST_METHODS', result.data)
    return Promise.resolve(result)
  },

  limparItem: ({ commit }) => {
    commit('LIMPAR_ITEM')
  },

  clearItens: ({ commit }) => {
    commit('CLEAR_ITENS')
    commit('LIMPAR_ITEM')
  }
}

const mutations = {
  'LISTA_ITENS' (state, payload) {
    state.itens = payload.resultMap
    state.totalItens = payload.totalItens
  },

  'LISTA_ITEM' (state, payload) {
    state.item = Object.assign({}, payload)
    state.item.dtDraw = moment(state.item.dtDraw, 'DD/MM/YYYY HH:mm').toDate()
    state.item.dtEnd = moment(state.item.dtEnd, 'DD/MM/YYYY HH:mm').toDate()
    state.item.dtStart = moment(state.item.dtStart, 'DD/MM/YYYY HH:mm').toDate()
  },

  'LIMPAR_ITEM' (state) {
    state.item = {
      ascertainedMethodId: '',
      cod: '',
      type: '',
      dtStart: '',
      dtEnd: '',
      dtDraw: '',
      image: '',
      imageCupom:'',
      imageCupomVerso:'',
      regulationSweepstakes:'',
      config: {
        price: 5.0,
        sizeLot: '',
        showResult: false,
        cardConfig: {
          numCols: 5,
          numLines: 5,
          maxNumber: 90,
          centralNumberBonus: false,
          expectedSales: '',
          payoutPercentage: '',
        },
        accumulation: {
          maxBalls: '',
          percentage: ''
        }
      }
    }
  },

  'LISTA_ITENS_SELECT' (state, payload) {
    state.itemSelect = payload
  },

  'LIST_METHODS' (state, payload) {
    state.methods = payload.resultMap
  },

  'CLEAR_ITENS' (state) {
    state.itens = []
    state.itensSelect = []
    state.totalItens = 0
  }
}

const getters = {
  listaItens: state => state.itens,
  totalItens: (state) => state.totalItens,
  item: state => state.item,
  listaItemSelectMethods: state => map(state.methods, item => {
    return {
      text: item.name,
      value: item.id
    }
  }),
  listaItensSelect: (state) => {
    return map(state.itens, item => {
      return {
        text: item.cod,
        value: item.id
      }
    })
  }
}

export default { namespaced: true, state, actions, mutations, getters }