export default [
  {
    path: 'g-fin/zera-saldo',
    name: 'zera-saldo',
    component: () => import('./index'),
    meta: {
      visible: true,
      title: 'Zera Saldo',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Financeiro', link: 'financeiro' },
        { name: 'Zera Saldo' }
      ]
    }
  }
]