// eslint-disable-next-line no-unused-vars
import {filter, size, last, compact, map, groupBy , forEach, orderBy} from 'lodash'
import http from '@/core/http'
import routes from '@/views/routes'

const url = 'autenticacao/usuarios'

const state = {
  item: {
    id: '',
    name: '',
    username: '',
    email: '',
    roles: '',
    jsonFlags: {
      cashInAllEntities: false,
      responsibleForPromoterEntityId: ''
    }
  },
  itens: [],
  userEntities: [],
  totalItens: 0
}

const actions = {
  getItens: async ({ commit }, payload) => {
    const headers = { moduloname: 'gestao' }
    const result = await http.get(url, { params: payload, headers })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },
  cadastrarItem: async (_, payload) => {
    const result = await http.post(url, payload)
    return Promise.resolve(result.data)
  },
  // editarItem: async ({ commit }) => {
  //   const result = await http.get(`${url}/view`)
  //   commit('LISTA_ITEM', result.data)
  // },
  getItem: async ({ commit }, payload) => {
    commit('EDITAR_ITEM', payload)
  },
  editarItem: async ({commit }, payload) => {
    const options = { headers: { moduloname: 'gestao' }}
    const result = await http.put(url, payload, options )
    commit('EDITAR_ITEM', result )
    return Promise.resolve(result)
  },
  deletarItem: async (_, payload) => {
    const result = await http.delete(url, {params: payload})
    return Promise.resolve(result.data)
  },
  limparItem: ({ commit }) => {
    commit('LIMPAR_ITEM')
  },

  // User Entities
  cadastrarUserEntities: async (_, payload) => {
    const result = await http.post('autenticacao/user-entities', payload)
    return Promise.resolve(result.data)
  },

  getItemUsersEntities: async ({ commit }, payload) => {
    // const result = await http.get('user-entities/list-all')
    commit('LIST_ITENS_USERS_ENTITIES', payload)
  },

  filterItens: async({ commit }, payload) => {
    const result = await http.get(`${url}/filter`, { params: payload })
    commit('LISTA_ITENS', result.data)
  },

  clearItens: ({ commit }) => {
    commit('CLEAR_ITENS')
    commit('LIMPAR_ITEM')
  }
}

const mutations = {
  'LISTA_ITENS' (state, payload) {
    state.itens = payload.mapResult
    state.totalItens = payload.totalItens
  },

  'LISTA_ITEM' (state, payload) {
    state.item = Object.assign({}, payload)
  },

  'EDITAR_ITEM' (state, payload) {
    state.item = Object.assign({}, payload)
  },

  'LIMPAR_ITEM' (state) {
    state.item = {
      id: '',
      name: '',
      username: '',
      email: '',
      roles: '',
      jsonFlags: {
        cashInAllEntities: false,
        responsibleForPromoterEntityId: '',
      }
    },
    state.userEntities = []
  },

  'LIST_ITENS_USERS_ENTITIES' (state, payload) {
    state.userEntities = payload ? payload : []
  },

  'CLEAR_ITENS' (state) {
    state.itens = []
    state.totalItens = 0
  }
}

const getters = {
  listaItens: (state) => state.itens,
  getItem: (state) => state.item,
  totalItens: (state) => state.totalItens,
  listUserEntities: state => state.userEntities,
  menuItens: (state, getters, rootState) => (val = '') => {
    if (size(rootState.roles.acl) === 0) return []
    let  itens = filter(rootState.roles.acl, e => {
      let path = e.module.split('/')
      if (!val) return e.allowed === true && last(path) === ''
      return e.allowed === true && path[2] === val
    })

    // criando um array de rotas registradas de rotas no vue
    // let rotasRegistradas = []
    // routes[0].children.forEach(element => {
    //   rotasRegistradas.push(element.path+'/')
    // });

     let rotasRegistradas = routes[0].children.map(element => {
      return {
        url: element.path+'/',
        meta: element.meta
      }
    });

    
    let modules = compact(map(itens, item => {
      // eslint-disable-next-line
      const module = item.module.split('/')
      const modulePathFull = `${item.group}${item.module}`
      // if (rotasRegistradas.includes(modulePathFull)) {
      const rota = rotasRegistradas.find(it => it.url === modulePathFull)
      if (rota) {
        return {
          groupId: item.groupId,
          group: item.group,
          module: module[1],
          modulePath: module[2],
          modulePathFull: `${item.group}${item.module}`,
          allowed: item.allowed,
          name: item.name,
          meta: rota.meta
        }
      }
    }))

    const modulesMap = orderBy(modules.map(e => {
      // eslint-disable-next-line
      let name = compact(e.module.split('/'))
      return {
        grupo: e.group,
        // name: String(last(name)).toUpperCase(),
        name: String(last(name)),
        realName: e.name,
        allowed: e.allowed,
        module: e.module,
        path: e.modulePath,
        pathFull: e.modulePathFull,
        meta: e.meta
      }
    }), 'realName', 'esc')

    const grupo = groupBy(modulesMap, item => {
      return `${item.grupo}`
    })
    
    let grupo2 = []

    forEach(grupo, (item, key) => {
      const obj = (groupBy(item, item2 => {
        return item2.module
      }))

      let objAux = []
      forEach(obj, (item, key) => {
        forEach(item, subitem => {
          if (!subitem.path && subitem.name === key) {
            objAux[subitem.realName] = item
          }
        })
      })

      grupo2[key] = { ...objAux }
    })

    return { ...grupo2 }
  },
  listaMenuTratado: (state, getters) => {
    return getters.menuItens()
  },
  listaMenu: (state, getters) => {
    // eslint-disable-next-line
    const items = []
    forEach(getters.menuItens(), item => {
      forEach(item, subItem => {
        if (subItem.length > 1) {
          forEach(subItem, itemMenu => {
            items.push(itemMenu)
          })
        } else {
          items.push(...subItem)
        }
      })
    })
    return filter(map(items, item => {
      if (item.path) {
        return {
          text: item.realName,
          value: item.path
        }
      } else {
        return {
          text: item.realName,
          value: item.name
        }
      } 
    }), itemFilter => {
      return itemFilter.text !== ''
    })
  }
}

export default { namespaced: true, state, actions, mutations, getters }