import Auth from './auth/router'
import Home from './home/router'
import HistoricoRequisicaoPremioWeb from './consultas/historicoRequisicaoPremioWeb/router'
import Cadastros from './cadastros/router'
import Feriados from './cadastros/feriados/router'
import metas from './cadastros/metas/router'
import Geolocalizacao from './cadastros/geolocalizacao/router'
import listarMetas from './cadastros/metas/listarMetas/router'
import metasEntidades from './cadastros/metas/metasEntidades/router'
import tiposIndices from './cadastros/tiposIndices/router'
import Organizze from './organizze/router'
import OrganizzeFinanceiro from './organizze/financeiro/router'
import autenticacao from './cadastros/autenticacao/router'
import Roles from './cadastros/autenticacao/roles/router'
import Grupos from './cadastros/autenticacao/grupos/router'
import Permissoes from './cadastros/autenticacao/permissoes/router'
import Endpoints from './cadastros/autenticacao/endpoints/router'
import Users from './cadastros/autenticacao/users/router'
import OrganizzeFinanceiroContas from './organizze/mais/contas/router'
import OrganizzeContatos from './organizze/contatos/router'
import Entidades from './cadastros/entities/router'
import EntidadesPendentes from './cadastros/entities/pendentes/router'
import OrganizzeFinanceiroCategorias from './organizze/mais/categorias/router'
import Osc from './cadastros/entities/osc/router'
import Estabelecimento from './cadastros/entities/estabelecimento/router'
import Credenciador from './cadastros/entities/credenciador/router'
import OrganizzeCentroCustos from './organizze/mais/centroCustos/router'
import OrganizzeFinanceiroLancamentos from './organizze/financeiro/lancamentos/router'
import UsuariosWeb from './cadastros/entities/usuariosWeb/router'
import PremioPagoCentral from './relatorios/premioPagoCentral/router'
import PremioPagoBaixo from './relatorios/premioPagoBaixo/router'
import OrganizzeRelatoriosFluxoCaixa from './organizze/relatorios/fluxoCaixa/router'
import OrganizzeRelatoriosContas from './organizze/relatorios/contas/router'
// Relatorios Organizze
import organizzeRelatorios from './organizze/relatorios/router'
import organizzeRelatoriosDespesaReceita from './organizze/relatorios/despesaReceita/router'
import organizzeRelatoriosVendasProdutos from './organizze/relatorios/vendasProdutoModalidade/router'

// dashboard
import OrganizzeDashboard from './organizze/dashboard/router'


// Rotas de cadastros de terminais
import Terminais from './cadastros/terminais/router'
import Devices from './cadastros/terminais/devices/router'
// import DeviceTypes from './cadastros/terminais/deviceTypes/router'
import Chips from './cadastros/terminais/chips/router'
import Installations from './cadastros/terminais/installations/router'
import MsgRodapeTerminal from './cadastros/terminais/msgRodapeTerminal/router'

// Rotas de cadastros de produtos
import Produtos from './cadastros/produtos/router'
import Products from './cadastros/produtos/products/router'
// import Modalities from './cadastros/produtos/modalities/router'
import PreCaixa from './cadastros/preCaixa/router'
// envio de sms
import SendSms from './cadastros/sendSms/router'
import Notificao from './cadastros/notificacao/router'

// Rotas de cadastros Loterias
import Loterias from './cadastros/loterias/router'
import Sweepstakes from './cadastros/loterias/sweepstakes/router'
import SweepstakesRecurrent from './cadastros/loterias/sweepstakesRecurrent/router'
import Results from './cadastros/loterias/results/router'
import CadastroResultado from './cadastros/loterias/results/cadastroPopup/router'
import Recorrentes from './cadastros/loterias/recorrentes/router'
import CotacaoDinamica from './cadastros/loterias/cotadaDinamica/router'

import DescargaOnline from './cadastros/descargaOnline/router'

// Rotas de cadastro de bonificações
// import Bonificacoes from './cadastros/bonificacoes/router'
// import ListaBonificacoes from './cadastros/listaBonificacoes/router'
import BonusCupons from './cadastros/entities/cuponsBonus/router'

import Jogos from './cadastros/jogos/router'


// rotas de financeiros
// import Financeiro from './cadastros/financeiro/router'
// import EntryTypes from './cadastros/financeiros/entryTypes/router'
// import Entries from './cadastros/financeiro/entries/router'
// import LancamentoFinanceiro from './financeiros/lancamentoFinanceiro/router'
// import VendasCreditos from './cadastros/financeiro/vendasCreditos/router'
// import Despesas from './cadastros/financeiro/despesas/router'
// import Cobranca from './cadastros/financeiro/cobranca/router'
// import Doacoes from './cadastros/financeiro/doacoes/router'
// import TiposOperacoes from './cadastros/financeiro/tiposOperacoes/router'
import Relatorios from './relatorios/router'
import RelatorioComissoes from './relatorios/comissoes/router'
import RelatorioComissoesAdmin from './relatorios/comissoesAdmin/router'
// import RelatorioInfluenciadores from './relatorios/influenciadores/router'
import RelatorioGanhadores from './relatorios/premioPagoCentral/router'
import RelatorioGanhadoresAdmin from './relatorios/ganhadoresAdmin/router'
// import RelatorioArrecadacao from './relatorios/arrecadacao/router'
// import RelatorioTipoArrecadacao from './relatorios/tipoArrecadacao/router'
import RelatorioGanhadoresAdminPrint from './relatorios/ganhadoresAdminPrint/router'
// import RelatorioComissoesOsc from './relatorios/comissoesOsc/router'
// import RelatorioDreRede from './relatorios/dreRede/router'
import RelatoriosBi from './relatorios/bi/router'
import RelatoriosBiPainel from './relatorios/biPainel/router'
import RelatorioInstalacoes from './relatorios/instalacoes/router'
import RelatorioMoreira from './relatorios/dashboard/router'
import RelatorioResultados from './relatorios/resultadosLoterias/router'
import RelatorioResultadosBingo from './relatorios/resultadosBingo/router'
import RelatoriosGecom from './relatorios/gecom/router'
import GecomHome from './relatorios/gecomMobile/router'
import GefinMobile from './relatorios/gecomMobile/pages/gefin/router'
import GecomMobile from './relatorios/gecomMobile/pages/gecom/router'
import DadosRevenda from './relatorios/gecomMobile/pages/dadosRevenda/router'
import GecomPromotores from './relatorios/gecomPromotores/router'
import DadosRevendaGecom from './relatorios/gecomMobile/pages/dadosRevendaGecom/router'
import RelatoriosGecomResumido from './relatorios/gecomResumido/router'
import RelatoriosAniversariantes from './relatorios/aniversariantes/router'
import HistoricoVendas from './relatorios/historicoVendas/router'
import RelatoriosLancamentoCaixa from './relatorios/lancamentoCaixa/router'
import comissoesPromotores from './relatorios/comissoesPromotores/router'

/**
 * Rotas relacionadas a consultas
 */
import Consultas from './consultas/router'
// import Resgate from './consultas/resgate/router'
// import CadastroCestas from './consultas/cadastroCestas/router'
// import Doacoes from './consultas/doacoes/router'
import VendasCreditos from './consultas/vendasCreditos/router'
// import Parceiros from './consultas/parceiros/router'
import Acessos from './consultas/acessos/router'
// import Cupons from './consultas/cupons/router'
// import ResgateTitulos from './consultas/resgateTitulos/router'
import Instalacoes from './consultas/instalacoes/router'
// import Dashboard from './consultas/dashboard/router'
import SenhaPreCaixa from './consultas/senhaPreCaixa/router'
import Ganhadores from './consultas/ganhadores/router'
import Premios from './consultas/premios/router'

/**
 * Rotas de contratos
 */
import TermoConsentimento from './termoConsentimento/router'

// import ContaAzul from './contaAzul/router'

/**
 * Rotas relacionadas a financeiro
 */
import Financeiros from './financeiros/router'
// import Cobranca from './financeiros/cobranca/router'
import Entries from './financeiros/entries/router'
import Despesas from './financeiros/despesas/router'
import LancamentoFinanceiro from './financeiros/lancamentoFinanceiro/router'
import DRE from './financeiros/dre/router'
// import PagamentoTitulos from './financeiros/pagamentoTitulos/router'
import ZeraSaldo from './financeiros/zeraSaldo/router'
import FinanceiroBonus from './financeiros/Bonus/router'
import Despacho from './financeiros/despacho/router'
import Pagamentos from './relatorios/pagamentos/router'
import FechamentoCaixa from './relatorios/fechamentoCaixa/router'
import RelatorioSportBet from './relatorios/biSportBet/router'


/**
 * Rotas relacionadas ao Organizze
 */
import RelatoriosOrganizze from './organizze/relatorios/router'
import RelatorioCategorias from './organizze/relatorios/categorias/router'
import RelatorioOrganizzeBiRevendas from './organizze/relatorios/biRevendas/router'
import OrganizzeMais from './organizze/mais/router'

/**
 * Rotas relacionadas ao Esportes
 */
import Esportes from './esportes/router'
import Bilhetes from './esportes/bilhetes/router'

/**
 * Rotas relacionadas ao Esportes
 */
import Backoffice from './backoffice/router'
import BiOperacoes from './backoffice/biOperacoes/router'
import AnaliseClientes from './backoffice/analiseClientes/router'
 import CRM from './backoffice/crm/router'

const routes = [
  {
    path: '/',
    name: 'init',
    redirect: '/auth',
    component: () => import('@/views/init/index'),
    children:[
      ...Home,
      ...Cadastros,
      ...metas,
      ...listarMetas,
      ...metasEntidades,
      ...tiposIndices,
      ...autenticacao,
      ...Roles,
      ...Grupos,
      ...Permissoes,
      ...Endpoints,
      ...Users,
      ...Entidades,
      ...EntidadesPendentes,
      ...HistoricoRequisicaoPremioWeb,
      ...Osc,
      ...Estabelecimento,
      ...Credenciador,
      ...Organizze,
      ...OrganizzeFinanceiro,
      ...organizzeRelatorios,
      ...organizzeRelatoriosDespesaReceita,
      ...organizzeRelatoriosVendasProdutos,
      ...RelatorioOrganizzeBiRevendas,

      ...RelatorioSportBet,
      ...RelatoriosBi,
      ...RelatoriosBiPainel,
      ...Terminais,
      ...SendSms,
      ...Notificao,
      ...PreCaixa,
      ...OrganizzeFinanceiroContas,
      ...Devices,
      ...Chips,
      ...Installations,
      ...OrganizzeContatos,
      ...OrganizzeFinanceiroCategorias,
      ...OrganizzeDashboard,
      ...Produtos,
      ...Products,
      ...OrganizzeCentroCustos,
      ...Sweepstakes,
      ...SweepstakesRecurrent,
      ...OrganizzeFinanceiroLancamentos,
      ...Results,
      ...PremioPagoCentral,
      ...PremioPagoBaixo,
      ...OrganizzeRelatoriosFluxoCaixa,
      ...OrganizzeRelatoriosContas,
      ...Loterias,

      // Bonificacoes
      // ...ListaBonificacoes,
      ...BonusCupons,

      // Financeiros
      ...Financeiros,
      // ...EntryTypes,
      ...Entries,
      ...LancamentoFinanceiro,
      ...FinanceiroBonus,
      // ...Cobranca,
      // ...Doacoes,
      // ...TiposOperacoes,
      ...DRE,
      // ...PagamentoTitulos,
      ...HistoricoVendas,

      // rotas de consultas
      ...Consultas,
      ...UsuariosWeb,
      // ...Resgate,
      // ...CadastroCestas,
      // ...Parceiros,
      // ...ContaAzul,
      ...Acessos,
      // ...Cupons,
      // ...ResgateTitulos,
      ...Instalacoes,
      // ...Dashboard,
      ...Jogos,
      ...SenhaPreCaixa,
      ...Ganhadores,
      ...Premios,

      // relatorios em consultas
      ...RelatorioComissoes,
      ...RelatorioComissoesAdmin,
      // ...RelatorioInfluenciadores,
      ...RelatorioGanhadores,
      ...RelatorioGanhadoresAdmin,
      ...RelatorioMoreira,
      // ...RelatorioArrecadacao,
      // ...RelatorioTipoArrecadacao,
      ...Relatorios,
      ...RelatorioGanhadoresAdminPrint,
      // ...RelatorioDreRede,
      // ...Organizze,
      ...VendasCreditos,
      ...Despesas,
      // ...RelatorioComissoesOsc,
      ...RelatorioInstalacoes,
      ...RelatorioResultados,
      ...RelatoriosGecom,
      ...Feriados,
      ...Geolocalizacao,
      ...ZeraSaldo,
      ...Recorrentes,
      ...DescargaOnline,
      ...RelatoriosAniversariantes,
      ...Pagamentos,
      ...FechamentoCaixa,
      ...MsgRodapeTerminal,
      ...RelatoriosLancamentoCaixa,
      ...comissoesPromotores,
      ...RelatoriosGecomResumido,
      ...CotacaoDinamica,
      ...RelatoriosOrganizze,
      ...RelatorioCategorias,
      ...OrganizzeMais,
      ...RelatorioResultadosBingo,
      ...GecomHome,
      ...GefinMobile,
      ...DadosRevenda,
      ...GecomMobile,
      ...GecomPromotores,
      ...DadosRevendaGecom,
      ...Despacho,
      ...Esportes,
      ...Bilhetes,
      ...Backoffice,
      ...AnaliseClientes,
      ...CRM,
      ...BiOperacoes,
    ]
  },
  ...TermoConsentimento,
  ...Auth,
  ...CadastroResultado,
]

export default routes