import http from '@/core/http'

const state = {
  accountList: [],
  balanceList: []
}

const actions = {
  getAccounts: async ({ commit }) => {
    const result = await http.get('gefin-mais/contas/accounts')
    commit('GET_ACCOUNT_LIST', result.data.data[0])
    return Promise.resolve(result.data.data[0])
  },

  getBalances: async ({ commit }, payload) => {
    const result = await http.get('gefin-relatorios/fluxo-caixa-saldos', { params: payload })
    commit('GET_BALANCE_LIST', result.data.data)
    return Promise.resolve(result.data.data)
  },
}

const mutations = {
  'GET_ACCOUNT_LIST' (state, payload) { state.accountList = payload },
  'GET_BALANCE_LIST' (state, payload) { state.balanceList = payload }
}

const getters = {
  accountList: state => state.accountList,
  balanceList: state => state.balanceList,
  headers: state => {
    if (!state.balanceList[0]) return []
    let itens = state.balanceList.map(v => v.cabecalho)

    if(itens[0] !== null) itens.unshift(null)
    return itens
  }
}

export default { namespaced: true, state, actions, mutations, getters }