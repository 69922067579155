import http from '@/core/http'
import { map } from 'lodash'

const endPoint = 'produtos/tipos-premios'

const state = {
  itens: [],
  item: {
    sweepstakeId: '',
    number: '',
    value: 0.00,
    description: '',
    image: '',
  }
}

const actions = {
  getItens: async ({ commit }, payload) => {
    const result = await http.get(endPoint, { params: payload })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },

  cadastrarItem: async (_, payload) => {
    const result = await http.post(endPoint, payload)
    return Promise.resolve(result)
  },

  editarItem: async (_, payload) => {
    const result = await http.put(endPoint, payload)
    return Promise.resolve(result)
  },

  deletarItem: async (_, payload) => {
    const result = await http.delete(endPoint, {params: payload})
    return Promise.resolve(result)
  },

  getItem: ({ commit }, payload) => {
    commit('LISTA_ITEM', payload)
  },

  limparItem: ({ commit }) => {
    commit('LIMPAR_ITEM')
  },
  
  limparItens: ({ commit }) => {
    commit('LIMPAR_ITEM')
    commit('LIMOAR_ITENS')
  },

  getItensPrizeTypes: async ({ commit }, payload) => {
    const result = await http.get('prize-type', payload)
    commit('LISTA_ITENS_SELECT', result.data)
  }
}

const mutations = {
  'LISTA_ITENS' (state, payload) {
    state.itens = payload
  },

  'LISTA_ITEM' (state, payload) {
    state.item = Object.assign({}, payload)
  },

  'LIMPAR_ITEM' (state) {
    state.item = {
      sweepstakeId: '',
      number: '',
      value: 0.00,
      description: '',
      image: ''
    }
  },

  'LIMOAR_ITENS' (state) {
    state.itens = []
  },

  'LISTA_ITENS_SELECT' (state, payload) {
    state.itemSelect = payload
  }
}

const getters = {
  listaItens: state => state.itens,
  item: state => state.item,
  listaItensSelect: state => map(state.itens, item => {
    return { 
      text: item.name, 
      value: item.id,
      ascertainedMethodTypes: item.ascertainedMethodTypes
    }
  })
}

export default { namespaced: true, state, actions, mutations, getters }