import { map } from 'lodash'
import http from '@/core/http'

const state = {
  accounts: [],
  result: []
}

const actions = {
  getAccounts: async ({ commit }) => {
    const result = await http.get('gefin-mais/contas/accounts')
    commit('GET_ACCOUNT_LIST', result.data.data[0])
    return Promise.resolve(result.data.data[0])
  },

  submit: async ({ commit }, payload) => {
    const result = await http.get('gefin/relatorios/index', { params: payload })
    commit('GET_RESULT', result.data.data)
    return Promise.resolve(result.data.data)
  },
}

const mutations = {
  'GET_ACCOUNT_LIST' (state, payload) { state.accounts = map(payload, item => {
    item.balance = item.balance || item.initialBalance
    return item
  })},

  'GET_RESULT' (state, payload) { state.result = payload }
}

const getters = {
  accounts: state => state.accounts,
  result: state => state.result
}

export default { namespaced: true, state, actions, mutations, getters }
