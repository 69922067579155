import http from '@/core/http'
import { size } from 'lodash'
import storage from '@/core/service/localStorage'

const endPoint = 'user-agreements/'

const state = {
  termos: null,
  requisicao: 0
}

const actions = {
  getTermos: async ({ commit, state }) => {
    let user = storage.get('auth-token')
    if (!state.requisicao && user.responsibleEntity) {
      commit('SET_REQUISICAO', state.requisicao + 1)
      const result = await http.get(endPoint)
      commit('SET_TERMOS', result.data)
      return Promise.resolve(result)
    }
  },

  // eslint-disable-next-line
  addTermos: async (_, payload) => {
    console.log(payload)
    const result = await http.post(endPoint, payload)
    return Promise.resolve(result)
  }
}

const mutations = {
  'SET_TERMOS' (state, payload) {
    state.termos = payload
  },

  'SET_REQUISICAO' (state, payload) {
    state.requisicao = payload
  }
}

const getters = {
  termos: state => state.termos,
  termoAtual: state => {
    if (state.termos) {
      const hasTerm = state.termos[size(state.termos) - 1]
      return hasTerm ? hasTerm : null
    }

    return null
  }
}

export default { namespaced: true, state, actions, mutations, getters }