// eslint-disable-next-line no-unused-vars
import http from '@/core/http'
import { merge } from 'lodash'

const url = 'entidades/usuarios-web'

const state = {
  loadingTable: true,
  item: {
    date:  '',
    created:  '',
    id:  '',
    email:  '',
    deleted:  '',
    status:  '',
    entryType: {
      type: ''
    },
    jsonData: {
      cpf:  '',
      name:  '',
      fullName:  '',
      birth:  '',
      phone:  '',
      influencer:  '',
      bank: '',
      agency: '',
      account: '',
      digit: '',
      accountType: '',
    }
  },
  itens: null,
  totalItens: 0,
  userEntities: [],
  listExtratoCredito: [{
    date: '',
    description: '',
    entityId: 0,
    entityName: '',
    entryTypeId: '',
    id: 0,
    transaction: 0,
    value: ''
  }],
  listExtratoPremio: [{
    date: '',
    description: '',
    entityId: 0,
    entityName: '',
    entryTypeId: '',
    id: 0,
    transaction: 0,
    value: ''
  }],
  listDocuments: [{
    fileName: 'teste',
    description: 'test desc'
  }],
  isStatus: false,
  enabledPix: {
    buyCredits: [],
    redemptionPrizePIX: []
  }
}

const actions = {
  getItens: async ({ commit }, payload) => {
    if (!payload) payload = {}

    let _filter = { 'deleted_>=':'01/01/1971' }

    if (payload.hasOwnProperty('_filter') && state.isStatus) {
      payload._filter = merge(payload._filter, _filter)
    } else if (state.isStatus) {
      payload = Object.assign(payload, { _filter })
    }

    const result = await http.get(url, { params: Object.assign(payload, { order: { id: 'desc' }})})
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },

  getUserToId: async ({ commit }, payload) => {
    const result = await http.get(url, { params: payload})
    commit('LISTA_ITEM', result.data.resultMap[0])
    return Promise.resolve(result)
  },
  cadastrarItem: async (_, payload) => {
    const result = await http.post(url, payload)
    return Promise.resolve(result.data)
  },
  getItem: async ({ commit }, payload) => {
    commit('LISTA_ITEM', payload)
  },
  getExtratoCredito: async ({ commit }, payload) => {
    const headers = { moduloname: 'g-fin' }
    const result = await http.get('entradas', { params: {userWebId:payload.id, tipo:'Creditos'}, headers })
    commit('LIST_EXTRATO_CREDITO', result.data)
  },
  getExtratoPremio: async ({ commit }, payload) => {
    const headers = { moduloname: 'g-fin' }
    const result = await http.get('entradas', { params: {userWebId:payload.id, tipo:'Premio'}, headers })
    commit('LIST_EXTRATO_PREMIO', result.data)
  },
  editarItem: async ({ commit }, payload) => {
    const result = await http.get(`${url}/view`, { params: { cod: payload.cod } })
    commit('EDITAR_ITEM', result.data)
  },
  editSaveItem: async ({commit }, payload) => {
    const result = await http.put(url, payload)
    commit('EDITAR_ITEM', result )
  },
  deletarItem: async (_, payload) => {
    const result = await http.delete(url, {params: payload})
    return Promise.resolve(result.data)
  },

  // User Entities
  cadastrarUserEntities: async (_, payload) => {
    const result = await http.post('autenticacao/user-entities', payload)
    return Promise.resolve(result.data)
  },

  getItemUsersEntities: async ({ commit }, payload) => {
    // const result = await http.get('user-entities/list-all')
    commit('LIST_ITENS_USERS_ENTITIES', payload)
  },

  createDocuments: async (_, payload) => {
    const result = await http.post('usuarios-web/documents', payload)
    return Promise.resolve(result.data)
  },
  getDocuments: async ({commit}, payload) => {
    const result = await http.get('usuarios-web/documents/view/', {params:{entityId: payload.id}})
    commit('LISTA_DOCUMENTOS', result.data)
    return Promise.resolve(result.data)
  },
  deleteDocuments: async (_, payload) => {
    const result = await http.delete('usuarios-web/documents', {params: {id: payload.document.id}})
    return Promise.resolve(result.data)
  },
  setLoadingTable: async ({commit}, payload) => {
    commit('LOADING_TABLE', payload)
  },
  clearItens: ({ commit }) => {
    commit('CLEAR_ITENS')
  },

  switchStatus ({ commit }) {
    commit('SWITCH_STATUS')
  },

  getEnabledPix: async ({ commit }) => {
    const result = await http.get('entidades/usuarios-web/enabled-pix')
    delete result.data.date
    commit('SET_ENABLED_PIX', result.data)
    return Promise.resolve(result.data)
  }
}

const mutations = {
  'LISTA_ITENS' (state, payload) {
    state.itens = payload.resultMap
    state.totalItens = payload.totalItens
  },

  LIST_EXTRATO_CREDITO (state, payload) {
    state.listExtratoCredito = payload.resultMap
  },

  LIST_EXTRATO_PREMIO (state, payload) {
    state.listExtratoPremio = payload.resultMap
  },

  'LISTA_ITEM' (state, payload) {
    state.item = Object.assign({}, payload)
    state.item.password = ''
    state.item.passwordConfirmation = ''
  },

  'EDITAR_ITEM' (state, payload) {
    state.item = Object.assign({}, payload)
  },

  'LIST_ITENS_USERS_ENTITIES' (state, payload) {
    state.userEntities = payload
  },
  'LISTA_DOCUMENTOS' (state, payload) {
    state.listDocuments = payload.resultMap
  },
  'LOADING_TABLE' (state, payload) {
    state.loadingTable = payload
  },
  'CLEAR_ITENS' () {
    state.item= {
      date:  '',
      created:  '',
      id:  '',
      email:  '',
      deleted:  '',
      status:  '',
      entryType: {
        type: ''
      },
      jsonData: {
        cpf:  '',
        name:  '',
        fullName:  '',
        birth:  '',
        phone:  '',
        passwordConfirmation:  '',
        password:  '',
        influencer:  '',
        bank: '',
        agency: '',
        account: '',
        digit: '',
        accountType: '',
      }
    }
    state.userEntities = []
    state.listExtratoCredito = [{
      date: '',
      description: '',
      entityId: 0,
      entityName: '',
      entryTypeId: '',
      id: 0,
      transaction: 0,
      value: ''
    }]
    state.listExtratoPremio = [
      {
        date: '',
        description: '',
        entityId: 0,
        entityName: '',
        entryTypeId: '',
        id: 0,
        transaction: 0,
        value: ''
      }
    ]
  },

  'SWITCH_STATUS' (state) {
    state.isStatus = !state.isStatus
  },

  'SET_ENABLED_PIX' (state, payload) {
    state.enabledPix = payload
  }
}

const getters = {
  loadingTable: (state) => state.loadingTable,
  listaItens: (state) => state.itens,
  totalItens: (state) => state.totalItens,
  item: (state) => state.item,
  listExtratoCredito: (state) => state.listExtratoCredito.filter(item => item.entryTypeId !== 'SRT' && item.entryTypeId !== 'PSR'),
  listExtrataTitulosCapitalizacao: (state) => state.listExtratoCredito.filter(item => item.entryTypeId === 'SRT' || item.entryTypeId === 'PSR'),
  listExtratoPremio: (state) => state.listExtratoPremio,
  listDocuments: (state) => state.listDocuments,
  isStatus: state => state.isStatus,
  enabledPix: state => state.enabledPix,
}

export default { namespaced: true, state, actions, mutations, getters }