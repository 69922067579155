import http from '@/core/http'

const state = {
  items: []
}

const actions = {
  // eslint-disable-next-line
  getItens: async ({ commit }, payload) => {
    const result = await http.get('/zera-saldo')
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result.data)
  },

  clearData: async ({ commit }) => {
    commit('CLEAR_DATA')
  },

  zeraSaldo: async () => {
    const result = await http.post('/zera-saldo')
    return Promise.resolve(result.data)
  }
}

const mutations = {
  'LISTA_ITENS' (state, payload) {
    state.items = payload.resultMap
  },
  
  'CLEAR_DATA' (state) {
    state.items = []
  }
}

const getters = {
  getData: (state) => state.items
}

export default { namespaced: true, state, actions, mutations, getters }