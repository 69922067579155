// eslint-disable-next-line no-unused-vars
import http from '@/core/http'

const url = 'notificacoes'

const state = {
  id: null,
  name: null,
  itens: [],
  itensSelectEntities: [],
  totalItens: 0,
  totalItemSelectMensagemIndividual: 0,
  filtro: null,
  numeros: [],
  item:  {
    id: '',
    entityIds: [],
    tipoNotificacao: '',
    message: '',
    tipoBusca: 6
  },
}

const actions = {
  getItens: async ({ commit }, payload) => {
    const result = await http.get(url, { params: payload })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },
  obterItens: async ({ commit, state }, payload) => {
    const headers = {
      moduloname: 'gestao',
    }
    if (state.filtro) {
      payload.entityTypeId_In = state.filtro
    }
    const result = await http.get('entidades', { params: payload, headers })
    commit('LISTA_ITENS_SELECT_ENTITIES', result.data)
    return Promise.resolve(result.data)
  },

  cadastrarItem: async (_, payload) => {
    const result = await http.post(url, payload)
    // if (!result.data.success) throw result
    return Promise.resolve(result)
  },

  addFiltroAtual: ({commit}, payload) => {
    commit('ADD_FILTRO_ENTITI_ID', payload || 6)
  },
  clearItens: ({ commit }) => {
    commit('CLEAR_ITENS')
    commit('LIMPAR_ITEM')
  }
}

const mutations = {
  'ADD_DADOS_TABLE' (state, payload) {
    state.numeros = payload
  },
  'LISTA_ITENS' (state, payload) {
    state.itens = payload.resultMap[0]
    state.totalItens = payload.totalItens
  },
  'LISTA_ITENS_SELECT_ENTITIES' (state, payload) {
    state.itensSelectEntities = payload.resultMap
    state.totalItemSelectMensagemIndividual = payload.totalItens
  },
  'ADD_FILTRO_ENTITI_ID' (state, payload) {
    state.filtro = payload
  },
  'CLEAR_ITENS' (state, payload = []) {
    state.numeros = payload
  }
}

const getters = {
  listaItens: state => state.itens,
  item: state => state.item,
  totalItemSelect: (state) => state.totalItens,
  totalItemSelectMensagemIndividual: (state) => state.totalItemSelectMensagemIndividual,
  itensSelectEntities: (state) => state.itensSelectEntities.map(item => {
    return {
      text: item.name + (item.document ? ` (${item.document})` : ''),
      value: item.id
    }
  }),

  itensEntities: state => state.itensSelectEntities
}

export default { namespaced: true, state, actions, mutations, getters }