import Vue from 'vue'
import numeral from 'numeral'
import extenso from 'extenso'
// import { size } from 'lodash'

// seta configuracoes globais
numeral.register('locale', 'en-us', {
  delimiters: {
    thousands: ',',
    decimal: '.'
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't'
  },
  ordinal: (number) => {
    switch (number) {
      case 1:
        return 'st'
      case 2:
        return 'nd'
      case 3:
        return 'rd'
      default:
        return 'th'
    }
  },
  currency: {
    symbol: '$'
  }
})

numeral.register('locale', 'es-es', {
  delimiters: {
    thousands: ',',
    decimal: '.'
  },
  abbreviations: {
    thousand: 'mil',
    million: 'mil',
    billion: 'b',
    trillion: 't'
  },
  ordinal: () => {
    return 'º'
  },
  currency: {
    symbol: '€'
  }
})

numeral.register('locale', 'pt-br', {
  delimiters: {
    thousands: '.',
    decimal: ','
  },
  abbreviations: {
    thousand: 'mil',
    million: 'milhões',
    billion: 'b',
    trillion: 't'
  },
  ordinal: function () {
    return 'º'
  },
  currency: {
    symbol: 'R$'
  }
})

// const moneyFormat = '$ 0,00'
const ordinalFormat = 'Oo'

// ********** usar essa FUNCAO PARA FORMATAR CURRENCY -------------------------------------
export function currencyEqual (val, invert = false) {
  val = invert ? val * -1 : val // inverte sinal
  const locale = String(Vue.config.language).replace(`_`, `-`).toLowerCase()
  numeral.locale(locale)
  const number = String(parseFloat(val).toFixed(3))
  const n = numeral((String(number.substring(0 , number.length - 1)).replace('.', ','))).format('$ 0.00[,]00')
  return n
}

export function currency (val) {
  const locale = String(Vue.config.language).replace(`_`, `-`).toLowerCase()
  numeral.locale(locale)
  const n = numeral((String(parseFloat(val).toFixed(2)).replace('.', ','))).format('$ 0.00[,]00')
  return n
  // let format = String(value).split(',')
  // if (parseInt(format[1]) === 0) return value
  // return format[0] + ',' + String(format[1]).substr(0, 2)
}

export function currencyExtensive (val) {
  console.log(extenso(String(parseFloat(val).toFixed(2)).replace('.', ','), { locale: 'br', mode: 'currency', currency: { type: 'BRL' } }))
  return extenso(String(parseFloat(val).toFixed(2)).replace('.', ','), { locale: 'br', mode: 'currency', currency: { type: 'BRL' } })
}

export function currencyNoSymbol (val) {
  const locale = String(Vue.config.language).replace(`_`, `-`).toLowerCase()
  numeral.locale(locale)
  const n = numeral((String(parseFloat(val).toFixed(2)).replace('.', ','))).format('0.00[,]00')
  return n
  // let format = String(value).split(',')
  // if (parseInt(format[1]) === 0) return value
  // return format[0] + ',' + String(format[1]).substr(0, 2)
}

export function ordinal (val) {
  const locale = String(Vue.config.language).replace(`_`, `-`).toLowerCase()
  numeral.locale(locale)
  return numeral(val).format(ordinalFormat)
}


// export function currencyNoSymbol (val, show = true) {
//   const locale = String(Vue.config.language).replace(`_`, `-`).toLowerCase()
//   numeral.locale(locale)
//   switch (show) {
//     // mostra somente numero formatado
//     case true:
//       return numeral(val).format(String(moneyFormat).replace('$ ', ''))
//     // nao mostra numero 0
//     case false:
//       return numeral(val).format(String(moneyFormat)).replace('R$ 0,00', '').replace('€ 0.00', '').replace('$ 0.00', '')
//     // numero sem R
//     case 2:
//       return numeral(val).format(String(moneyFormat)).replace('R', '')
//     // retorna null e sem R
//     case 3:
//       return  numeral(val).format(String(moneyFormat)).replace('R$ 0,00', '').replace('€ 0.00', '')
//     // valores somente positivos sem R$
//     //   case 4:
//     //     if (val === null || val === undefined || val === '') return '0'
//     //     if (val < 0) {
//     //       val = -1 * val
//     //     }

//     //     const format = String(val).split('.')
//     //     if (size(format) === 1) return format[0]
//     //     return format[0] + ',' + String(format[1]).substr(0, 2)
//   }
// }

export function currencySymbol () {
  let _locale = String(Vue.config.language).replace(`_`, `-`).toLowerCase()
  let numeralObject = numeral.locales[_locale]
  return numeralObject.currency.symbol
}
