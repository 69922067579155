import http from '@/core/http'
import { map } from 'lodash'

const url = 'entidades/estabelecimento/comissao'

const state = {
  itens: [],
  item:  {
    id: '',
    comission: 0.0,
    idCredenciador: '',
  },
  itensSelect: []
}

const actions = {
  getItens: async ({ commit }, payload) => {
    const result = await http.get(url,  { params: payload })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },

  setItem: async ({ commit }, payload) => {
    commit('LISTA_ITEM', payload)
  },
  
  cadastrarItem: async (_, payload) => {
    const result = await http.post(url, payload)
    return Promise.resolve(result)
  },
  
  editarItem: async (_, payload) => {
    const result = await http.put(url, payload)
    return Promise.resolve(result.data)
  },

  deletarItem: async (_, payload) => {
    const result = await http.delete(url, { params: payload })
    return Promise.resolve(result.data)
  },
  
  clearDados: async ({ commit }) => {
    commit('CLEAR_ITENS')
  },

  clearForm: async ({ commit }) => {
    commit('CLEAR_FORM')
  },

  obterItensSelect: async ({ commit }, payload) => {
    if (payload.hasOwnProperty('_filter')) {
      payload['_filter'] = Object.assign(payload['_filter'], { 'entityTypeId_In': '3' })
    } else {
      payload['_filter'] = { 'entityTypeId_In': '3' }
    }

    // const result = await http.get('/entidades/credenciador', { params: payload })
    const result = await http.get('entidades/list-tree', { params: payload })
    commit('LISTA_ITENS_SELECT', result.data)
    return Promise.resolve(result)
  },
}

const mutations = {
  'LISTA_ITENS' (state, payload) {
    state.itens = payload
  },

  'LISTA_ITEM' (state, payload) {
    const item = Object.assign({}, payload)
    state.item = {
      id: item.id,
      comission: item.aliquot,
      idCredenciador: item.parentId
    }
  },

  'LISTA_ITENS_SELECT' (state, payload) {
    state.itensSelect = payload
  },

  'CLEAR_ITENS' (state) {
    state.itens = []
    state.item = {
      id: '',
      comission: ''
    }
  },

  'CLEAR_FORM' (state) {
    state.item.id = ''
    state.item.comission = ''
  }
}

const getters = {
  listaItens: state => state.itens,
  item: state => state.item,
  listaItensSelect: (state) => {
    return map(state.itensSelect, item => {
      return {
        text: item.text,
        value: item.value
      }
    })
  }
}

export default { namespaced: true, state, actions, mutations, getters }