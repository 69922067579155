import { currency, ordinal, currencyNoSymbol, currencySymbol, currencyEqual, currencyExtensive } from '@/core/filters/currency'
import { nomeUrl } from './showNome'
import { datas } from './datas'
import { document } from './document'
import { phone, phone2 } from './phone'
import { percentage } from './percentage'
import { number } from './numeral'
export default {
  install (Vue) {
    Vue.filter('ordinal', ordinal)
    Vue.filter('currency', currency)
    Vue.filter('currencyExtensive', currencyExtensive)
    Vue.filter('currencyEqual', currencyEqual)
    Vue.filter('currencyNoSymbol', currencyNoSymbol)
    Vue.filter('currencySymbol', currencySymbol)
    Vue.filter('nomeUrl', nomeUrl)
    Vue.filter('datas', datas)
    Vue.filter('document', document)
    Vue.filter('phone', phone)
    Vue.filter('phone2', phone2)
    Vue.filter('percentage', percentage)
    Vue.filter('numeral', number)
  }
}
