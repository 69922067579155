import http from '@/core/http'
import { groupBy } from 'lodash'

const state = {
  lotteries: [],
  results: [],
  ganhadores: [],
}
const actions = {
  getSweepstakesResults: async ({commit}, payload) => {
    // if (payload.cod === 'TODAS') payload.cod = ''
    const result = await http.get('/loterias/concursos/resultado-bingo/', { params: { ...payload } })
    commit('ADD_RESULTS', result.data.result)
    return Promise.resolve(false)
  },
  getLotteries: async ({commit}, payload) => {
    const result = await http.get('/loterias/concursos/loterias/', { params: { ...payload } })
    commit('SWEEPSTAKES_GROUPS', result.data.lotteries.filter(item => item.ascertained_method_id === 'BINGO'))
    return Promise.resolve(result.data.lotteries)
  },
  getAllLotteries: async ({commit}, payload) => {
    const result = await http.get('/loterias/concursos/loterias/', { params: { ...payload } })
    commit('SWEEPSTAKES_GROUPS', result.data.lotteries)
    return Promise.resolve(result.data.lotteries)
  },

  limparResultado({ commit }) {
    commit('CLEAR_RESULTS')
  },

  limparLoterias({ commit }) {
    commit('CLEAR_LOTTERIES')
  }
}
const mutations = {
  'ADD_RESULTS' (state, payload) {
    state.results = payload
    state.ganhadores = {
      premioCartelaFechada: Object.values(groupBy(groupBy(payload.lotteryGuessWinners.filter(item => item.prizes.prizeTypeId === 'PRCF'), item => item.prizeNumber), item => item.entityId)),
      premioColuna: Object.values(groupBy(groupBy(payload.lotteryGuessWinners.filter(item => item.prizes.prizeTypeId === 'PRC'), item => item.prizeNumber), item => item.entityId)),
      premioLinha: Object.values(groupBy(groupBy(payload.lotteryGuessWinners.filter(item => item.prizes.prizeTypeId === 'PRL'), item => item.prizeNumber), item => item.entityId)),
      premioTransversal: Object.values(groupBy(groupBy(payload.lotteryGuessWinners.filter(item => item.prizes.prizeTypeId === 'PRT'), item => item.prizeNumber), item => item.entityId)),
      premioExtra: Object.values(groupBy(groupBy(payload.lotteryGuessWinners.filter(item => item.prizes.prizeTypeId === 'PRE'), item => item.prizeNumber), item => item.entityId)),
    }
  },
  'SWEEPSTAKES_GROUPS'(state, payload) {
    if (payload) {

      state.lotteries = payload
    }
  },

  'CLEAR_RESULTS'(state) {
    state.results = []
    state.ganhadore =  []
  },

  'CLEAR_LOTTERIES'(state) {
    state.lotteries = []
  }
}
const getters = {
  results: state => state.results,
  ganhadores: state => state.ganhadores,
  lotteries: state => state.lotteries.map(item => ({
    text: item.cod,
    value: item.id
  }))
}

export default { namespaced: true, state, actions, mutations, getters }