export default [
    {
      path: 'd-fin',
      name: 'd-fin',
      component: () => import('./index'),
      children: [],
      meta: {
        visible: false,
        title: 'Organizze',
        breadcrumb: [
          { name: 'Home', link: 'home' },
          { name: 'D-FIN' }
        ]
      }
    },
  ]