export default [
  {
    path: 'gestao/entidades/bonus-cupons',
    name: 'bonus-cupons',
    component: () => import('./index'),
    meta: {
      visible: true,
      title: 'Agência',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Gestão', link: 'gestao' },
        { name: 'Rede', link: 'entidades' },
        { name: 'Bônus de cupons' }
      ]
    }
  }
]