export default [
  {
    path: 'gestao/descarga-online',
    name: 'descarga-online',
    component: () => import('./index'),
    children: [],
    meta: {
      visible: false,
      title: 'Prê-caixa',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Gestão', link: 'gestao' },
        { name: 'Descarga Online' }
      ]
    }
  },
]