export default [
  {
    path: 'gestao/autenticacao',
    name: 'autenticacao',
    component: () => import('./index'),
    children: [],
    meta: {
      visible: false,
      title: 'Autenticação',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Gestão', link: 'gestao' },
        { name: 'Autenticação' }
      ]
    }
  },
]