import http from '@/core/http'
// import { map } from 'lodash'

const state = {
  data: [],
  dataConfigLimites: {
    maxPrizeValue: '',
    modalities: []
  },

  modality: {
    value: '',
    modalityId: ''
  },
  totalItens: 0,
  cotacao: {
    loteria: '',
    palpite: '',
    modalidade: '',
  },
}
const actions = {
  getItens: async ({commit}, payload) => {
    const result = await http.get('loterias/cotacao-dinamica', { params: payload })
    commit('LIST_DATA', result.data.result)
    return Promise.resolve(result.data.data)
  },

  bloquearCotacao: async (_, payload) => {
    const result = await http.put('loterias/cotacao-dinamica', payload)
    return Promise.resolve(result.data.data)
  },

  registerCotacao: async (_, payload) => {
    const result = await http.post('loterias/cotacao-dinamica', payload)
    return Promise.resolve(result.data.data)
  },

  getItemsConfigLimites: async ({ commit }, payload) => {
    const result = await http.get('loterias/cotacao-dinamica/config-limites', payload)
    commit('LIST_DATA_CONFIG_LIMITES', result.data.result)
    return Promise.resolve(result.data.data)
  },

  editConfigLimites: async ({ commit }, payload) => {
    const result = await http.put('loterias/cotacao-dinamica/config-limites', payload)
    commit('LIST_DATA_CONFIG_LIMITES', result.data.result.params)
    return Promise.resolve(result.data.data)
  },

  setCotacao: async ({ commit }, payload) => {
    commit('SET_COTACAO', payload)
  },

  addCotacao: async ({ commit }, payload) => {
    commit('ADD_COTACAO', payload)
  },

  deleteCotacao: async ({ commit }, payload) => {
    commit('DELETE_COTACAO', payload)
  }
}
const mutations = {
  'LIST_DATA'(state, payload) {
    state.data = payload[0]
    state.totalItens = payload[1]
  },

  'LIST_DATA_CONFIG_LIMITES'(state, payload) {
    state.dataConfigLimites = {
      maxPrizeValue: payload.maxPrizeValue,
      modalities: payload.modalitiesQuotated.map(item => ({
        value: item.maxPrizeValue,
        modalityId: item.modalityId,
        maxHit: item.maxHit ? item.maxHit : undefined
      }))
    }
  },

  'SET_COTACAO'(state, { value, modalityId, index }) {
    state.modality = {
      value,
      modalityId,
      index
    }
  },

  'ADD_COTACAO'(state, { value, modalityId, index }) {
   if (index >= 0) {
      state.dataConfigLimites.modalities.splice(index, 1, { value, modalityId })
      delete state.modality.index

      return
    }

    state.dataConfigLimites.modalities.push({ value, modalityId })
    state.modality = {
      value: '',
      modalityId: ''
    }
  },

  'DELETE_COTACAO'(state, payload) {
    state.dataConfigLimites.modalities.splice(payload, 1)
  }
}
const getters = {
  listaItens: state => state.data,
  data: state => state.data,
  totalItens: state => state.totalItens,
  cotacao: state => state.cotacao,
  dataConfigLimites: state => state.dataConfigLimites,
  modality: state => state.modality
}

export default { namespaced: true, state, actions, mutations, getters }