export default [
  {
    path: 'backoffice/crm',
    name: 'crm',
    component: () => import('./index'),
    children: [],
    meta: {
      visible: true,
      title: 'CRM',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Backoffice', link: 'backoffice' },
        { name: 'CRM' },
      ]
    }
  },
]