export default [
    {
      path: 'd-fin/gefin-mais/gefin-cost-center',
      name: 'gefin-cost-center',
      component: () => import('./index'),
      children: [],
      meta: {
        visible: false,
        title: 'Centro de Custos',
        order: '5',
        breadcrumb: [
          { name: 'Home', link: 'home' },
          { name: 'GEFIN', link: 'gefin' },
          { name: 'Mais', link: 'gefin-mais' },
          { name: 'Centro de Custos' }
        ]
      }
    },
  ]