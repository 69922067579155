import http from '@/core/http'
// import { map } from 'lodash'

const endPoint = 'produtos/resultados'

const state = {
  itens: [],
  item: {
    id: '',
    sweepstakeId: '',
    number: '',
    value: '',
    description: '',
  },
  lista: '',
  itemAddPermission: [],
  acl: null,
  itemSelect: [],
  totalItens: 0,
  date:''
}

const actions = {
  getSweepstakes: async ({ commit }, payload) => {
    const result = await http.get('loterias/concursos', { params: payload })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },

  getItens: async ({ commit }, payload) => {
    const result = await http.get(endPoint, { params: payload })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },

  cadastrarItem: async (_, payload) => {
    const result = await http.post(endPoint, payload)
    return Promise.resolve(result)
  },

  editarItem: async (_, payload) => {
    const result = await http.put(endPoint, payload)
    return Promise.resolve(result)
  },

  deletarItem: async (_, payload) => {
    const result = await http.delete(endPoint, {params: payload})
    return Promise.resolve(result)
  },

  getItem: ({ commit }, payload) => {
    commit('LISTA_ITEM', payload)
  },

  limparItem: ({ commit }) => {
    commit('LIMPAR_ITEM')
  }
}

const mutations = {
  'LISTA_ITENS' (state, payload) {
    state.itens = payload.resultMap
    state.totalItens = payload.totalItens
    state.date = payload.date
  },

  'LISTA_ITEM' (state, payload) {
    state.item = Object.assign({}, payload)
  },

  'LIMPAR_ITEM' (state) {
    state.item = {
      sweepstakeId: '',
      number: '',
      value: '',
      description: '',
    }
  },

  'LISTA_ITENS_SELECT' (state, payload) {
    state.itemSelect = payload
  }
}

const getters = {
  listaItens: state => state.itens,
  totalItens: state => state.totalItens,
  item: state => state.item,
  getDate: state => state.date
}

export default { namespaced: true, state, actions, mutations, getters }