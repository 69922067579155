export default [
    {
      path: 'd-fin/dashboard',
      name: 'dashboard',
      component: () => import('./index'),
      children: [],
      meta: {
        visible: false,
        title: 'Dashboard',
        order: '1',
        breadcrumb: [
          { name: 'Home', link: 'home' },
          { name: 'D-FIN', link: 'd-fin' },
          { name: 'Dashboard' }
        ]
      }
    },
  ]