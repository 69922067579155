export default [
  {
    path: 'relatorios/resultados-bingos',
    name: 'resultados-bingos',
    component: () => import('./index'),
    meta: {
      visible: true,
      title: 'Resultados Bingos',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Relatórios', link: 'relatorios' },
        { name: 'Resultados Bingos'},
      ]
    }
  }
]
