import http from '@/core/http'
import { map } from 'lodash'

const state = {
  sweepstakes_recurrences: []
}
const actions = {
  getSweepstakesRecurrences: async ({commit}) => {
    const result = await http.get('/loterias/concursos-recorrentes')
    const data = map(result.data.resultMap, item => {
      return {
        text: item.cod,
        value: item
      }
    })
    commit('SWEEPSTAKES_RECURRENCES', data)
    return Promise.resolve(data)
  },
  addSweepstakesRecurrences: async (_, payload) => {
    const result = await http.post('/loterias/concursos-recorrentes/registrar', { sweepstakeRecurrenceId: payload.selectedSweepstake.id, date: payload.date, bruteForce: true }, { headers: { moduloname: 'gestao' }})
    return Promise.resolve(result)
  }
}
const mutations = {
  'SWEEPSTAKES_RECURRENCES' (state, payload) {
    state.sweepstakes_recurrences = payload
  }
}
const getters = {
  sweepstakes_recurrences: state => state.sweepstakes_recurrences
}

export default { namespaced: true, state, actions, mutations, getters }