import Vue from 'vue'
import Vuex from 'vuex'
import modules from '@/views/store'
import * as getters from './getters'
import http from '@/core/http'

Vue.use(Vuex)

// gambis para realizar pooling para nao deslogar do gestao porque o BI nao faz request para executar refresh do token
const state = {
  rotas: [ 
  '/backoffice/analise-clientes',
  '/backoffice/bi-operacoes',
  '/d-fin/gefin-relatorios/bi',
  '/d-fin/gefin-relatorios/relatorios-bi-revendas',
  '/esportes/bi-sport-bet',
  ],
  intervalRequestBI: null
}

const actions = {
  startIntervalRequestBI: ({ commit }) => {
    commit('ADD_INTERVAL')
  },
}

const mutations = {
  'ADD_INTERVAL' (state) {
    state.intervalRequestBI = setInterval(() => {
      const url = window.location.pathname
      if (state.rotas.includes(url)) {
        http.get('/', {params: { 'update-token-bi': true }})
      }
    }, 1000 * 60 * 8)
  }
}

const store = new Vuex.Store({
  state,
  actions,
  mutations,
  getters,
  modules
})

export default store
