// eslint-disable-next-line no-unused-vars
import http from '@/core/http'
import storage from '@/core/service/localStorage'
import cookies from '@/core/service/session'

const state = {
  token: null,
  user: {
    name: '',
    id: '',
    email: '',
    userEntityIds: [],
    userEntityPaths: [],
    jsonFlags: {},
  }
}

const headerAuth = (userToken, password) => window.btoa(`${userToken}:${password}`)
const actions = {
  /* Authenticação */
  login ({commit}, payload) {
    const headers = { headers: { Authorization: 'Basic ' + headerAuth(payload.name, payload.password) } }
    const data = { lang: payload.lang || 'teste' }
    const firebaseToken = cookies.get('firebase-token')

    if (firebaseToken) data['firebaseToken'] = firebaseToken

    return http.post(`autenticacao/usuarios/auth`, data, headers).then((res) => {
      // commit('LOGIN_SUCCESS', res.data.auth)
      commit('LOGIN_SUCCESS', res.data)
      return Promise.resolve(res)
    })
  },
  recoverPassword ({commit}, payload) {
    return http.post(`recover/sendEmailRecoverPassword`, { payload }).then((res) => {
      commit('SEND_EMAIL_SUCCESS', res.data.message)
      return Promise.resolve(res)
    })
  },

  async getUser ({commit}) {
    let user = storage.get('auth-token')
    const result = await http.post('/autenticacao/roles/show', { id: user.roleId }, {headers: { moduloname: 'gestao'}})
    user = Object.assign(user, { role: result.data.name })    
    if (user) commit('SET_USER', user)
  },

  clearUser ( { commit } ) {
    commit('CLEAR_USER')
  }
}

const mutations = {
  'LOGIN_SUCCESS' (state, e) {
    state.token = e.auth
    state.user = {
      name: e.name,
      id: e.id,
      email: e.email,
      userEntityIds: e.userEntityIds,
      userEntityPaths: e.userEntityPaths,
      responsibleEntity: e.responsibleEntity,
      jsonFlags: e.jsonFlags
    }
    storage.set('jwt', e.jwt)
    storage.set('auth-token', e)
  },
  'SEND_EMAIL_SUCCESS' (state, e) {
    state.username = e
  },
  'SET_USER' (state, e) {
    state.user = {
      name: e.name,
      id: e.id,
      email: e.email,
      role: e.role,
      userEntityIds: e.userEntityIds || [],
      userEntityPaths: e.userEntityPaths || [],
      jsonFlags: e.jsonFlags
    }
  },
  
  'CLEAR_USER' (state) {
    state.user = {
      name: '',
      id: '',
      email: '',
      userEntityIds: [],
      userEntityPaths: []
    }
  }
}

const getters = {
  user: state => state.user
}

export default { namespaced: true, state, actions, mutations, getters }