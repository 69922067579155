import http from '@/core/http'
// eslint-disable-next-line
import { map } from 'lodash'
// eslint-disable-next-line
// import moment from 'moment'


const state = {
  itens: [],
  configGecom: {},
  totais: {}
}

const actions = {
  getItens: async ({ commit }, payload) => {
    const result = await http.get('gecom-home/gecom-mobile', { headers: { moduloname: 'relatorios' }, params: payload })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },
  
  clearItens () {}
}

const mutations = {
  'LISTA_ITENS' (state, payload) {
    // debugger
    state.itens = payload.data
    state.configGecom = payload.paramsConfig
    state.totais = payload.totais
  }
}

const getters = {
  listaItens: state => state.itens,
  configGecom: state => state.configGecom,
  totais: state => state.totais,
}

export default { namespaced: true, state, actions, mutations, getters }