export default [
  {
    path: 'gestao/autenticacao/module-groups',
    name: 'module-groups',
    component: () => import('./index'),
    meta: {
      visible: true,
      title: 'Module-Groups',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Gestão', link: 'gestao' },
        { name: 'Autenticação', link: 'autenticacao' },
        { name: 'Module-Groups' }
      ]
    }
  }
]