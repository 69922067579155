import http from '@/core/http'

const state = {
  itens: [],
  totalItens: 0
}

const actions = {
  getItens: async ({commit}, payload) => {
    const result = await http.get('/web-prize-requests', { params: payload })
    commit('GET_ITENS', result.data)
    return Promise.resolve(result.data.result)
  }
}

const mutations = {
  'GET_ITENS' (state, payload) {
    state.itens = payload.result
    state.totalItens = payload.totalItens
  }
}

const getters = {
  itens: state => state.itens,
  totalItens: (state) => state.totalItens
}

export default { namespaced: true, state, actions, mutations, getters }