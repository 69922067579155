import http from '@/core/http'

const state = {
  revenda: [],
  historicoVendas: [],
  configGecom: {},
  totais: {}
}

const actions = {
  setDataRevenda: async ({ commit }, payload) => {
    const result = await http.get('gecom-home/dados-revenda-gecom', { headers: { moduloname: 'relatorios' }, params: payload })
    commit('LISTA_ITENS', result.data)
    // return Promise.resolve(result)
  },

  getHistoricoVendas: async ({ commit }, payload) => {
    const result = await http.get('gecom-home/historico-vendas-gecom', { headers: { moduloname: 'relatorios' }, params: payload })
    commit('LISTA_HISTORICO_VENDAS', result.data)
    // return Promise.resolve(result)
  },

  clearItens () {}
}

const mutations = {
  'LISTA_ITENS' (state, payload) {
    state.revenda = payload.data
  },

  'LISTA_HISTORICO_VENDAS' (state, payload) {
    state.historicoVendas = payload.data
  }
}

const getters = {
  revenda: state => state.revenda,
  historicoVendas: state => state.historicoVendas,
  configGecom: state => state.configGecom,
  totais: state => state.totais,
}

export default { namespaced: true, state, actions, mutations, getters }