import Vue from 'vue'
import numeral from 'numeral'

export function percentage (val) {
  const valor = val ? val : 0

  const locale = String(Vue.config.language).replace(`_`, `-`).toLowerCase()
  numeral.locale(locale)
  const value = String(parseFloat(valor).toFixed(2)).replace('.', ',')
  return `${value}%`
}