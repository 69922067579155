export default [
  {
    path: 'g-fin/entradas',
    name: 'entradas',
    component: () => import('./index'),
    meta: {
      visible: true,
      title: 'Histórico Financeiro',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Financeiro', link: 'financeiro' },
        { name: 'Histórico Financeiro' }
      ]
    }
  }
]