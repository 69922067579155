export default [
  {
    path: 'consultas/senha-pre-caixa',
    name: 'senha-pre-caixa',
    component: () => import('./index'),
    children: [],
    meta: {
      visible: true,
      title: 'Jogos',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Consultas', link: 'consultas' },
        { name: 'Senha pré caixa' },
      ]
    }
  },
]