import http from '@/core/http'
// eslint-disable-next-line
import { map } from 'lodash'
// eslint-disable-next-line
// import moment from 'moment'

const url = '/gecom-home/'

const state = {
  data: {
    deve_atual: '',
    caixa: '',
    pagto: ''
  },
  listMovimentos: [],
  configGecom: {},
  totais: {}
}

const actions = {
  getItens: async ({ commit }, payload) => {
    const result = await http.get(url, { headers: { moduloname: 'relatorios' }, params: payload })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },

  getData: async ({ commit }, payload) => {
    const result = await http.get(url, { headers: { moduloname: 'relatorios' }, params: payload })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },  

  getMovimentos: async ({ commit }, payload) => {
    const result = await http.get('gecom-home/movimento', { headers: { moduloname: 'relatorios' }, params: payload })
    commit('LISTA_MOVIMENTOS', result.data)
    return Promise.resolve(result)
  },  

  clearItens () {}
}

const mutations = {
  'LISTA_ITENS' (state, payload) {
    state.data = payload.data[0]
  },

  'LISTA_MOVIMENTOS' (state, payload) {
    state.listMovimentos = payload.data
  }
}

const getters = {
  data: state => state.data,
  configGecom: state => state.configGecom,
  totais: state => state.totais,
  listMovimentos: state => state.listMovimentos,
}

export default { namespaced: true, state, actions, mutations, getters }