export default [
  {
    path: 'relatorios/fechamento-caixa',
    name: 'fechamento-caixa',
    component: () => import('./index'),
    meta: {
      visible: true,
      title: 'Fechamento Caixa',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Relatórios', link: 'relatorios' },
        { name: 'Fechamento de Caixa'},
      ]
    }
  }
]
