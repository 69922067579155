export default [
  {
    path: 'relatorios/lancamento-caixa',
    name: 'lancamento-caixa',
    component: () => import('./index'),
    children: [],
    meta: {
      visible: true,
      title: 'Lancamento de Caixa',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Relatórios', link: 'relatorios' },
        { name: 'Lancamento de Caixa' },
      ]
    }
  },
]