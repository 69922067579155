import Events from '@/core/service/events'
import Vue from 'vue'
import store from '@/core/store'
import moment from 'moment'
import numeral from 'numeral'

export const errorSnackbar = (err) => {
  Events.$emit('snackbarCadastro::msg', {
    toggle: true,
    type: 'error',
    msg: err
  })
}

export const successSnackbar = (msg) => {
  Events.$emit('snackbarCadastro::msg', {
    toggle: true,
    type: 'success',
    msg: msg
  })
}


export const currency = (value) => {
  let currency = ''
  const locale = String(Vue.config.language).replace(`_`, `-`)
  switch(locale) {
    case 'pt-BR':
      currency = 'BRL'
      break
  }


  return new Intl.NumberFormat(locale, { style: 'currency', currency: currency }).format(value)
}

export const percentage = (val) => {
  const valor = val ? val : 0

  const locale = String(Vue.config.language).replace(`_`, `-`).toLowerCase()
  numeral.locale(locale)
  const value = String(parseFloat(valor).toFixed(2)).replace('.', ',')
  return `${value}%`
}

export const formatPhone = (number) => {
  const numberWithoutCaracteres = number.replace(/\D/g, '')
  return `${store.getters.ddi}${numberWithoutCaracteres.substring(0, 2)}${numberWithoutCaracteres.substring(2, numberWithoutCaracteres.length)}`
}

export const convertMinutesToHours = minutes => {
  if (minutes >= 24 * 60 || minutes < 0) {
    return minutes
  }

  let 
    h = minutes / 60 | 0,
    m = minutes % 60 | 0

  return moment().hours(h).minutes(m).format('HH:mm')
}

export const formatDate = (date, format = 'DD/MM/YYYY HH:mm:ss') => {
  const locale = String(Vue.config.language).replace(`_`, `-`)
  switch (locale) {
    case 'pt-BR':
      return moment(date).format(format)  
    default:
      break;
  }
} 

export const premiosFormat = e => {
  if (String(e).length > 1) {
    return `${String(e).charAt(0)}º ao ${String(e).charAt(1)}º`
  }
  return `${e}º`
}

export function parseTime(time, cFormat) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string')) {
      if ((/^[0-9]+$/.test(time))) {
        // support "1548221490638"
        time = parseInt(time)
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), '/')
      }
    }

    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value ] }
    return value.toString().padStart(2, '0')
  })
  return time_str
}
