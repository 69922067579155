export default [{
  path: 'd-fin/gefin-relatorios/despesas-receitas',
  name: 'despesas-receitas',
  component: () => import('./index'),
  meta: {
    visible: true,
    title: 'Performance',
    order: '2',
    breadcrumb: [
      { name: 'Home', link: 'home' },
      { name: 'D-FIN', link: 'd-fin' },
      { name: 'Relatorios', link: 'gefin-relatorios' },
      { name: 'Performance' }
    ]
  }
}]
