import http from '@/core/http'
import { map, groupBy, forEach } from 'lodash'
import moment from 'moment'

const url = 'historico-vendas'

const state = {
  itens: [],
  item: {
    entityId: '',
    codBilhete: '',
    nrJogo: ''  
  },
  lista: '',
  itemAddPermission: [],
  acl: null,
  itemSelect: [],
  totalItens: 0,
  transmissoes: [],
  listGames: [],
  listPrizes: [],
  listGamesWinners: [],
  listCaixa: [],
  hasMonthOrDate: 1
}

const actions = {
  getItens: async ({ commit }, payload) => {
    const result = await http.get(url, { params: payload, headers: { moduloname: 'relatorios' } })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },

  getFilterVendas: async ({ commit, state }, payload) => {
    const result = await http.get(`${url}/filtro-vendas/`, { params: payload, headers: { moduloname: 'relatorios' } })

    function reduceTotais (obj, type) {
      return obj.reduce((acc, next) => {
        const total = next[type]
        return total ? acc + parseFloat(total) : acc
      }, 0)
    }
    
    if (state.hasMonthOrDate === 2) {
      result.data.data = groupBy(result.data.data, item => moment(item.date).format('MM/YYYY'))

      let arrResults = []
  
  
      forEach(result.data.data, (item, key) => {
        arrResults.push({
          date: moment(key, 'MM/YYYY'),
          proposta: reduceTotais(item, 'proposta'),
          comissao: reduceTotais(item, 'comissao'),
          saldo: reduceTotais(item, 'saldo'),
          caixa: reduceTotais(item, 'caixa'),
          pagto: reduceTotais(item, 'pagto'),
          premio: reduceTotais(item, 'premio'),
          entity_id: item[0].entity_id,
          entity_cod: item[0].entity_cod,
          entity_name: item[0].entity_name,
          deve_ant: item[0].deve_ant,
          deve_atual: item[item.length - 1].deve_atual,
        })
      })
  
      result.data.data = arrResults
    }

    // result.data.results = orderBy(result.data.results, 'date', 'asc')

    commit('LISTA_TRANSMISSOES', result.data)
    // commit('PRIZES_LIST', resultPrizesGrouped)
    return Promise.resolve(result)
  },

  getListJogos: async ({ commit }, payload) => {
    const result = await http.get(`historico-vendas/filtro-vendas/palpites`, { params: payload , headers: { moduloname: 'relatorios' }})
    commit('LIST_GAMES', result.data)
    return Promise.resolve(result)
  },

  getListJogosWinners: async ({ commit }, payload) => {
    const result = await http.get(`historico-vendas/filtro-vendas/winners`, { params: payload, headers: { moduloname: 'relatorios' }})
    commit('LIST_GAMES_WINNERS', result.data)
    return Promise.resolve(result)
  },

  getListCaixa: async ({ commit }, payload) => {
    const result = await http.get(`entradas`, { params: payload , headers: { moduloname: 'g-fin' }})
    commit('LIST_CAIXA', result.data)
    return Promise.resolve(result)
  },

  cadastrarItem: async (_, payload) => {
    const result = await http.post(url, payload)
    return Promise.resolve(result)
  },

  editarItem: async (_, payload) => {
    const result = await http.put(url, payload)
    return Promise.resolve(result)
  },

  deletarItem: async (_, payload) => {
    const result = await http.delete(url, {params: payload})
    return Promise.resolve(result)
  },

  getItem: ({ commit }, payload) => {
    commit('LISTA_ITEM', payload)
  },

  limparItem: ({ commit }) => {
    commit('LIMPAR_ITEM')
  },

  limparItemModalJogos: ({ commit }) => {
    commit('LIMPAR_ITEM_MODAL_JOGOS')
  },

  setHasMonthOrDate: ({ commit }, payload) => {
    commit('SET_HAS_MONTH_OR_DATE', payload)
  }
}

const mutations = {
  'LISTA_ITENS' (state, payload) {
    state.itens = payload.resultMap
    state.totalItens = payload.totalItens
  },

  'LISTA_TRANSMISSOES' (state, payload) {
    state.transmissoes = payload
  },

  'PRIZES_LIST' (state, payload) {
    state.listPrizes =  payload
  },

  'LIST_GAMES_WINNERS' (state, payload) {
    state.listGamesWinners =  payload.resultMap
  },

  'LISTA_ITEM' (state, payload) {
    state.item = Object.assign({}, payload)
  },

  'LIST_GAMES' (state, payload) {
    state.listGames = payload.resultMap
  },

  'LIMPAR_ITEM' (state) {
    state.listGames = [],
    state.listPrizes = [],
    state.listGamesWinners = [],
    state.listCaixa = []
  },

  'LIMPAR_ITEM_MODAL_JOGOS' (state) {
    state.listGamesWinners = []
  },

  'LISTA_ITENS_SELECT' (state, payload) {
    state.itemSelect = payload
  },

  'SET_HAS_MONTH_OR_DATE' (state, payload) {
    state.hasMonthOrDate = payload
  },

  'LIST_CAIXA' (state, payload) {
    state.listCaixa = payload.resultMap

    state.listCaixa.forEach(item => {
      if (item.entryType.type === 'D') {
        item.value = parseFloat(item.value) * -1
      }
    })
  }
}

const getters = {
  listaItens: state => state.itens,
  listTransmissionsNotDeleted: state => state.itens.filter(item => moment(item.deleted).format('DD/MM/YYYY') === '01/01/1970'),
  listTransmissionsDeleted: state => state.itens.filter(item => moment(item.deleted).format('DD/MM/YYYY') > '01/01/1970'),
  totalItens: (state) => state.totalItens,
  item: state => state.item,
  transmissoes: state => state.transmissoes,
  listGames: state => state.listGames,
  listPrizes: state => state.listPrizes,
  listGamesWinners: state => state.listGamesWinners,
  listCaixa: state => state.listCaixa,
  listaItensSelect: (state) => {
    return map(state.itens, item => {
      return {
        text: item.name,
        value: item.id
      }
    })
  }
}

export default { namespaced: true, state, actions, mutations, getters }