import http from '@/core/http'
import { each, map } from 'lodash'
import Vue from 'vue'

const url = 'premios'

const state = {
  itens: [],
  item: [],
  itemSelect: [],
  totalItens: 0,
}

const actions = {
  clearItens: async ({commit}) => {
    commit('CLEAR_ALLL')
    return Promise.resolve(true)
  },
  setSubTable: async ({commit}, payload) => { // para premios web
    if (payload) {
      let params = { ids: map(payload.lista, v => v.winnerId) }
      // eslint-disable-next-line no-unused-vars
      const result = await http.get(`${url}/premio-web`, { params })
      console.log(result.data.resultMap)
      let itens = []
      each(result.data.resultMap, (obj) => {
        each(obj.guessWinners, v => {
          itens.push({
            modality: obj.modality.name,
            guess: { guess: v.value },
            prizeRange: v.prizeRange,
            guessValue: v.guessValue,
            prizeValue: v.prizeValue,
            guesses: v.guesses,
            lottery: obj.sweep.cod,
            games:{ gameNumber: obj.games.gameNumber, guessWinners: [{}]},
            totais: {
              guessValue: 0
            }
          })
        })
      })
      payload.format = itens
      commit('ADD_WINNERS', payload)
    }
  },
  getItens: async ({ commit }, payload) => { // lista premios baixos
    const result = await http.get(url, { params: payload })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },

  getPremioAltos: async ({ commit }, payload) => {
    const result = await http.get(`${url}/premios-altos`, { params: payload })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },

  limparItem: ({ commit }) => {
    commit('LIMPAR_ITEM')
  },
}

const mutations = {
  'LISTA_ITENS' (state, payload) {
    state.itens = payload.resultMap
    state.totalItens = payload.totalItens
  },

  'LISTA_ITEM' (state, payload) {
    state.item = Object.assign({}, payload)
  },

  'LIMPAR_ITEM' (state) {
    state.item = {
      name: '',
      produtoId: '',
      product: {
        name: ''
      }
    }
  },

  'LISTA_ITENS_SELECT' (state, payload) {
    state.itemSelect = payload
  },
  'ADD_WINNERS' (state, payload) {
    Vue.set(state.itens[payload.key], 'new', payload.format)
  },
  'CLEAR_ALLL' (state) {
    state.itens = []
    state.item = []
    state.itemSelect = []
  }
}

const getters = {
  listaItens: state => state.itens,
  totalItens: (state) => state.totalItens,
  item: state => state.item,
  listaItensSelect: (state) => {
    return map(state.itens, item => {
      return {
        text: item.name,
        value: item.id
      }
    })
  }
}

export default { namespaced: true, state, actions, mutations, getters }