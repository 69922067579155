export default [
  {
    path: 'g-fin/bonus',
    name: 'bonus',
    component: () => import('./index'),
    meta: {
      visible: true,
      title: 'Bônus',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Financeiro', link: 'financeiro' },
        { name: 'Bônus' }
      ]
    }
  }
]