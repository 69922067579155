import http from '@/core/http'
import { map, forEach, compact, merge } from 'lodash'

const url = 'terminais/devices'

const state = {
  itens: [],
  item: {
    deviceVersion: '',
    serial: '',
    deviceTypeId: 1,
    hwSerial: ''
  },
  lista: '',
  itemAddPermission: [],
  acl: null,
  itemSelect: [],
  totalItens: 0,
  totalItemSelect: 0
}

const actions = {
  getItens: async ({ commit, rootGetters }, payload) => {

    if (!payload) payload = {}

    let _filter = { 'deleted_>=':'01/01/1971' }

    if (payload.hasOwnProperty('_filter') && rootGetters['globalStore/isStatus']) {
      payload._filter = merge(payload._filter, _filter)
    } else if (rootGetters['globalStore/isStatus']) {
      payload = Object.assign(payload, { _filter })
    }

    const result = await http.get(url, { params: Object.assign(payload, { order: { id: 'desc' }})})
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },

  getItensNotInstallations: async ({ commit }, payload) => {
    const result = await http.get(`${url}/list-available`, { params: payload })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },

  cadastrarItem: async (_, payload) => {
    const result = await http.post(url, payload)
    return Promise.resolve(result)
  },

  editarItem: async (_, payload) => {
    const result = await http.put(url, payload)
    return Promise.resolve(result)
  },

  deletarItem: async (_, payload) => {
    const result = await http.delete(url, { params: payload })
    return Promise.resolve(result)
  },

  buscarItem: async ({ commit }, payload) => {
    const result = await http.post(`${url}/View`, { params: payload })
    commit('LISTA_ITEM', result.data)
  },

  getItem: ({ commit }, payload) => {
    commit('LISTA_ITEM', payload)
  },

  limparItem: ({ commit }) => {
    commit('LIMPAR_ITEM')
  },

  obterItensSelect: async ({ commit }) => {
    const result = await http.get(`/list-ats`)
    commit('LISTA_ITENS_SELECT', result.data)
  },

  clearItens: ({ commit }) => {
    commit('CLEAR_ITENS')
    commit('LIMPAR_ITEM')
  }
}

const mutations = {
  'LISTA_ITENS' (state, payload) {
    state.itens = payload.resultMap
    state.totalItens = payload.totalItens
    state.totalItemSelect = payload.totalItens
  },

  'LISTA_ITEM' (state, payload) {
    state.item = Object.assign({}, payload)
  },

  'LIMPAR_ITEM' (state) {
    state.item = {
      deviceVersion: '',
      serial: '',
      deviceTypeId: 1,
      hwSerial: ''
    }
  },

  'LISTA_ITENS_SELECT' (state, payload) {
    state.itemSelect = payload.resultMap
  },

  'CLEAR_ITENS' (state) {
    state.itens = []
    state.totalItens = 0
  }
}

const getters = {
  listaItens: state => state.itens,
  totalItens: (state) => state.totalItens,
  item: state => state.item,
  totalItemSelect: (state) => state.totalItemSelect,
  listaItensSelect: (state) => {
    return map(state.itens, item => {
      return {
        text: item.serial,
        value: item.id
      }
    })
  },
  listaItensSelectFilter: (state, getters, rootState, rootGetters) => {
    let devicesInstalations = [] 
    forEach(rootGetters['installations/listaItens'], item => {
      devicesInstalations.push(item.device.deviceId)
    })
  
    return compact(map(state.itens, item => {
      if (!devicesInstalations.includes(item.id)) {
        return {
          text: item.serial,
          value: item.id
        }
      }
    }))
  }
}

export default { namespaced: true, state, actions, mutations, getters }