import http from '@/core/http'
import { map } from 'lodash'

const url = 'geolocalizacao'

const state = {
  itens: [],
  item: {
    entityId: '',
    codBilhete: '',
    nrJogo: ''
  },
  lista: '',
  itemAddPermission: [],
  acl: null,
  itemSelect: [],
  totalItens: 0
}

const actions = {
  getGeolocations: async ({ commit }, payload) => {
    const result = await http.get(url, { params: payload })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },

  getSupervisor: async () => {
    const result = await http.get(`${url}/listar-supervisores`, { headers: { moduloname: 'gestao' }, params: { order: { 'cod::int': 'ASC' } }})
    const data = map(result.data.resultMap, item => {
      return {
        text: `${item.cod} - ${item.name}`,
        value: item
      }
    })
    return Promise.resolve(data)
  },

  getPromotoresLastPosition: async (_,payload) => {
    const result = await http.get(`${url}/listar-promotor`, { params: payload })
    return Promise.resolve(result)
  },

  getRastroPromotores: async (_, payload) => {
    const result = await http.get(`${url}/promotor-caminho`, { params: payload })
    return Promise.resolve(result)
  },

  salvarPosicaoRevenda: async (_,payload) => {
    const result = await http.put(`${url}`,{id: payload.id, lat: payload.position.lat(), lng: payload.position.lng()})
    return Promise.resolve(result)
  },

  getItem: ({ commit }, payload) => {
    commit('LISTA_ITEM', payload)
  },

  limparItem: ({ commit }) => {
    commit('LIMPAR_ITEM')
  }
}

const mutations = {
  'LISTA_ITENS' (state, payload) {
    state.itens = payload.resultMap
    state.totalItens = payload.totalItens
  },

  'LISTA_ITEM' (state, payload) {
    state.item = Object.assign({}, payload)
  },

  'LIMPAR_ITEM' (state) {
    state.item = {
      name: '',
      produtoId: '',
      product: {
        name: ''
      }
    }
  },

  'LISTA_ITENS_SELECT' (state, payload) {
    state.itemSelect = payload
  }
}

const getters = {
  listaItens: state => state.itens,
  totalItens: (state) => state.totalItens,
  item: state => state.item,
  listaItensSelect: (state) => {
    return map(state.itens, item => {
      return {
        text: item.name,
        value: item.id
      }
    })
  }
}

export default { namespaced: true, state, actions, mutations, getters }