export default [
  {
    path: 'gestao/autenticacao/usuarios',
    name: 'usuarios',
    component: () => import('./index'),
    meta: {
      visible: true,
      title: 'Cadastro Usuario',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Gestão', link: 'gestao' },
        { name: 'Autenticação', link: 'autenticacao' },
        { name: 'Usuários' }
      ]
    }
  }
]