export default [
  {
    path: 'gestao/produtos/products',
    name: 'products',
    component: () => import('./index'),
    meta: {
      visible: true,
      title: 'Produtos',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Gestão', link: 'gestao' },
        { name: 'Produtos', link: 'produtos' },
        { name: 'Produtos' }
      ]
    }
  }
]