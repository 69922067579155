export default [
  {
    path: 'gestao/autenticacao/roles',
    name: 'roles',
    component: () => import('./index'),
    meta: {
      visible: true,
      title: 'Roles',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Gestão', link: 'gestao' },
        { name: 'Autenticação', link: 'autenticacao' },
        { name: 'Roles' }
      ]
    }
  }
]