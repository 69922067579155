import http from '@/core/http'
import { size } from 'lodash'

const url = 'entidades/pendentes'

const state = {
  itens: [],
  item: {
    parentId: '',
    cod: '',
    name: '',
    email: '',
    cnpj: '',
    cpf: '',
    rg: '',
    birth: '',
    gender: '',
    maritalStatus: '',
    nameSpouse: '',
    birthSpouse: '',
    phone: '',
    familyIncome: '',
    pix: {
      bank: '',
      typeKey: 'celular',
      keyCode: {
        key: '',
        keyEmail: ''
      }
    },
    adresses: {
      item1: {
        typeAddress: 'commercial',
        typeSituation: '',
        cep: '',
        address: '',
        neighborhood: '',
        number: '',
        state: '',
        city: '',
        cepValidate: false,
        complement: ''
      }
    },
    accounts: {
      item1: {
        typeAccount: '',
        bank: '',
        agency: '',
        account: ''
      }
    },
    children:  {},
    jsonFlags: {
      payerOfPrizesByPix: false
    }
  },
  totalItens: 0
}

const actions = {
  getItens: async ({ commit }, payload) => {
    const result = await http.get(url, { params: payload })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },
  getItem: ({ commit }, payload) => {
    commit('LISTA_ITEM', payload)
  },
  limparItem: ({ commit }) => {
    commit('LIMPAR_ITEM')
  },
  deletarItem: async (_, payload) => {
    const result = await http.delete(url, { params: payload })
    return Promise.resolve(result)
  },
  aprovarItem: async (_, payload) => {
    const result = await http.post(`${url}/aprovar`, payload)
    return Promise.resolve(result)
  }
}

const mutations = {
  'LISTA_ITENS' (state, payload) {
    state.itens = payload.resultMap
    state.totalItens = payload.totalItens
  },
  'LISTA_ITEM' (state, payload) {
    const item = Object.assign({}, payload)
    if (item.pix) {
      const keycode = item.pix.keyCode.key
      item.pix.keyCode.keyEmail = item.pix.keyCode.type === 'e-mail' ? keycode : ''
      item.pix.keyCode.key = item.pix.keyCode.type !== 'e-mail' ? keycode : ''
    } else {
      item.pix = {
        typeKey: 'celular',
        bank: {
          code: '',
          name: ''
        },
        keyCode:  {
          key: '',
          keyEmail: ''
        }
      }
    }

    
    const addresses = {}
    item.adresses && item.adresses.forEach((it, idx) => {
      addresses[`item${idx+1}`] = {
        typeAddress: it.typeAddress,
        typeSituation: it.typeSituation,
        cep: it.cep,
        address: it.address,
        neighborhood: it.neighborhood,
        number: it.number,
        state: it.state,
        city: it.city,
        cepValidate: false,
        complement: it.complement
      }
    })

    item.adresses = size(addresses) ? addresses : {
      item1: {
        typeAddress: '',
        typeSituation: '',
        cep: item.cep,
        address: item.address,
        neighborhood: item.neighborhood,
        number: item.number,
        state: item.state,
        city: item.city,
        cepValidate: false
      }
    }

    const accounts = {}
    item.accounts && item.accounts.forEach((it, idx) => {
      accounts[`item${idx+1}`] = {
        typeAccount: it.typeAccount,
        bank: it.bank,
        agency: it.agency,
        account: it.account
      }
    })

    item.accounts = size(accounts) ? accounts : {
      item1: {
        typeAccount: '',
        bank: '',
        agency: '',
        account: ''
      }
    }


    const children = {}
    item.children && item.children.forEach((it, idx) => {
      children[`item${idx+1}`] = {
        name: it.name,
        birth: it.birth
      }
    })

    item.children = children
    state.qtdItemFormChildren = size(children)

    item.fotosDeletadas = []
    item.phone = item.phone ? item.phone.substr(2) : ''
    state.item = item
  },
  'LIMPAR_ITEM' (state){
    state.item = {
      parentId: '',
      cod: '',
      name: '',
      email: '',
      document: '',
      cnpj: '',
      cpf: '',
      rg: '',
      birth: '',
      gender: '',
      maritalStatus: '',
      nameSpouse: '',
      birthSpouse: '',
      phone: '',
      familyIncome: '',
      pix: {
        bank: '',
        typeKey: 'e-mail',
        keyCode: {
          key: '',
          keyEmail: ''
        }
      },
      adresses: {
        item1: {
          typeAddress: 'commercial',
          typeSituation: '',
          cep: '',
          address: '',
          neighborhood: '',
          number: '',
          state: '',
          city: '',
          cepValidate: false,
          complement: ''
        }
      },
      accounts: {
        item1: {
          typeAccount: '',
          bank: '',
          agency: '',
          account: ''
        }
      },
      children:  {},
      photos: {
        item1: {
          description: '',
          image: '',
          nameImage: '',
          type: ''
        }
      },
      jsonFlags: {
        payerOfPrizesByPix: false
      }
    }
  }
}

const getters = {
  listaItens: state => state.itens,
  item: state => state.item,
  totalItens: state => state.totalItens,
}

export default { namespaced: true, state, actions, mutations, getters }