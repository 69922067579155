export default [
  {
    path: 'gestao/autenticacao/url',
    name: 'url',
    component: () => import('./index'),
    meta: {
      visible: true,
      title: 'url',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Gestão', link: 'gestao' },
        { name: 'Autenticação', link: 'autenticacao' },
        { name: 'Recursos' }
      ]
    }
  }
]