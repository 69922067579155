import http from '@/core/http'

const endpoint = 'lancamento-caixa'

const state = {
  itens: [],
  totalBonus: 0,
  totalGeral: 0
}

const actions = {
  getItens: async ({ commit }, payload) => {
    const result = await http.get(endpoint, { params: payload })
    commit('SET_RESULT', result.data)
    return Promise.resolve(result)
  },

  getItem: ({ commit }, payload) => {
    commit('LISTA_ITEM', payload)
  },

  limparItem: ({ commit }) => {
    commit('LIMPAR_ITEM')
  },
  clearItens: ({ commit }) => {
    commit('LIMPAR_ITEM')
  }
}

const mutations = {
  'SET_RESULT' (state, payload) {
    const data = payload.resultMap
    state.itens = data.resultGroup.length ? data.resultGroup : []

    if (data.totaisGerais) {
      state.totalBonus = data.totaisGerais.bonus
      state.totalGeral = data.totaisGerais.geral
    }
  },
  'LIMPAR_ITEM' (state) {
    state.itens = []
  }
}

const getters = {
  totalGeral:  (state) => ({ geral: state.totalGeral, bonus: state.totalBonus }),
  listaItens:  (state) => state.itens,
  totalItens: (state) => state.totalItens,
  transmissoes: state => state.transmissoes,
}

export default { namespaced: true, state, actions, mutations, getters }