import http from '@/core/http'
import { filter, map, groupBy } from 'lodash'

const state = {
  categories: [],
  fatherCategories: [],
  subCategories: [],
  categoryGroupsMapped: []
}

const actions = {
  getCategories: async ({ commit }) => {
    const result = await http.get('gefin-mais/categorias')
    result.data.data[0] = result.data.data[0].filter(item => !item.jsonFlags || !item.jsonFlags.InportOnly || !item.categoryId)
    commit('GET_CATEGORY_LIST', result.data.data[0])
    return Promise.resolve(result.data.data[0])
  },

  submit: async (_, payload) => {
    let result = {}

    if (!payload.isEdit) {
      delete payload.isEdit
      result = await http.post('gefin-mais/categorias', payload)
    } else {
      delete payload.isEdit
      result = await http.put('gefin-mais/categorias', payload)
    }

    return Promise.resolve(result.data)
  },

  deleteCategory: async (_, payload) => {
    const result = await http.delete('gefin-mais/categorias', { params: payload })
    return Promise.resolve(result.data.data)
  }
}

const mutations = { 'GET_CATEGORY_LIST' (state, payload) {
  state.categories = payload

  state.fatherCategories = filter(state.categories, item => item.categoryId === null)

  state.subCategories = filter(state.categories, item => item.categoryId !== null)

  state.categoryGroupsMapped = (() => {
    const categoriesMapped = map(state.fatherCategories, category => {
      return {
        ...category,
        subCategories: filter(state.subCategories, subCategory => subCategory.category.id === category.id)
      }
    })

    return groupBy(categoriesMapped, item => item.categoryGroup)
  })()
}}

const getters = {
  categories: state => state.categories,
  fatherCategories: state => state.fatherCategories,
  subCategories: state => state.subCategories,
  categoryGroupsMapped: state => state.categoryGroupsMapped
}

export default { namespaced: true, state, actions, mutations, getters }