export default [
  {
    path: '/permissoes',
    redirect: 'roles',
  },
  {
    path: 'gestao/permissoes/:id',
    name: 'permissoes',
    component: () => import('./index'),
    meta: {
      visible: false,
      title: 'Permissões',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Gestão', link: 'gestao' },
        { name: 'Autenticação', link: 'autenticacao' },
        { name: 'Permissões' }
      ]
    }
  }
]