import organizzeFinanceiroContas from './organizze/mais/contas/store'
import tagsView from './components/tagsView/store'
import dateServer from './components/date/store'
import globalStore from './components/global/store'
import historicoRequisicaoPremioWeb from './consultas/historicoRequisicaoPremioWeb/store'
import auth from './auth/store'
import organizzeContatos from './organizze/contatos/store'
import home from './home/store'
import organizzeFinanceiroCategorias from './organizze/mais/categorias/store'
import organizzeCentroCustos from './organizze/mais/centroCustos/store'
import organizzeFinanceiroLancamentos from './organizze/financeiro/lancamentos/store'
import premioPagoCentral from './relatorios/premioPagoCentral/store'
import premioPagoBaixo from './relatorios/premioPagoBaixo/store'
import users from './cadastros/autenticacao/users/store'
import roles from './cadastros/autenticacao/roles/store'
import grupos from './cadastros/autenticacao/grupos/store'
import permissoes from './cadastros/autenticacao/permissoes/store'
import endpoints from './cadastros/autenticacao/endpoints/store'
import preCaixa from './cadastros/preCaixa/store'
import geolocations from './cadastros/geolocalizacao/store'
import organizzeRelatoriosFluxoCaixa from './organizze/relatorios/fluxoCaixa/store'
import organizzeRelatoriosContas from './organizze/relatorios/contas/store'
import organizzeRelatoriosVendasProdutos from './organizze/relatorios/vendasProdutoModalidade/store'

// Stores de cadastros de entidades
import entities from './cadastros/entities/store'
import pendingEntities from './cadastros/entities/pendentes/store'
import ats from './cadastros/entities/ats/store'
import osc from './cadastros/entities/osc/store'
import comissionOscPrizes from './cadastros/entities/osc/components/comission/components/comissionOscPrizes/store'
import comissionWeb from './cadastros/entities/osc/components/comission/components/comissionWeb/store'
import comissionOverall from './cadastros/entities/osc/components/comission/components/comissionOverall/store'
import estabelecimento from './cadastros/entities/estabelecimento/store'
import estabelecimentoComissao from './cadastros/entities/estabelecimento/components/comissaoStore'
import credenciador from './cadastros/entities/credenciador/store'
import credenciadorComission from './cadastros/entities/credenciador/components/comission/store'
import caixa from './cadastros/entities/caixa/store'
import influenciador from './cadastros/entities/influenciador/store'
import influenciadorComission from './cadastros/entities/influenciador/components/contract/store'
import usuariosWeb from './cadastros/entities/usuariosWeb/store'

// Cadastro de Indice e metas
import listarMetas from './cadastros/metas/listarMetas/store'
import metasEntidades from './cadastros/metas/metasEntidades/store'
import tiposIndices from './cadastros/tiposIndices/store'

// Stores de cadastros de instalacoes
import chips from './cadastros/terminais/chips/store'
import devices from './cadastros/terminais/devices/store'
import installations from './cadastros/terminais/installations/store'
import deviceTypes from './cadastros/terminais/deviceTypes/store'
import msgRodapeTerminal from './cadastros/terminais/msgRodapeTerminal/store'

import descargaOnline from './cadastros/descargaOnline/store'

// Stores de cadastros de produtos
import products from './cadastros/produtos/products/store'
import modalities from './cadastros/produtos/modalities/store'
import prizeTypes from './cadastros/produtos/prizeTypes/store'

// Stores de Loterias
import sweepstakes from './cadastros/loterias/sweepstakes/store'
import prizes from './cadastros/loterias/sweepstakes/components/prizes/store'
import sweepstakesRecurrent from './cadastros/loterias/sweepstakesRecurrent/store'
import sweepstakesRecurrentPrizesConfig from './cadastros/loterias/sweepstakesRecurrent/components/prizes/store'
import sweepstakesRecurrentHoliday from './cadastros/loterias/sweepstakesRecurrent/components/holidays/store'
import results from './cadastros/loterias/results/store'
import feriados from './cadastros/feriados/store'
import recorrentes from './cadastros/loterias/recorrentes/store'
import cotacaoDinamica from './cadastros/loterias/cotadaDinamica/store'


// envio de sms
import SendSms from './cadastros/sendSms/store'
import notificacao from './cadastros/notificacao/store'

// Stores de cadastros de bonificações
import listBonifications from './cadastros/listaBonificacoes/store'
import bonusCupons from './cadastros/entities/cuponsBonus/store'
/**
 * Store relacionados a financeiros
 */
import entryTypes from './financeiros/entryTypes/store'
import entries from './financeiros/entries/store'
import lancamentoFinanceiro from './financeiros/lancamentoFinanceiro/store'
import tiposOperacoes from './financeiros/tiposOperacoes/store'
import cobranca from './financeiros/cobranca/store'
import despesas from './financeiros/despesas/store'
import DRE from './financeiros/dre/store'
import pagamentoTitulos from './financeiros/pagamentoTitulos/store'
import financeiroBonus from './financeiros/Bonus/store'
import despacho from './financeiros/despacho/store'

/**
 * Store relacionados a consultas
 */
import resgate from './consultas/resgate/store'
import cadastroCestas from './consultas/cadastroCestas/store'
import organizze from './consultas/organizze/store'
import doacoes from './consultas/doacoes/store'
import vendasCreditos from './consultas/vendasCreditos/store'
import parceiros from './consultas/parceiros/store'
import acessos from './consultas/acessos/store'
import cupons from './consultas/cupons/store'
import resgateTitulos from './consultas/resgateTitulos/store'
import instalacoes from './consultas/instalacoes/store'
import jogos from './cadastros/jogos/store'
import senhaPreCaixa from './consultas/senhaPreCaixa/store'
import ganhadores from './consultas/ganhadores/store'
import premios from './consultas/premios/store'

/**
 * Store relacionados a relatorios
 */
import relatorioComissoes from './relatorios/comissoes/store'
import relatorioComissoesAdmin from './relatorios/comissoesAdmin/store'
import relatorioInfluenciadores from './relatorios/influenciadores/store'
// import relatorioGanhadores from './relatorios/ganhadores/store'
import relatorioGanhadoresAdmin from './relatorios/ganhadoresAdmin/store'
import relatorioArrecadacao from './relatorios/arrecadacao/store'
import relatorioTipoArrecadacao from './relatorios/tipoArrecadacao/store'
import relatorioGanhadoresAdminPrint from './relatorios/ganhadoresAdminPrint/store'
import relatorioComissoesOsc from './relatorios/comissoesOsc/store'
import relatorioDreRede from './relatorios/dreRede/store'
import relatorioInstalacoes from './relatorios/instalacoes/store'
import relatorioMoreira from './relatorios/dashboard/store'
import relatorioResultados from './relatorios/resultadosLoterias/store'
import relatorioResultadosBingo from './relatorios/resultadosBingo/store'
import relatoriosGecom from './relatorios/gecom/store'
import relatoriosGecomResumido from './relatorios/gecomResumido/store'
import relatorioGecomHome from './relatorios/gecomMobile/store'
import relatorioGecomMobile from './relatorios/gecomMobile/pages/gecom/store'
import relatorioGefinMobile from './relatorios/gecomMobile/pages/gefin/store'
import relatorioGefinDadosRevenda from './relatorios/gecomMobile/pages/dadosRevenda/store'
import relatorioDadosRevendaGecom from './relatorios/gecomMobile/pages/dadosRevendaGecom/store'
import relatorioesGecomPromotores from './relatorios/gecomPromotores/store'
import zeraSaldo from './financeiros/zeraSaldo/store'
import pagamentos from './relatorios/pagamentos/store'
import relatoriosAniversariantes from './relatorios/aniversariantes/store'
import relatoriosLancamentoCaixa from './relatorios/lancamentoCaixa/store'
import fechamentoCaixa from './relatorios/fechamentoCaixa/store'
import historicoVendas from './relatorios/historicoVendas/store'
import comissoesPromotores from './relatorios/comissoesPromotores/store'
import organizzeDespesaReceita from './organizze/relatorios/despesaReceita/store'
import RelatorioSportBet from './relatorios/biSportBet/store'

/**
 * Store relacionados a organizze
 */
 import relatorioCategorias from './organizze/relatorios/categorias/store'
 import relatorioDashboard from './organizze/dashboard/store'

import relatorioBi from './relatorios/bi/store'
import relatorioBiPainel from './relatorios/biPainel/store'

import termoConsentimento from './termoConsentimento/store'

import filter from './components/filter/store'
import pagination from './components/pagination/store'


/**
 * Store relacionados a esportes
 */
import bilhetes from './esportes/bilhetes/store'


/**
 * Store relacionados a backoffice
 */
 import crm from './backoffice/crm/store'

export default {
  relatorioBi,
  relatorioBiPainel,
  tagsView,
  auth,
  home,
  users,
  roles,
  grupos,
  endpoints,
  permissoes,
  ats,
  osc,
  comissionWeb,
  comissionOscPrizes,
  comissionOverall,
  estabelecimento,
  credenciador,
  credenciadorComission,
  influenciador,
  influenciadorComission,
  caixa,
  chips,
  devices,
  entities,
  pendingEntities,
  installations,
  products,
  modalities,
  sweepstakes,
  sweepstakesRecurrent,
  sweepstakesRecurrentPrizesConfig,
  deviceTypes,
  prizes,
  entryTypes,
  results,
  entries,
  usuariosWeb,
  resgate,
  lancamentoFinanceiro,
  tiposOperacoes,
  prizeTypes,
  cadastroCestas,
  parceiros,
  acessos,
  termoConsentimento,
  DRE,
  relatorioDreRede,
  relatorioComissoes,
  relatorioComissoesAdmin,
  relatorioInfluenciadores,
  organizzeRelatoriosFluxoCaixa,
  organizzeRelatoriosContas,
  organizzeRelatoriosVendasProdutos,
  relatorioGanhadoresAdmin,
  relatorioArrecadacao,
  relatorioTipoArrecadacao,
  relatorioGanhadoresAdminPrint,
  organizze,
  cobranca,
  doacoes,
  vendasCreditos,
  filter,
  despesas,
  pagination,
  SendSms,
  cupons,
  resgateTitulos,
  estabelecimentoComissao,
  pagamentoTitulos,
  notificacao,
  relatorioComissoesOsc,
  instalacoes,
  relatorioInstalacoes,
  listBonifications,
  bonusCupons,
  jogos,
  historicoVendas,
  dateServer,
  relatorioMoreira,
  globalStore,
  relatorioResultados,
  preCaixa,
  geolocations,
  senhaPreCaixa,
  feriados,
  sweepstakesRecurrentHoliday,
  ganhadores,
  relatoriosGecom,
  zeraSaldo,
  recorrentes,
  descargaOnline,
  financeiroBonus,
  relatoriosAniversariantes,
  pagamentos,
  fechamentoCaixa,
  metasEntidades,
  listarMetas,
  tiposIndices,
  premios,
  msgRodapeTerminal,
  relatoriosLancamentoCaixa,
  comissoesPromotores,
  historicoRequisicaoPremioWeb,
  relatoriosGecomResumido,
  cotacaoDinamica,
  organizzeFinanceiroContas,
  organizzeContatos,
  organizzeFinanceiroCategorias,
  organizzeCentroCustos,
  organizzeFinanceiroLancamentos,
  organizzeDespesaReceita,
  relatorioResultadosBingo,
  premioPagoCentral,
  relatorioCategorias,
  relatorioDashboard,
  relatorioGecomHome,
  relatorioGecomMobile,
  relatorioGefinMobile,
  relatorioGefinDadosRevenda,
  relatorioDadosRevendaGecom,
  premioPagoBaixo,
  relatorioesGecomPromotores,
  despacho,
  RelatorioSportBet,
  bilhetes,
  crm,
}