import http from '@/core/http'
import { filter, size } from 'lodash'
import config from '@/core/config'
import moment from 'moment'
moment.locale('pt-br')

const state = {
  accountList: [],
  recordList: [],
  recordPendentes: [],
  tagList: [],
  costCenterList: [],
  expenditureCategoryList: [],
  incomeCategoryList: [],
  otherCategoryList: [],
  balanceList: [],
  contactList: [],
  customerContactList: [],
  getTagsRecords: []
}

const actions = {
  getTags: async ({ commit }) => {
    const result = await http.get('gefin-tags')
    commit('GET_TAG_LIST', result.data.data[0])
    return Promise.resolve(result.data.data[0])
  },
  getTagsRecors: async ({ commit }) => {
    const result = await http.get('gefin-tags-records')
    commit('GET_TAG_RECORDS_LIST', result.data.data[0])
    return Promise.resolve(result.data.data[0])
  },

  getAccounts: async ({ commit }) => {
    const result = await http.get('gefin-mais/contas/accounts')
    commit('GET_ACCOUNT_LIST', result.data.data[0])
    return Promise.resolve(result.data.data[0])
  },

  getCategories: async ({ commit }) => {
    const result = await http.get('gefin-mais/categorias')
    result.data.data[0] = result.data.data[0].filter(item => !item.jsonFlags || !item.jsonFlags.InportOnly || !item.categoryId)
    commit('GET_EXPENDITURE_CATEGORY_LIST', result.data.data[0])
    commit('GET_INCOME_CATEGORY_LIST', result.data.data[0])
    commit('GET_OTHER_CATEGORY_LIST', result.data.data[0])
    return Promise.resolve(result.data.data[0])
  },

  getRecords: async ({ commit }, payload) => {
    const result = await http.get('gefin-financeiro/lancamentos', { params: payload })
    commit('GET_RECORD_LIST', result.data.data[0])
    return Promise.resolve(result.data.data[0])
  },

  getRecordId: async (_, payload) => {
    const result = await http.get('gefin-financeiro/lancamentos', { params: payload })
    return Promise.resolve(result.data.data[0])
  },
  getRecordsPendentes: async ({ commit }, payload) => {
    const result = await http.get('gefin-financeiro/lancamentos', { params: payload })
    commit('GET_RECORD_PENDENTES', result.data.data[0])
    return Promise.resolve(result.data.data[0])
  },

  getCostCenters: async ({ commit }) => {
    const result = await http.get('gefin-mais/gefin-cost-center')
    commit('GET_COST_CENTERS', result.data.data[0])
    return Promise.resolve(result.data.data[0])
  },

  submit: async (_, payload) => {
    let result = {}
    if (!payload.isTransfer) {
      if (!payload.isEdit) result = await http.post('gefin-financeiro/lancamentos', {...payload.record, isRepeat: payload.isRepeat, isCopy: payload.isCopy, typeUpdated: payload.typeUpdated })
      else result = await http.put('gefin-financeiro/lancamentos', {...payload.record, isRepeat: payload.isRepeat, isCopy: payload.isCopy, typeUpdated: payload.typeUpdated })
    } else {
      if (!payload.isEdit) result = await http.post('gefin-financeiro/lancamentos-transferencia', { ...payload.record,  typeUpdated: payload.typeUpdated })
      else result = await http.put('gefin-financeiro/lancamentos-transferencia', { ...payload.record,  typeUpdated: payload.typeUpdated })
    }

    return Promise.resolve(result.data.data)
  },

  deleteRecord: async (_, payload) => {
    const result = await http.delete('gefin-financeiro/lancamentos', { params: payload })
    return Promise.resolve(result.data.data)
  },

  getBalance: async ({ commit }, payload) => {
    const result = await http.get('gefin-financeiro/saldos', { params: payload })
    commit('GET_BALANCE_LIST', result.data.data)
    return Promise.resolve(result.data.data)
  },

  getContacts: async ({ commit }) => {
    const result = await http.get('gefin-contatos/')
    commit('GET_CONTACT_LIST', result.data.data[0])
    commit('GET_CUSTOMER_CONTACT_LIST', result.data.data[0])
    return Promise.resolve(result.data.data[0])
  },

  downloadImage: async (_, payload) => {
    if (!payload.name_s3) return false

    const response = await http.get(`${config.urlAWS}/${payload.name_s3}`, { responseType: "blob" });
    const blob = new Blob([response.data], { type: "application/png" })
    const link = document.createElement("a")
    link.href = URL.createObjectURL(blob)
    link.download = payload.name_s3
    link.click()
    URL.revokeObjectURL(link.href)
  }
}

const mutations = {
  'GET_TAG_RECORDS_LIST' (state, payload) { state.getTagsRecords = payload },
  'GET_ACCOUNT_LIST' (state, payload) { state.accountList = payload },
  'GET_RECORD_LIST' (state, payload) { state.recordList = payload },
  'GET_RECORD_PENDENTES' (state, payload) { state.recordPendentes = payload },
  'GET_TAG_LIST' (state, payload) { state.tagList = payload },
  'GET_COST_CENTERS' (state, payload) { state.costCenterList = payload },
  'GET_EXPENDITURE_CATEGORY_LIST' (state, payload) { state.expenditureCategoryList = filter(payload, category => category.categoryGroup === 'D' || category.categoryGroup === 'O') },
  'GET_INCOME_CATEGORY_LIST' (state, payload) { state.incomeCategoryList = filter(payload, category => category.categoryGroup === 'R' || category.categoryGroup === 'O') },
  'GET_OTHER_CATEGORY_LIST' (state, payload) { state.otherCategoryList = filter(payload, category => category.categoryGroup === 'O') },
  'GET_BALANCE_LIST' (state, payload) { state.balanceList = payload },
  'GET_CONTACT_LIST' (state, payload) { state.contactList = filter(payload, item => item.contactType.id === 5) },
  'GET_CUSTOMER_CONTACT_LIST' (state, payload) { state.customerContactList = filter(payload, item => item.contactType.id === 4) }
}

const getters = {
  accountList: state => state.accountList,
  tagList: state => state.tagList,
  listTagsRecords: state => state.getTagsRecords,
  costCenterList: state => state.costCenterList,
  expenditureCategoryList: state => state.expenditureCategoryList,
  incomeCategoryList: state => state.incomeCategoryList,
  otherCategoryList: state => state.otherCategoryList,
  balanceList: state => state.balanceList,
  contactList: state => state.contactList,
  customerContactList: state => state.customerContactList,
  recordList: state => state.recordList,
  recordListPendentes: state => state.recordPendentes,
  recorPaymentD: (state) => { // lancamentos a pagar tipo despesa
    const data = state.recordList
    if (size(data) === 0) return {atrasadas: [], apagar: []}
    let atual = moment()
    const apagar = filter(data, v => atual.isBefore(moment(v.dtDue, 'YYYY-MM-DD')))
    const atrasadas = filter(data, v => atual.isAfter(moment(v.dtDue, 'YYYY-MM-DD')))
    return {apagar, atrasadas}
  }
}

export default { namespaced: true, state, actions, mutations, getters }