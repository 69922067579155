// eslint-disable-next-line no-unused-vars
import http from '@/core/http'
import { orderBy } from 'lodash'

// eslint-disable-next-line no-unused-vars
const url = 'pre-caixa'

const state = {
  id: null,
  items: [],
  itemsTransactions: [],
  images: [],
  transacaoStatus: [],
  totalItems: 0
}

const actions = {
  getItems: async ({ commit }, payload) => {
    const result = await http.get('despacho', { params: payload })
    commit('ADD_ITEMS', result.data)
    // if (!result.data.affected) throw new Error(result)
    return Promise.resolve(result.data)
  },

  getTransaction: async ({ commit }, dispatchId) => {
    const result = await http.get('despacho/transacao', { params: { dispatchId } })
    commit('ADD_ITEMS_TRANSACTION', result.data.data)
    // if (!result.data.affected) throw new Error(result)
    return Promise.resolve(result.data)
  },

  getTransactionStatuses: async ({ commit }) => {
    const result = await http.get('despacho/transacao-status')
    commit('ADD_ITEMS_TRANSACTION_STATUSES', result.data.data)
    // if (!result.data.affected) throw new Error(result)
    return Promise.resolve(result.data)
  },

  confirmationPayment: async (_, transaction) => {
    const result = await http.put('despacho/transacao', transaction)
    // commit('ADD_ITEMS_TRANSACTION', result.data.data)
    // if (!result.data.affected) throw new Error(result)
    return Promise.resolve(result.data)
  },

  cancelPayment: async (_, transaction) => {
    const result = await http.put('despacho/transacao/cancel', transaction)
    // commit('ADD_ITEMS_TRANSACTION', result.data.data)
    // if (!result.data.affected) throw new Error(result)
    return Promise.resolve(result.data)
  },

  acceptTransaction: async (_, transaction) => {
    const result = await http.put('despacho/transacao/aceitar', transaction)
    // commit('ADD_ITEMS_TRANSACTION', result.data.data)
    // if (!result.data.affected) throw new Error(result)
    return Promise.resolve(result.data)
  },

  saleCredits: async (_, transaction) => {
    const result = await http.put('despacho/transacao/baixar-creditos', transaction)
    // commit('ADD_ITEMS_TRANSACTION', result.data.data)
    // if (!result.data.affected) throw new Error(result)
    return Promise.resolve(result.data)
  },

  getReceiptsEntity: async ({ commit }, transaction) => {
    const result = await http.get('despacho/transacao/images', { params: { ...transaction }})
    // commit('ADD_ITEMS_TRANSACTION', result.data.data)
    commit('ADD_ITEMS_IMAGES', result.data.data)
    // if (!result.data.affected) throw new Error(result)
    return Promise.resolve(result.data)
  },

  setImage: async ({ commit }, image) => {
    commit('SET_IMAGE', image)
  },

  selecionarOutraRevenda: async (_, transaction) => {
    const result = await http.put('despacho/transacao/mudar-revenda', transaction)
    return Promise.resolve(result.data)
    // commit('SET_IMAGE', image)
  },

  clearImages: async ({ commit }) => {
    commit('CLEAR_IMAGES')
  },

  clearItens: async ({ commit }) => {
    commit('CLEAR_ITENS')
  },

}

const mutations = {
  'ADD_ITEMS' (state, payload) {
    payload.data.forEach(it => {
      const ultimoTransacao = orderBy(it.transactions, 'updated')[it.transactions.length - 1]
      if (!ultimoTransacao) return
      switch (ultimoTransacao.transactionStatusId) {
        case 'PR':
          it.order = 1
          break
        case 'PG':
          it.order = 2
          break
        default:
          it.order = 99
      }
    })
    state.items = payload.data
    state.totalItems = payload.total
  },
  
  'ADD_ITEMS_TRANSACTION' (state, transactions) {
    state.itemsTransactions = transactions
  },

  'ADD_ITEMS_IMAGES' (state, images) {
    state.images = images
  },

  'SET_IMAGE' (state, images) {
    state.images = images
  },

  'ADD_ITEMS_TRANSACTION_STATUSES' (state, transacaoStatus) {
    state.transacaoStatus = transacaoStatus
  },

  'CLEAR_IMAGES' (state) {
    state.images = []
  },

  'CLEAR_ITENS' (state) {
    state.items = []
  },
 
}

const getters = {
  items: state => state.items, 
  totalItems: state => state.totalItems,
  itemsTransactions: state => state.itemsTransactions,
  images: state => state.images,
  transacaoStatus: state => {
    const status = state.transacaoStatus.map(it => ({
      value: it.id,
      text: it.name
    }))

    // status.unshift({
    //   value: '',
    //   text: 'Todos'
    // })

    return status
  },
}

export default { namespaced: true, state, actions, mutations, getters }