export default [
  {
    path: 'gestao/terminais',
    name: 'terminais',
    component: () => import('./index'),
    meta: {
      visible: false,
      title: 'Terminais',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Gestão', link: 'gestao' },
        { name: 'Terminais' }
      ]
    }
  }
]