// eslint-disable-next-line no-unused-vars
import http from '@/core/http'

const url = 'sms'

const state = {
  id: null,
  name: null,
  itens: [],
  totalItens: 0,
  filtro: null,
  numeros: []
}

const actions = {
  addDadosTable: ({commit}, payload) => {
    commit('ADD_DADOS_TABLE', [])
    commit('ADD_DADOS_TABLE', payload)
  },
  addFiltroAtual: ({commit}, payload) => {
    commit('ADD_FILTRO_ENTITI_ID', payload || 6)
  },
  sendSms:  async (_, payload) => {
    const result = await http.post(url, { params: payload })
    // commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },
  getItens: async (_, payload) => {
    payload = { data: { totalItens: 1 }}
    return Promise.resolve(payload)
  },
  obterItens: async ({ commit, state }, payload) => {
    const headers = {
      moduloname: 'gestao',
    }
    if (state.filtro) {
      payload.entityTypeId_In = state.filtro
    }
    const result = await http.get('entidades', { params: payload, headers })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result.data)
  },
  clearItens: ({ commit }) => {
    commit('CLEAR_ITENS')
    commit('LIMPAR_ITEM')
  }
}

const mutations = {
  'ADD_DADOS_TABLE' (state, payload) {
    state.numeros = payload
  },
  'LISTA_ITENS' (state, payload) {
    state.itens = payload.resultMap
    state.totalItens = payload.totalItens
  },
  'ADD_FILTRO_ENTITI_ID' (state, payload) {
    state.filtro = payload
  },
  'CLEAR_ITENS' (state, payload = []) {
    state.numeros = payload
  }
}

const getters = {
  listaItens: state => state.itens,
  totalItemSelect: (state) => state.totalItens
}

export default { namespaced: true, state, actions, mutations, getters }