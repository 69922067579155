import http from '@/core/http'
import { map } from 'lodash'

const url = 'entidades/supervisor'

const state = {
  itens: [],
  item:  {
    id: '',
    cod: '',
    region: 'GYN',
    name: '',
    email: '',
    cnpj: '',
    address: '',
    cep: '',
    phone: '',
    city: '',
    state: '',
    neighborhood: '',
    number: '',
    complement: '',
    responsible: {
      name: '',
      cpf: '',
      rg: '',
      issuingBody: '',
      email: '',
      phone: '',
      phoneComercial: '',
      cep: '',
      address: '',
      state: '',
      city: '',
      neighborhood: '',
      number: '',
      complement: '',
    }
  },
  lista: '',
  itemAddPermission: [],
  itemSelect: [],
  totalItens: 0,
  totalItemSelect: 0
}

const actions = {
  getItens: async ({ commit }, payload) => {
    const result = await http.get(url, { params: payload })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },

  cadastrarItem: async (_, payload) => {
    const result = await http.post(url, payload)
    // if (!result.data.success) throw result
    return Promise.resolve(result)
  },

  editarItem: async (_, payload) => {
    const result = await http.put(url, payload)
    if (!result.data.id) throw result
    return Promise.resolve(result)
  },

  deletarItem: async (_, payload) => {
    const result = await http.delete(url, { params: payload })
    // if (!result.data.success) throw result
    return Promise.resolve(result.data)
  },

  buscarItem: async ({ commit }, payload) => {
    const result = await http.get(`${url}/view`, payload)
    commit('LISTA_ITEM', result.data)
  },

  getItem: ({ commit }, payload) => {
    commit('LISTA_ITEM', payload)
  },

  limparItem: ({ commit }) => {
    commit('LIMPAR_ITEM')
  },

  obterItensSelect: async ({ commit }, payload) => {
    const result = await http.get(`entidades/ats`, { params: payload })
    commit('LISTA_ITENS_SELECT', result.data)
  },

  clearItens: ({ commit }) => {
    commit('CLEAR_ITENS')
    commit('LIMPAR_ITEM')
  }
}

const mutations = {
  'LISTA_ITENS' (state, payload) {
    state.itens = payload.resultMap
    state.totalItens = payload.totalItens
  },

  'LISTA_ITEM' (state, payload) {
    state.item = Object.assign({}, payload)
  },

  'LIMPAR_ITEM' (state) {
    state.item = {
      id: '',
      cod: '',
      name: '',
      cnpj: '',
      phone: '',
      cep: '',
      address: '',
      city: '',
      state: '',
      neighborhood: '',
      number: '',
      complement: '',
      responsible: {
        name: '',
        cpf: '',
        phone: '',
        phoneComercial: '',
        cep: '',
        address: '',
        state: '',
        city: '',
        neighborhood: '',
        number: '',
        complement: '',
      }
    }
  },

  'LISTA_ITENS_SELECT' (state, payload) {
    state.itemSelect = payload.resultMap
    state.totalItemSelect = payload.totalItens
  },

  'CLEAR_ITENS' (state) {
    state.itens = []
    state.totalItens = 0
    state.itemSelect = []
  }
}

const getters = {
  listaItens: state => state.itens,
  totalItens: (state) => state.totalItens,
  totalItemSelect: (state) => state.totalItemSelect,
  item: state => state.item,
  listaItensSelect: (state) => {
    return map(state.itemSelect, item => {
      return {
        text: item.name,
        value: item.id
      }
    })
  }
}

export default { namespaced: true, state, actions, mutations, getters }