export default [
  {
    path: 'gestao/entidades/supervisor',
    name: 'supervisor',
    component: () => import('./index'),
    meta: {
      visible: true,
      title: 'Supervisor',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Gestão', link: 'gestao' },
        { name: 'Rede', link: 'entidades' },
        { name: 'Supervisor' }
      ]
    }
  }
]