import route from '@/core/router'
import { last } from 'lodash'

const state = {
  visitedViews: []
}

const mutations = {
  ADD_VISITED_VIEW: (state, view) => {
    if (state.visitedViews.some(v => v.path === view.path)) return
    switch (view.meta.visible) {
      case false:
        return
      default:
        state.visitedViews.push(
          Object.assign({}, view, {
            title: view.meta.title || 'no-name'
          })
        )
    }
  },
  DEL_VISITED_VIEW: (state, view) => {
    for (const [i, v] of state.visitedViews.entries()) {

      if (v.path === view.path) {
        state.visitedViews.splice(i, 1)

        if (window.location.pathname === view.path) {
          let ultimoViews = last(state.visitedViews)
          ultimoViews ? route.push(ultimoViews.path) : route.push('/home')
        }
        break
      }
    }
  },
}

const actions = {
  addView({ commit }, view) {
    commit('ADD_VISITED_VIEW', view)
  },

  delView({ commit }, view) {
    commit('DEL_VISITED_VIEW', view)
  },

  // delCachedView({ commit, state }, view) {
  //   return new Promise(resolve => {
  //     commit('DEL_CACHED_VIEW', view)
  //     resolve([...state.cachedViews])
  //   })
  // },

  // delOthersViews({ dispatch, state }, view) {
  //   return new Promise(resolve => {
  //     dispatch('delOthersVisitedViews', view)
  //     dispatch('delOthersCachedViews', view)
  //     resolve({
  //       visitedViews: [...state.visitedViews],
  //       cachedViews: [...state.cachedViews]
  //     })
  //   })
  // },
  // delOthersVisitedViews({ commit, state }, view) {
  //   return new Promise(resolve => {
  //     commit('DEL_OTHERS_VISITED_VIEWS', view)
  //     resolve([...state.visitedViews])
  //   })
  // },
  // delOthersCachedViews({ commit, state }, view) {
  //   return new Promise(resolve => {
  //     commit('DEL_OTHERS_CACHED_VIEWS', view)
  //     resolve([...state.cachedViews])
  //   })
  // },

  // delAllViews({ dispatch, state }, view) {
  //   return new Promise(resolve => {
  //     dispatch('delAllVisitedViews', view)
  //     dispatch('delAllCachedViews', view)
  //     resolve({
  //       visitedViews: [...state.visitedViews],
  //       cachedViews: [...state.cachedViews]
  //     })
  //   })
  // },
  // delAllVisitedViews({ commit, state }) {
  //   return new Promise(resolve => {
  //     commit('DEL_ALL_VISITED_VIEWS')
  //     resolve([...state.visitedViews])
  //   })
  // },
  // delAllCachedViews({ commit, state }) {
  //   return new Promise(resolve => {
  //     commit('DEL_ALL_CACHED_VIEWS')
  //     resolve([...state.cachedViews])
  //   })
  // },

  // updateVisitedView({ commit }, view) {
  //   commit('UPDATE_VISITED_VIEW', view)
  // }
}

const getters = {
  listViews: (state) => {
    return state.visitedViews
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
