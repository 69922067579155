export default [
  {
    path: 'backoffice',
    name: 'backoffice',
    component: () => import('./index'),
    meta: {
      visible: false,
      title: 'Backoffice',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Backoffice' }
      ]
    }
  }
]