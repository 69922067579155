export default [
  {
    path: 'gestao',
    name: 'gestao',
    component: () => import('./index'),
    children: [],
    meta: {
      visible: false,
      title: 'Gestão',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Gestão' }
      ]
    }
  },
]