import http from '@/core/http'
import { filter, groupBy } from 'lodash'

const state = {
  entitiesList: [],
  prizeList: [],
  userList: []
}

const actions = {
  getPremiosBaixos: async ({ commit }, payload) => {
    const result = await http.get('ganhadores/premios-baixos', { params: payload })
    commit('GET_PRIZES', result.data.resultMap)
    return Promise.resolve(result.data.resultMap)
  },

  getUsers: async ({ commit }, payload) => {
    const headers = { moduloname: 'gestao' }
    const result = await http.get('/autenticacao/usuarios', { params: payload, headers })
    commit('GET_USERS', result.data.mapResult)
    return Promise.resolve(result.data.mapResult)
  }
}

const mutations = {
  'GET_ENTITIES' (state, payload) { state.entitiesList = payload },
  'GET_PRIZES' (state, payload) { state.prizeList = payload },
  'GET_USERS' (state, payload) { state.userList = payload }
}

const getters = {
  prizeListMap: state => {
    const result = {}
    const mappedPrizesPerUser = groupBy(state.prizeList, prize => prize.updatedUserId || 1)

    for (const key in mappedPrizesPerUser) {
      if (Object.hasOwnProperty.call(mappedPrizesPerUser, key)) {
        const item = mappedPrizesPerUser[key]
        result[(filter(state.userList, user => user.id == key)[0] || {}).name || 'system'] = item
      }
    }
    return result
  }
}

export default { namespaced: true, state, actions, mutations, getters }