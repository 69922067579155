// import http from '@/core/http'

const state = {
  revenda: [],
  configGecom: {},
  totais: {}
}

const actions = {
  setDataRevenda: async ({ commit }, payload) => {
    // const result = await http.get('gecom-mobile/dados-revenda', { headers: { moduloname: 'relatorios' }, params: payload })
    commit('LISTA_ITENS', payload)
    // return Promise.resolve(result)
  },
}

const mutations = {
  'LISTA_ITENS' (state, payload) {
    state.revenda = payload
  }
}

const getters = {
  revenda: state => state.revenda,
  configGecom: state => state.configGecom,
  totais: state => state.totais,
}

export default { namespaced: true, state, actions, mutations, getters }