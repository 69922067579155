export default [
  {
    path: 'gestao/metas/metas-entidades',
    name: 'metas-entidades',
    component: () => import('./index'),
    meta: {
      visible: false,
      title: 'Metas Promotor',
      breadcrumb: [
        { name: 'Gestão', link: 'gestao' },
        { name: 'Metas', link: 'metas' },
        { name: 'Metas Promotor' }
      ]
    }
  }
]