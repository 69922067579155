import http from '@/core/http'
import { isValidParam } from '@/utils/validationUtils'
import { filter, map } from 'lodash'

const state = {
  costCenters: [],
  costCentersMapped: []
}

const actions = {
  getCostCenters: async ({ commit }) => {
    const result = await http.get('gefin-mais/gefin-cost-center')
    commit('GET_COST_CENTERS', result.data.data[0])
    return Promise.resolve(result.data.data[0])
  },

  submit: async (_, payload) => {
    let result =  {}

    if (payload.isEdit) result = await http.put('gefin-mais/gefin-cost-center', payload.data)
    else result = await http.post('gefin-mais/gefin-cost-center', payload.data)

    return Promise.resolve(result.data.data[0])
  },

  deleteCostCenter: async (_, payload) => {
    const result = await http.delete('gefin-mais/gefin-cost-center', { params: payload })
    return Promise.resolve(result.data.data)
  }
}

const mutations = {
  'GET_COST_CENTERS' (state, payload) {
    state.costCenters = payload
    state.costCentersMapped = map(filter(payload, item => !isValidParam(item.costCenterId)), fatherCostCenter => {
      return {
        ...fatherCostCenter,
        subCostCenters: filter(filter(payload, item => isValidParam(item.costCenterId)), subCostCenter => subCostCenter.costCenterId === fatherCostCenter.id)
      }
    })
    state.subCostCenters = payload.filter(item => isValidParam(item.costCenterId))
  },
 }

const getters = {
  costCenters: state => state.costCenters,
  costCentersMapped: state => state.costCentersMapped
}

export default { namespaced: true, state, actions, mutations, getters }