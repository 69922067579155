// eslint-disable-next-line no-unused-vars
import http from '@/core/http'
import { map } from 'lodash'

const state = {
  itens: [],
  item: {
    id: '',
    name: '',
    date: ''
  },
  itemSelect: [],
  totalItens: 0
}

const actions = {
  cadastrarItem: async (_, payload) => {
    const result = await http.post('autenticacao/module-groups', payload)
    // if (!result.data.success) throw result
    return Promise.resolve(result)
  },

  getItens: async ({ commit }, payload) => {
    const result = await http.get('autenticacao/module-groups', { params: payload })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },

  obterItensSelect: async ({ commit }) => {
    const result = await http.get('autenticacao/module-groups')
    commit('LISTA_ITENS_SELECT', result.data)
  },

  getItem: ({ commit }, payload) => {
    commit('LISTA_ITEM', payload)
  },

  editarItem: async (_, payload) => {
    const result = await http.put('autenticacao/module-groups', payload)
    if (!result.data.id) throw result
    return Promise.resolve(result)
  },

  deletarItem: async (_, payload) => {
    const result = await http.delete('autenticacao/module-groups', {params: payload})
    // if (!result.data.success) throw result
    return Promise.resolve(result)
  },

  buscarItem: async ({ commit }, payload) => {
    const result = await http.post('autenticacao/module-groups/view', payload)
    commit('BUSCAR_ITEM', result.data)
    return Promise.resolve(result.data)
  },

  limparItem: ({ commit }) => {
    commit('LIMPAR_ITEM')
  },

  clearItens: ({ commit }) => {
    commit('CLEAR_ITENS')
    commit('LIMPAR_ITEM')
  }
}

const mutations = {
  'LISTA_ITENS' (state, payload) {
    state.itens = payload.resultpMapOrder
    state.totalItens = payload.totalItens
  },

  'LISTA_ITEM' (state, payload) {
    state.item = Object.assign({}, payload)
  },

  'LIMPAR_ITEM' (state) {
    state.item = {
      id: '',
      name: '',
      date: ''
    }
  },

  'BUSCAR_ITEM' (state, payload) {
    state.item = Object.assign({}, payload)
  },

  'LISTA_ITENS_SELECT' (state, payload) {
    state.itemSelect = payload.resultpMapOrder
  },

  'CLEAR_ITENS' (state) {
    state.itens = []
    state.totalItens = 0
  }
}

const getters = {
  listaItens: (state) => state.itens,
  item: (state) => state.item,
  totalItens: (state) => state.totalItens,

  listaItensSelect: (state) => {
    return map(state.itemSelect, item => {
      return {
        text: item.name,
        value: item.id
      }
    })
  }
}

export default { namespaced: true, state, actions, mutations, getters }