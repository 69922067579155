export default [
  {
    path: 'gestao/entidades/pendentes',
    name: 'pendentes',
    component: () => import('./index'),
    children: [],
    meta: {
      visible: true,
      title: 'Agências Pendentes',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Gestão', link: 'gestao' },
        { name: 'Rede', link: 'entidades' },
        { name: 'Agências Pendentes', }
      ]
    }
  },
]