import http from '@/core/http'
import { merge } from 'lodash'

const endPoint = '/resgate-titulos'

const state = {
  totalItens:0,
  itens: [],
  item: {},
  isStatus: false
}

const actions = {
  getItens: async ({ commit }, payload) => {
    // const result = await http.get(endPoint, { params: Object.assign(payload, { order: { id: 'desc' }})})
    if (!payload) payload = {}

    let _filter = { 'withdrawRequests:status_ilike': '%C%' }

    if (payload.hasOwnProperty('_filter') && state.isStatus) {
      payload._filter = merge(payload._filter, _filter)
    } else if (state.isStatus) {
      payload = Object.assign(payload, { _filter })
    } else {
      payload = { _filter: { 'withdrawRequests:status_ilike': '%N%' }}
    }

    const result = await http.get(endPoint, { params: Object.assign(payload, { order: { id: 'desc' }}) })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },

  cadastrarItem: async (_, payload) => {
    const result = await http.post(endPoint, payload)
    return Promise.resolve(result)
  },

  editarItem: async (_, payload) => {
    const result = await http.put(endPoint, payload)
    return Promise.resolve(result)
  },

  deletarItem: async (_, payload) => {
    const result = await http.delete(endPoint, {params: payload})
    return Promise.resolve(result)
  },

  obterItensSelect: async ({ commit }, payload) => {
    if (payload.hasOwnProperty('_filter')) {
      payload['_filter'] = Object.assign(payload['_filter'], { 'entityTypeId_In': '2,3' })
    } else {
      payload['_filter'] = { 'entityTypeId_In': '2,3' }
    }

    const result = await http.get('entidades/list-tree', { params: payload, headers: { moduloname: 'gestao' } })
    commit('LISTA_ITENS_SELECT', result.data)
    return Promise.resolve(result)
  },

  switchStatus ({ commit }) {
    commit('SWITCH_STATUS')
  },

  setItem: ({ commit }, payload) => {
    commit('LISTA_ITEM', payload)
  },

  limparItem: ({ commit }) => {
    commit('LIMPAR_ITEM')
  },

  clearItens: ({ commit }) => {
    commit('CLEAR_ITENS')
    commit('LIMPAR_ITEM')
  }
}

const mutations = {
  'LISTA_ITENS' (state, payload) {
    state.itens = payload.resultMap
    state.totalItens = payload.totalItens
  },

  'LISTA_ITEM' (state, payload) {
    state.item = Object.assign({}, payload)
  },

  'LIMPAR_ITEM' (state) {
    state.item = {
      id: '',
      cod: '',
      email: '',
      name: '',
      cnpj: '',
      address: '',
      cep: '',
      phone: '',
      city: '',
      state: '',
      neighborhood: '',
      number: '',
      complement: '',
      responsible: {
        name: '',
        cpf: '',
        phone: '',
        phoneComercial: '',
        email: '',
        cep: '',
        address: '',
        state: '',
        city: '',
        neighborhood: '',
        number: '',
        complement: '',
      }
    }
  },

  'LISTA_ITENS_SELECT' (state, payload) {
    state.itemSelect = payload
  },

  'CLEAR_ITENS' (state) {
    state.itens = []
    state.totalItens = 0
  },

  'SWITCH_STATUS' (state) {
    state.isStatus = !state.isStatus
  }
}

const getters = {
  listaItens: state => state.itens,
  totalItens: (state) => state.totalItens,
  item: state => state.item,
  isStatus: state => state.isStatus
}

export default { namespaced: true, state, actions, mutations, getters }