import { reduce } from 'lodash'

export const isOnlyNumber = value => {
    const regex = /^[-]*[\d]+/g
    return regex.test(value)
}

export const isValidCnpj = value => {
    let cnpj = value.replace(/[^\d]+/g,'');

    if(cnpj == '') return false;

    if (cnpj.length != 14) return false;

    // Elimina CNPJs invalidos conhecidos
    if (cnpj == "00000000000000" ||
        cnpj == "11111111111111" ||
        cnpj == "22222222222222" ||
        cnpj == "33333333333333" ||
        cnpj == "44444444444444" ||
        cnpj == "55555555555555" ||
        cnpj == "66666666666666" ||
        cnpj == "77777777777777" ||
        cnpj == "88888888888888" ||
        cnpj == "99999999999999")
        return false;

    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0,tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
            pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0))
        return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0,tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1))
          return false;

    return true;
}

export const isValidCpf = value => {
    if (typeof value !== "string") return false

    const cpf = value.replace(/[\s.-]*/igm, '')
    var soma = 0
    var resto = 0

    if (
        !cpf ||
        cpf.length != 11 ||
        cpf == "00000000000" ||
        cpf == "11111111111" ||
        cpf == "22222222222" ||
        cpf == "33333333333" ||
        cpf == "44444444444" ||
        cpf == "55555555555" ||
        cpf == "66666666666" ||
        cpf == "77777777777" ||
        cpf == "88888888888" ||
        cpf == "99999999999"
    ) {
        return false
    }


    for (let i = 1; i <= 9; i++)
        soma = soma + parseInt(cpf.substring(i-1, i)) * (11 - i)

    resto = (soma * 10) % 11

    if ((resto == 10) || (resto == 11))  resto = 0

    if (resto != parseInt(cpf.substring(9, 10)) ) return false

    soma = 0

    for (var i = 1; i <= 10; i++)
        soma = soma + parseInt(cpf.substring(i-1, i)) * (12 - i)

    resto = (soma * 10) % 11

    if ((resto == 10) || (resto == 11))  resto = 0
    if (resto != parseInt(cpf.substring(10, 11) ) ) return false

    return true
}

export const isValidCpfOrCnpj = (value) => {
    if (!value) return false
    return isValidCpf(value) || isValidCnpj(value)
}

export const isValidParam = (val) => val !== null && val !== undefined && val !== ''

export const isDiffOfZero = (val) => parseFloat(val) !== 0

export const isValidFileSize = (files) => !files.length || reduce(files, (prev, next) => prev + parseInt(next.size), 0) < 10e6

export const isValidPhone = number => {
    if (!number) return false
    let invalid = String(number).replace(/\D/g, '').substring(2, 15)
    number = String(number).replace(/\D/g, '')[2]
    const arrayNumber = ['999999999', '988888888', '977777777', '966666666', '955555555', '944444444', '933333333', '922222222', '911111111', '900000000']
    if (!arrayNumber.includes(invalid) && parseInt(number) === 9 && invalid.length === 9) return true
    return false
}

export const validateDDD = number => {
    if (!number) return false
    number = String(number).substring(1, 3)
    if (parseInt(number) === 55) return false
    return parseInt(number) > 10
}

export const isValidEmail = value => {
    const re = /\S+@\S+\.\S+/
    return re.test(value)
}