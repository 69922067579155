export default [
  {
    path: 'gestao/loterias/concursos-recorrentes',
    name: 'concursos-recorrentes',
    component: () => import('./index'),
    meta: {
      visible: true,
      title: 'Concursos Recorrentes',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Gestão', link: 'gestao' },
        { name: 'Loterias', link: 'loterias' },
        { name: 'Concursos Recorrentes' }
      ]
    }
  }
]