// eslint-disable-next-line no-unused-vars
import http from '@/core/http'
import { map } from 'lodash'

// eslint-disable-next-line no-unused-vars
const url = 'pre-caixa'

const state = {
  id: null,
  pList: [],
  vList: [],
  dados: [],
  itens: [],
  entries: [],
  item: {
    id: '',
    pendingOperationTypeId: '',
    p: '',
    v: '',
    value: '',
    status: 'N',
    params: {
      entity: ''
    }
  },
  filtro: {
    dtStart: '',
    dtEnd: '',
    vStart: '',
    vEnd: '',
    pStart: '',
    pEnd: ''
  },
  entitiesTypes: {},
  totalItens: 0
}

const actions = {
  clearAll: ({ commit }) => {
    commit('CLEAR_ITENS')
  },
  deletarItem: async (_, payload) => {
    const result = await http.delete(url, { params: payload })
    if (!result.data.affected) throw new Error(result)
    return Promise.resolve(result.data)
  },
  cadastrarItem: async ({commit}, payload) => {
    const result = await http.post(url, { ...payload }, { headers: { moduloname: 'g-fin' }})
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result.data)
  },
  baixar: async(_, payload) => {
    const result = await http.post(`${url}/baixar`, payload)
    return Promise.resolve(result.data)
  },
  editarPreCaixa: async (_, payload) => {
    const result = await http.put(url, payload)
    return Promise.resolve(result.data)
  },
  entryTypes: async ({commit}) => {
    const result = await http.get('/tipos-entradas',{ params:{ _addlFilter:{'Entries:module_=': 'Revenda', 'Entries:description_In':'Débito,Crédito'}, headers: { moduloname: 'g-fin' }}})
    commit('ENTRIES', result.data.resultMap)
    return Promise.resolve(result.data.resultMap)
  },
  getItens: async () => {
    return Promise.resolve([])
  },
  getDados: async ({ commit }, payload) => {
    commit('ADD_BOX', [])
    const result = await http.get(url, { params:{ ...payload } })
    commit('ADD_BOX', result.data.resultMap)
    commit('ADD_TOTAL_BOX', result.data)
    return Promise.resolve()
  },
  obterItens: async ({ commit, state }, payload) => {
    const headers = {
      moduloname: 'gestao',
    }
    if (state.filtro) {
      payload.entityTypeId_In = state.filtro
    }
    const result = await http.get('entidades', { params: payload, headers })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result.data)
  },
  getLista: async({ commit }, payload) => {
    const result = await http.get('entidades', { params: payload, headers: { moduloname: 'gestao' } })
    commit('ADD_ENTITIES', { res: result.data.resultMap, type: payload.type })
    return Promise.resolve(result.data)
  },
  editarItem: async({ commit }, payload) => {
    commit('ADD_EDIT_ITEM', payload)
  },
  filtroPromotor: async (_, payload) => {
    const result = await http.get('entidades', { params: payload, headers: { moduloname: 'gestao' } })
    return Promise.resolve(result.data)
  },
  filterEntities: async ({ commit }, payload) => {
    const result = await http.get('entidades', { params: payload, headers: { moduloname: 'gestao' } })
    commit('ADD_ENTITIES', { res: result.data, type: payload.type })
    return Promise.resolve(result.data)
  },
  getEntitiesTypes: async ({ commit }, payload) => {
    const headers = { moduloname: 'gestao' }
    const result = await http.get('entity-types', { params: payload, headers })
    commit('ADD_ENTITY_TYPES', result.data)
    return Promise.resolve(result.data)
  },
}

const mutations = {
  'ENTRIES' (state, payload = []) {
    state.entries = payload
  },
  'ADD_BOX' (state, payload = []) {
    if (payload.length !== 0) {
      state.itens = payload[0]
    } else {
      state.itens = []
    }
  },
  'ADD_TOTAL_BOX' (state, payload = []) {
    state.totalItens = payload.totalItens || 0
  },
  'CLEAR_ITENS' (state) {
    state.item = {
      id: '',
      pendingOperationTypeId: '',
      p: '',
      v: '',
      value: '',
      status: 'N',
      params: {
        estabelecimento: '',
        credenciador: ''
      }
    }
  },
  'ADD_EDIT_ITEM' (state, payload) {
    state.item = payload
  },
  'ADD_ENTITIES' (state, {res, type}) {
    if (type === 'p') state.pList = res
    if (type === 'v') { state.vList = res }
  },
  'ADD_ENTITY_TYPES' (state, payload) {
    state.entitiesTypes = payload.resultMap
  },
}

const formatSelect = (obj, type) => map(obj, v => {
  if (type === 'v') {
    return {text: `(${v.cod}) ${v.name}`, value: v.id, parent: v.parentId }
  }
  return {text: `(${v.cod}) ${v.name}`, value: v.id }
})

const getters = {
  pList: state => formatSelect(state.pList, 'p'), // lista de promotores
  vList: state => formatSelect(state.vList, 'v'), // lista de estabelecimentos
  obj: state => state.item,
  listaItens: state => state.itens || [],
  listaEntries: state => { return map(state.entries, v => ({text: v.description, value: v.id })) },
  listaEntitiesTypes: state => state.entitiesTypes,
  totalItens: state => state.totalItens,
  listDados: (state) => state.dados,
  filtro: (state) => state.filtro
}

export default { namespaced: true, state, actions, mutations, getters }