export default [
  {
    path: 'gestao/metas/listar',
    name: 'listar',
    component: () => import('./index'),
    meta: {
      visible: false,
      title: 'Lista de Metas',
      breadcrumb: [
        { name: 'Gestão', link: 'gestao' },
        { name: 'Metas', link: 'metas' },
        { name: 'Listar Metas' }
      ]
    }
  }
]