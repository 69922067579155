import { register } from 'register-service-worker'
import { init } from './core/service/firebase'

register(`./service-worker.js`, {
  registered () {
    console.info('Service worker registration successful.')
  },
  ready (registration) {
    console.info('Service worker registration is ready.')

    if (Notification.permission !== 'granted') {
      Notification.requestPermission((permission) => {
        if (permission === 'granted') init(registration)
      })
    } else {
      init(registration)
    }
  },
  cached () {
    console.info('Service worker registration cached.')
  },
  updatefound () {
    console.info('New content is downloading.')
  },
  updated () {
    console.info('New content is available; please refresh.')
  },
  offline () {
    console.info('No internet connection found. App is running in offline mode.')
  },
  error (error) {
    console.error('Error during service worker registration:', error)
  }
})
