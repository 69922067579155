export default [
  {
    path: 'relatorios',
    name: 'relatorios',
    component: () => import('./index'),
    children: [],
    meta: {
      visible: false,
      title: 'Relatórios',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Relatórios' },
      ]
    }
  },
]