export default [
    {
      path: 'd-fin/gefin-relatorios/dre',
      name: 'dre',
      component: () => import('./index'),
      meta: {
        visible: true,
        title: 'DRE',
        breadcrumb: [
          { name: 'Home', link: 'home' },
          { name: 'D-FIN', link: 'd-fin' },
          { name: 'Relatorios', link: 'gefin-relatorios' },
          { name: 'DRE'},
        ]
      }
    }
  ]