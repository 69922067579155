export default [
  {
    path: 'relatorios/aniversariantes',
    name: 'aniversariantes',
    component: () => import('./index'),
    children: [],
    meta: {
      visible: true,
      title: 'Aniversariantes',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Relatórios', link: 'relatorios' },
        { name: 'Aniversariantes' },
      ]
    }
  },
]