export default [
  {
    path: 'backoffice/analise-clientes',
    name: 'analise-clientes',
    component: () => import('./index'),
    children: [],
    meta: {
      visible: true,
      title: 'Backoffice',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Backoffice', link: 'backoffice' },
        { name: 'Análise de Clientes' },
      ]
    }
  },
]