import http from '@/core/http'
// import { filter, map, groupBy } from 'lodash'
import { currency } from '@/core/service/utils'
import { size } from 'lodash'

const state = {
  dashBoardCategorias:[],
  lancamentoCosting: { D: { data: [] } },
  lancamentoPorCategorias: {
    D: {
      data: []
    },
    R: {
      data: []
    }
  }
}

const actions = {
  getLancamentosPorCategorias: async ({ commit }, payload) => {
    const result = await http.get('gefin-relatorios/relatorios-categorias', { params: { ...payload } })
    commit('SET_LANCAMENTOS_POR_CATEGORIAS', {payload: result.data.data, costing: result.data.groupByCosting})
    return Promise.resolve(result.data.data[0])
  },
  getCategiriasDashboard: async ({ commit }, payload) => {
    const result = await http.get('gefin-relatorios/categorias-dashboard', { params: { ...payload } })
    commit('SET_CATEGORIAS_DASHBOARD', result.data.data[0])
    return Promise.resolve(result.data.data[0])
  }
}

const mutations = {
  'SET_LANCAMENTOS_POR_CATEGORIAS' (state, {payload, costing }) {
    state.lancamentoPorCategorias.D.data = payload.D ? payload.D.data : []
    state.lancamentoPorCategorias.D.total = payload.D ? payload.D.total : 0
    state.lancamentoPorCategorias.R.data = payload.R ? payload.R.data : []
    state.lancamentoPorCategorias.R.total = payload.R ? payload.R.total : 0

    // costing
    state.lancamentoCosting.D.data = costing.D ? costing.D.data : []
    state.lancamentoCosting.D.total = costing.D ? costing.D.total : 0
  },
  'SET_CATEGORIAS_DASHBOARD' (state, payload) {
    state.dashBoardCategorias = payload
  }
}

const getSeries = (data, type, referencia = 0) => {
  // return data.reduce((acc, curr) => acc.concat(curr.total < 0 ? curr.total * -1 : curr.total), [])
  switch (type) {
    case 'C':
    case 'R':
      // return data.reduce((acc, curr) => acc.concat(curr.lancamentos.categoriaPai.name), [])
      return getDataSeries(data, referencia)
    case 'D':
      return data.reduce((acc, curr) => acc.concat(curr.total < 0 ? curr.total * -1 : curr.total), [])
    default:
      return []
  }
}

// eslint-disable-next-line
const getPercent = (data) => {
  if (!size(data.data)) return []
  return data.data.map(v => parseFloat(((v.total / data.total)* 100)).toFixed(2) + '%')
}

const getDataLabels = (data) => {
  let arrLancamento = []
    data.forEach(it => {
      it.lancamentos.raiz.children.forEach(item => {
        // debugger
        arrLancamento.push(String(item.data[0].data.description).replace('WEB', ''))
        // item.data.forEach(dados => {
        //   arrLancamento.push(dados.data.description)
        // })
      })
    })
    return arrLancamento
}

const getDataSeries = (data, referencia) => {
  let arrLancamento = []
  let receita = !size(referencia) ? 0 : Math.abs(referencia[0].total)
  
  data.forEach(it => {
    it.lancamentos.raiz.children.forEach(item => {
      // debugger
      if (receita) {
        // let max = Math.max(item.total, receita)
        // let min = Math.min(item.total, receita)

        // let porcentagem = Number((receita / item.total) * 100)
        // console.log(porcentagem)
        arrLancamento.push(Math.abs(item.total))
      } else {
        arrLancamento.push(Math.abs(item.total))
      }
    })
  })

  return arrLancamento
}

const getLabels = (data, type) => {
  switch (type) {
    case 'C':
    case 'R':
      // return data.reduce((acc, curr) => acc.concat(curr.lancamentos.categoriaPai.name), [])
      return getDataLabels(data)
    case 'D':
      return data.reduce((acc, curr) => acc.concat(curr.lancamentos.categoriaPai.name), [])
    default:
      return []
  }
}
// eslint-disable-next-line
const getColors = (data) => {
  if(!size(data)) return []
  return data.map(v => v.lancamentos.categoriaPai.colour)
}

const getters = {
  getCategoriasDashboard: state => state.dashBoardCategorias,
  lancamentoPorCategorias: state => state.lancamentoPorCategorias,
  lancamentoCosting: state => state.lancamentoCosting,
   graficoDonutR: state => ({
    series: getSeries(state.lancamentoPorCategorias.R.data, 'R'),
    chartOptions: {
      labels: getLabels(state.lancamentoPorCategorias.R.data, 'R'),
      fill: {
        type: 'gradient'
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              value: {
                show: true,
                fontSize: '20px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 400,
                color: undefined,
                offsetY: 16,
                formatter: function (val) {
                  return currency(val)
                }
              },
              name: {
                  show: true,
                  fontSize: '24px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 600,
                  color: undefined,
                  offsetY: 0,
                  formatter: function (val) {
                    return val
                  }
                },
                total: {
                  show: true,
                  showAlways: true,
                  label: 'Receitas',
                  fontSize: '22px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 600,
                  color: '#373d3f',
                  formatter: function (w) {
                    return currency(w.globals.seriesTotals.reduce((a, b) => {
                      return a + b
                    }, 0))
                  }
                },
              },
            }
          }
      },
      tooltip: {
        style: {
          fontSize: '14px',
          fontFamily: undefined
        },
        y: {
          show: true,
          formatter: function (val) {
            return currency(val)
          },
          title: {
            formatter: (seriesName) => {
              // console.log(seriesName)
              return seriesName
            },
          },
        },
      },
      legend: {
        show: false
      }
    }
  }),

   // Custeio
  graficoColuna: () => ({
    series: getSeries(state.lancamentoCosting.D.data, 'C', state.lancamentoPorCategorias.R.data),
    chartOptions: {
      labels: getLabels(state.lancamentoCosting.D.data, 'C'),
      fill: {
        type: 'gradient'
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              value: {
                show: true,
                fontSize: '20px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 400,
                color: undefined,
                offsetY: 16,
                formatter: function (val) {
                  return currency(val)
                }
              },
              name: {
                  show: true,
                  fontSize: '24px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 600,
                  color: undefined,
                  offsetY: 0,
                  formatter: function (val) {
                    return val
                  }
                },
                total: {
                  show: true,
                  showAlways: true,
                  label: 'Custeio',
                  fontSize: '22px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 600,
                  // color: '#373d3f',
                  // formatter: function (w) {
                  //   return currency(w.globals.seriesTotals.reduce((a, b) => {
                  //     return a + b
                  //   }, 0))
                  // }
                  formatter: function (w) {
                    const totalCusteio = w.globals.seriesTotals.reduce((a, b) => a + b, 0)
                    // const totalReceita = state.lancamentoPorCategorias.R.data.reduce((crr, { total }) => crr + total, 0)
                    // const percentageCusteio = (Math.abs(totalCusteio) / Math.abs(totalReceita)) * 100
                    return currency(Math.abs(totalCusteio))
                  }
                },
              }
            }
          }
      },
      tooltip: {
        style: {
          fontSize: '14px',
          fontFamily: undefined
        },
        y: {
          show: true,
          formatter: function (val) {
            return currency(val)
          },
          title: {
            formatter: (seriesName) => {
              // console.log(seriesName)
              return seriesName
            },
          },
        },
      },
      legend: {
        show: false
      }
    }
  }),
  graficoDonutD: state => ({
    series: getSeries(state.lancamentoPorCategorias.D.data, 'D'),
    chartOptions: {
      labels: getLabels(state.lancamentoPorCategorias.D.data, 'D'),
      fill: {
        type: 'gradient'
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              value: {
                show: true,
                fontSize: '20px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 400,
                color: undefined,
                offsetY: 16,
                formatter: function (val) {
                  return currency(val)
                }
              },
              name: {
                show: true,
                fontSize: '24px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
                color: undefined,
                offsetY: 0,
                formatter: function (val) {
                  return val
                }
              },
              total: {
                show: true,
                showAlways: true,
                label: 'Despesas',
                fontSize: '22px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
                color: '#373d3f',
                formatter: function (w) {
                  const totalDespesas = w.globals.seriesTotals.reduce((a, b) => a + b, 0)
                  // const totalReceita = state.lancamentoPorCategorias.R.data.reduce((crr, { total }) => crr + total, 0)
                  // const percentageCusteio = (Math.abs(totalDespesas) / Math.abs(totalReceita)) * 100
                  return currency(totalDespesas)
                }
              },
            }
          }
        }
      },
      tooltip: {
        style: {
          fontSize: '14px',
          fontFamily: undefined
        },
        y: {
          show: true,
          formatter: function (val) {
            return currency(val)
          },
          title: {
            formatter: (seriesName) => {
              // console.log(seriesName)
              return seriesName
            },
          },
        },
      },
      
      legend: {
        show: false
      }
    }
  }),
  /*
  *****Exemplo grafico de BARRAS *******
  graficoDonutR: state => ({
    series: [{ name: 'Valor:', data:  getSeries(state.lancamentoPorCategorias.R.data, 'R') }],
    chartOptions: {
      labels: getLabels(state.lancamentoPorCategorias.R.data),
      tooltip: {
        style: {
          fontSize: '14px',
          fontFamily: undefined
        },
        y: {
          show: true,
          formatter: function (val) {
            return currency(val)
          },
          title: {
            formatter: (seriesName) => {
              // console.log(v.dataPointIndex, v.w.config.labels[v.dataPointIndex])
              return seriesName
            },
          },
        },
      },
      yaxis: {
        labels: {
          show: false,
          formatter: (serie) => {
            return serie
          }
        }
      },
      xaxis:{
        labels:{
          show: false,
          formatter: (v) => {
            return String(v).toUpperCase()
          }
        }
      },
      title: {
        text: 'Receitas',
        align: 'center',
        floating: true,
        style: {
          offsetY: 50,
          offsetX: 50,
          fontSize: '24px',
          fontWeight: 'bold',
          fontFamily: 'Roboto, sans-serif',
          color: '#1cb4ff'
        }
      },
      fill: {
        colors:['#47ca82'],
      },
      legend: {
        show: false
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          color: '#FFF'
        },
        formatter: function (val, opt) {
          return [opt.w.globals.labels[opt.dataPointIndex] , `${currency(val)}`]
        }
      },
    }
  }),
*/
}

export default { namespaced: true, state, actions, mutations, getters }