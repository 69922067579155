// eslint-disable-next-line no-unused-vars
import http from '@/core/http'
// import { map } from 'lodash'

const state = {
  itens: [],
  item: {
    id: '',
    name: '',
    path: '',
    moduleGroupId: ''
  },
  totalItens: 0
}

const actions = {
  getItens: async ({ commit }, payload) => {
    const result = await http.get('/autenticacao/modules', { params: payload })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },

  cadastrarItem: async (_, payload) => {
    const result = await http.post('/autenticacao/modules', payload)
    // if (!result.data.success) throw result
    return Promise.resolve(result)
  },

  editarItem: async (_, payload) => {
    const result = await http.put('/autenticacao/modules', payload)
    return Promise.resolve(result)
  },

  getItem: ({ commit }, payload) => {
    commit('LISTA_ITEM', payload)
  },

  deletarItem: async (_, payload) => {
    const result = await http.delete('/autenticacao/modules', {params: payload})
    return Promise.resolve(result)
  },

  buscarItem: async ({ commit }, payload) => {
    const result = await http.post('/autenticacao/modules/view', payload)
    commit('BUSCAR_ITEM', result.data)
    return Promise.resolve(result.data)
  },

  limparItem: ({ commit }) => {
    commit('LIMPAR_ITEM')
  },

  clearItens: ({ commit }) => {
    commit('CLEAR_ITENS')
    commit('LIMPAR_ITEM')
  }
}

const mutations = {
  'LISTA_ITENS' (state, payload) {
    state.itens = payload.mapResult
    state.totalItens = payload.totalItens
  },

  'LISTA_ITEM' (state, payload) {
    state.item = Object.assign({}, payload)
  },

  'LIMPAR_ITEM' (state) {
    state.item = {
      id: '',
      name: '',
      path: '',
      moduleGroupId: ''
    }
  },

  'BUSCAR_ITEM' (state, payload) {
    state.item = Object.assign({}, payload)
  },

  'CLEAR_ITENS' (state) {
    state.itens = []
    state.totalItens = 0
  }
}

const getters = {
  listaItens: (state) => state.itens,
  item: (state) => state.item,
  totalItens: (state) => state.totalItens
}

export default { namespaced: true, state, actions, mutations, getters }