import http from '@/core/http'
import { map } from 'lodash'

const state = { 
  items: [],
  submitDate: null,
  supervisorList: [],
  promoterList: [],
  total: null
}
const actions = {
  submit: async ({commit}, payload) => {
    const result = await http.get(`/entradas/pagamentos`, { params: payload })
    const data = result.data.result
    let total = 0
    data.forEach(element => total += element.subTotal )
    commit('TOTAL', total)
    commit('ITEMS', data)
    commit('SUBMIT_DATE', result.data.date)
    return Promise.resolve(data)
  },
  getSupervisor: async ({commit}) => {
    const result = await http.get(`/entidades?entityTypeId=2`, { headers: { moduloname: 'gestao' }, params: { order: { 'cod::int': 'ASC' } }})
    const data = map(result.data.resultMap, item => {
      return {
        text: `${item.cod} - ${item.name}`,
        value: item
      }
    })
    commit('SUPERVISOR_LIST', data)
    return Promise.resolve(data)
  },
  getPromoter: async ({commit}) => {
    const result = await http.get(`/entidades?entityTypeId=3`, { headers: { moduloname: 'gestao' }, params: { order: { 'cod::int': 'ASC' } }})
    const data = map(result.data.resultMap, item => {
      return {
        text: `${item.cod} - ${item.name}`,
        value: item
      }
    })
    commit('PROMOTER_LIST', data)
    return Promise.resolve(data)
  }
}
const mutations = {
  'ITEMS' (state, payload) { state.items = payload },
  'SUBMIT_DATE' (state, payload) { state.submitDate = payload },
  'SUPERVISOR_LIST' (state, payload) { state.supervisorList = payload },
  'PROMOTER_LIST' (state, payload) { state.promoterList = payload },
  'TOTAL' (state, payload) { state.total = payload }
}
const getters = {
  items: state => state.items,
  submitDate: state => state.submitDate,
  supervisorList: state => state.supervisorList,
  promoterList: state => state.promoterList,
  total: state => state.total
}

export default { namespaced: true, state, actions, mutations, getters }