import { messaging } from '../config/firebase'
import { getToken, onMessage } from 'firebase/messaging'
import session from '@/core/service/session'
import config from '../config'

export const init = (serviceWorkerRegistration) => {
  getToken(messaging, { vapidKey: config.firebase_public_key, serviceWorkerRegistration }).then((currentToken) => {
    if (currentToken) {
      if (!session.get('jwt')) {
        session.set('firebase-token', currentToken)
      }
    } else {
      console.info('No registration token available. Request permission to generate one.')
    }
  }).catch((err) => {
    console.error('An error occurred while retrieving token. ', err)
  })

  onMessage(messaging, (payload) => {
    console.info('Message received in foreground. ', payload)

    const notificationOptions = {
      image: payload.notification.image,
      body: payload.notification.body
    }

    return new Notification(payload.notification.title, notificationOptions)
  })
}
