export default [
  {
    path: 'consultas/acessos',
    name: 'acessos',
    component: () => import('./index'),
    meta: {
      visible: true,
      title: 'Acessos',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Consultas', link: 'consultas' },
        { name: 'Acessos' }
      ]
    }
  }
]