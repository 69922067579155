export default [
  {
    path: 'gestao/geolocalizacao',
    name: 'geolocalizacao',
    component: () => import('./index'),
    children: [],
    meta: {
      visible: true,
      title: 'Geolocalização',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Gestao', link: 'gestao' },
        { name: 'Geolocalização' },
      ]
    }
  },
]