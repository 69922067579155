export default [
  {
    path: 'gestao/terminais/devices',
    name: 'devices',
    component: () => import('./index'),
    meta: {
      visible: true,
      title: 'Dispositivos',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Gestão', link: 'gestao' },
        { name: 'Terminais', link: 'terminais' },
        { name: 'Dispositivos' }
      ]
    }
  }
]