import http from '@/core/http'
// import { map } from 'lodash'
import moment from 'moment'

const endPoint = 'produtos/premios-recorrentes'

const state = {
  itens: [],
  item: {
    sweepstakeId: '',
    number: '',
    value: 0.00,
    description: '',
    image: '',
    percentage: 0,
    prizeTypeId: '',
    qtdPrizes: '',
    interval: ''
  },
  lista: '',
  itemAddPermission: [],
  acl: null,
  itemSelect: {
    text: 'Teste',
    value: 1
  },
  premios: []
}

const actions = {
  obterItens: async ({ commit }, payload) => {
    const result = await http.get(endPoint, { params: payload })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },

  cadastrarItem: async (_, payload) => {
    const result = await http.post(endPoint, payload)
    return Promise.resolve(result)
  },

  editarItem: async (_, payload) => {
    const result = await http.put(endPoint, payload)
    return Promise.resolve(result)
  },

  deletarItem: async (_, payload) => {
    const result = await http.delete(endPoint, {params: payload})
    return Promise.resolve(result)
  },

  getItem: async (_, payload) => {
    const result = await http.get(`${endPoint}/view`, { params: payload })
    return Promise.resolve(result)
  },

  setItem: ({ commit }, payload) => {
    commit('LISTA_ITEM', payload)
    return Promise.resolve(payload)
  },

  limparItemCadastro: ({ commit }, payload) => {
    commit('LIMPAR_ITEM_CADASTRO', payload)
    commit('LIMPAR_ITENS', payload)
    commit('LIMPAR_ITEM')
  },

  limparItem: ({ commit }) => {
    commit('LIMPAR_ITEM')
  },
  
  limparItens: ({ commit }) => {
    commit('LIMPAR_ITEM')
    commit('LIMPAR_ITENS')
  },

  getItensPrizeTypes: async ({ commit }, payload) => {
    const result = await http.get('prize-type', payload)
    commit('LISTA_ITENS_SELECT', result.data)
  },

  addPremio: async({ commit, state }, payload) => {
    commit('ADD_PREMIOS', payload)
    let arrayNumber = []
    state.premios.forEach(item => {
      arrayNumber.push(item.number)
    })
    state.item.number = parseInt(Math.max.apply(Math, arrayNumber)) + 1
    state.item.percentage = 0

    return state.premios
  },

  removePremio: async({ commit }, payload) => {
    commit('REMOVE_PREMIO', payload)
    return state.premios
  }
}

const mutations = {
  'LISTA_ITENS' (state, payload) {
    state.premios = payload
  },

  'LISTA_ITEM' (state, payload) {
    state.item = Object.assign({}, payload.item)
    state.item.dtDraw = moment(payload.item.dtDraw, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY HH:mm')
    state.item.idArray = payload.index
  },

  'LIMPAR_ITEM_CADASTRO' (state, payload) {
      state.item.number = ''
      state.item.value = 0.00
      state.item.description = ''
      state.item.image = ''
      state.item.percentage = 0
      state.item.id = ''
      state.item.idArray = ''
      state.item.dtDraw = payload
  },

  'LIMPAR_ITEM' (state) {
    state.item = {
      sweepstakeId: '',
      number: '',
      value: 0.00,
      description: '',
      image: '',
      percentage: 0,
      prizeTypeId: ''
    }
  },

  'LIMPAR_ITENS' (state) {
    state.itens = []
    state.premios = []
  },

  'LISTA_ITENS_SELECT' (state, payload) {
    state.itemSelect = payload
  },

  'ADD_PREMIOS' (state, payload) {
    if (payload.idArray >= 0) {
      // state.premios = state.premios.filter((_, index) => index !== payload.idArray)
      state.premios.splice(payload.idArray, 1, payload)
      delete state.item.idArray

      return
    }
    state.premios.push(payload)
  },

  'REMOVE_PREMIO' (state, payload) {
    state.premios = state.premios.filter(item => item.number !== payload.number)
  }
}

const getters = {
  listaItens: state => state.premios,
  item: state => state.item,
  listaItensSelect: state => state.itemSelect
}

export default { namespaced: true, state, actions, mutations, getters }