import http from '@/core/http'
import { map } from 'lodash'

const endPoint = 'vendas-creditos'

const state = {
  totalItens:0,
  itens: [],
  listCreditosVendidos: [],
  item: {
    id: '',
    cod: '',
    value: '',
    created: ''
  }
}

const actions = {
  getItens: async ({ commit }, payload) => {
    const result = await http.get(endPoint, { params: Object.assign(payload, { order: { 'Credits.id': 'DESC' }})})
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },

  cadastrarItem: async (_, payload) => {
    const result = await http.post(endPoint, payload)
    return Promise.resolve(result)
  },

  editarItem: async (_, payload) => {
    const result = await http.put(endPoint, payload, { headers: { moduloname: 'consultas' }})
    return Promise.resolve(result)
  },

  deletarItem: async (_, payload) => {
    const result = await http.delete(endPoint, {params: payload})
    return Promise.resolve(result)
  },

  listaCreditosVendidos: async ({ commit }, payload) => {
    const result = await http.get(`${endPoint}/list`, { params: payload, headers: { moduloname: 'consultas' } })
    commit('LISTA_CREDITOS_VENDIDOS', result.data.data)
    return Promise.resolve(result)
  },

  getItem: ({ commit }, payload) => {
    commit('LISTA_ITEM', payload)
  },

  limparItem: ({ commit }) => {
    commit('LIMPAR_ITEM')
  },

  clearItens: ({ commit }) => {
    commit('CLEAR_ITENS')
    commit('LIMPAR_ITEM')
  }
}

const mutations = {
  'LISTA_ITENS' (state, payload) {
    state.itens = payload.resultMap
    state.totalItens = payload.totalItens
  },

  'LISTA_ITEM' (state, payload) {
    state.item = Object.assign({}, payload)
  },

  'LIMPAR_ITEM' (state) {
    state.item = {
      id: '',
      cod: '',
      value: '',
      created: ''
    }
  },

  'LISTA_ITENS_SELECT' (state, payload) {
    state.itemSelect = payload
  },

  'LISTA_CREDITOS_VENDIDOS' (state, payload) {
    state.listCreditosVendidos = payload
  },

  'CLEAR_ITENS' (state) {
    state.itens = []
    state.totalItens = 0
  }
}

const getters = {
  listaItens: state => state.itens,
  totalItens: (state) => state.totalItens,
  listCreditsNotCanceled: state => state.listCreditosVendidos.filter(item => item.status !== 'C'),
  listCreditsCanceled: state => state.listCreditosVendidos.filter(item => item.status === 'C'),
  item: state => state.item,
  listCreditosVendidos: state => state.listCreditosVendidos,
  listaItensSelect: (state) => {
    return map(state.itens, item => {
      return {
        text: item.name || item.cod,
        value: item.id
      }
    })
  }
}

export default { namespaced: true, state, actions, mutations, getters }