// eslint-disable-next-line no-unused-vars
import http from '@/core/http'
// import { map } from 'lodash'

const state = {
  dataInstalations: '',
  dataPrognotico: ''
}

const actions = {
  clearAll: ({ commit }) => {
    commit('CLEAR_ITENS')
  },

  getDataInstallations: async ({ commit }, payload) => {
    commit('CLEAR_DESCARGA_ONLINE')

    const result = await http.get('/terminais/instalacao/view', { params: payload })
    const resultdeviceProg = await http.get('/terminais/instalacao/dados-prog', { params: { serial: result.data.device.serial } })

    commit('DATA_INSTALATIONS', result.data)
    commit('DATA_PROGNOTICO', resultdeviceProg.data[0])
    return Promise.resolve(result)
  },

  registrarDescargaOnline: async (_, payload) => {
    const result = await http.post('descarga-online', payload)
    return Promise.resolve(result)
  },

  clearDescargaOnline: async ({ commit }) => {
    commit('CLEAR_DESCARGA_ONLINE')
  }
}

const mutations = {
 'DATA_INSTALATIONS' (state, payload) {
  state.dataInstalations = payload
 },

 'DATA_PROGNOTICO' (state, payload) {
  state.dataPrognotico = payload
 },

 'CLEAR_DESCARGA_ONLINE' (state) {
   state.dataPrognotico = ''
   state.dataInstalations = ''
 }
}

const getters = {
  dataInstalations: state => state.dataInstalations,
  dataPrognotico: state => state.dataPrognotico
}

export default { namespaced: true, state, actions, mutations, getters }