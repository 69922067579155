export default [
  {
    path: 'backoffice/bi-operacoes',
    name: 'bi-operacoes',
    component: () => import('./index'),
    meta: {
      visible: true,
      title: 'BI Operações',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Backoffice', link: 'backoffice' },
        { name: 'BI-Operações' },
      ]
    }
  }
]
