import http from '@/core/http'
import { map } from 'lodash'

const state = {
  bankList: [],
  accounts: []
}

const actions = {
  getBankList: async ({ commit }) => {
    const result = await http.get('gefin-mais/contas/accounts/institutions')
    commit('GET_BANK_LIST', result.data.data[0])
    return Promise.resolve(result.data.data[0])
  },

  submit: async (_, payload) => {
    let result = {}

    if (!payload.isEdit) {
      delete payload.isEdit
      result = await http.post('gefin-mais/contas/accounts', payload)
    } else {
      delete payload.isEdit
      result = await http.put('gefin-mais/contas/accounts', payload)
    }

    return Promise.resolve(result.data.data)
  },

  getAccounts: async ({ commit }) => {
    const result = await http.get('gefin-mais/contas/accounts')
    commit('GET_ACCOUNT_LIST', result.data.data[0])
    return Promise.resolve(result.data.data[0])
  },

  deleteAccount: async (_, payload) => {
    const result = await http.delete('gefin-mais/contas/accounts', { params: payload })
    return Promise.resolve(result.data.data)
  }
}

const mutations = {
  'GET_BANK_LIST' (state, payload) { state.bankList = payload },
  'GET_ACCOUNT_LIST' (state, payload) { state.accounts = map(payload, item => {
    item.balance = item.balance || item.initialBalance
    return item
  }) }
}

const getters = {
  bankList: state => state.bankList,
  accounts: state => state.accounts
}

export default { namespaced: true, state, actions, mutations, getters }