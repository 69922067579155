import http from '@/core/http'
// eslint-disable-next-line
import { size, uniqBy, map } from 'lodash'

const state = {
  produtos: null
}

const actions = {
  getDados: async ({ commit }, payload) => {
    commit('ADD_PRODUTOS', [])
    const result = await http.get('gefin-relatorios/list-vendas-produtos', {params: payload })
    commit('ADD_PRODUTOS', result.data)
    return Promise.resolve(result.data)
  }
}

const mutations = {
  'ADD_PRODUTOS' (state, payload) {
    state.produtos = payload
  }
}

const getters = {
  listProdutos: (state) => size(state.produtos) ? state.produtos.data : [],
  listLabelProdutos: (state) => {
    const dados = size(state.produtos) ? state.produtos.data : []
    const labels = map(dados, v => v.produto)
    return uniqBy(labels)
  }
}

export default { namespaced: true, state, actions, mutations, getters }