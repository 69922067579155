export default [
    {
      // path: 'relatorios/comissoes-admin',
      path: '',
      name: 'comissoes-admin',
      component: () => import('./index'),
      meta: {
        visible: true,
        title: 'Comissões Admin',
        breadcrumb: [
          { name: 'Home', link: 'home' },
          { name: 'Relatórios', link: 'relatorios' },
          { name: 'Comissões Admin'},
        ]
      }
    }
  ]