export default [
  {
    path: 'relatorios/dashboard',
    name: 'dashboard',
    component: () => import('./index'),
    meta: {
      visible: true,
      title: 'Moreira',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Relatorios', link: 'relatorios' },
        // { name: 'Dashboard', link: 'dashboard' },
        { name: 'Dashboard' }
      ]
    }
  }
]