/* Metodoos de add get set remove sessio storage */
import Cookies from 'js-cookie'
export default {
  get: (key) => {
    return Cookies.get(key) || false
  },
  set: (key, value = null) => {
    Cookies.set([key], value)
  },
  getObject: (key) => {
    return Cookies.get(key)
  },
  setObject: (key, value) => {
    Cookies.set(key, JSON.stringify(value))
    return Cookies.get(key)
  },
  remove: (key) => {
    return Cookies.remove(key) || false
  }
}
