export default [
  {
    path: 'd-fin/gefin-relatorios/bi',
    name: 'bi',
    component: () => import('./index'),
    meta: {
      visible: true,
      title: 'BI',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'D-FIN', link: 'd-fin' },
        { name: 'Relatorios', link: 'gefin-relatorios' },
        { name: 'BI'},
      ]
    }
  }
]
