export default [
  {
    path: 'relatorios/bi-painel',
    name: 'bi-painel',
    component: () => import('./index'),
    meta: {
      visible: true,
      title: 'BI-Painel',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Relatórios', link: 'relatorios' },
        { name: 'BI-Painel'},
      ]
    }
  }
]
