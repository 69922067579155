import storage from '@/core/service/localStorage'
import store from '@/core/store'
import {size, compact} from 'lodash'
// import http from '@/core/http'
// import Events from '@/core/service/events'

export default  async (to, from, next) => {
  const user = storage.get('auth-token')
  const AUTHTOKEN = user.auth
  storage.set('moduleName', compact(to.path.split('/'))[0])
  const idAuthStore = store.getters['auth/user'].id
  const idAcl = store.getters['roles/aclID']

  if (idAuthStore && idAuthStore !== user.id || (idAcl && user.id !== idAcl)) {
    window.location.reload()
  }
  
  if (to.path === '/home' && size(AUTHTOKEN)) {
    const result = await store.dispatch('termoConsentimento/getTermos').catch( () => next() )
    
    if(result) {
      result.data.forEach(item => {
        if (size(item.userAgreements) === 0) {
          next('/termo-consentimento')
          return 
        } 
        next()
      })
    }
    next()
  }

  switch (to.path) {
    case '/termo-consentimento': 
    if (size(AUTHTOKEN)) {
      return next()
     }

     return next('/auth')
    case '/auth':
      if(size(AUTHTOKEN)) {
        let acl = await store.dispatch('roles/getAcl')
        
        if (size(acl) > 0) return next('/home')
        
        storage.remove('auth-token')
        storage.remove('jwt')
        return next({ path: '/auth' })
        
      } else {
        return next()
      }
    default:
      if (size(AUTHTOKEN)) {
        await store.dispatch('roles/getAcl')
        next()
      } else {
        next('/auth')
      }
  }

  // SET AUTH-TOKEN NO WEBVIEW
  try {
    // eslint-disable-next-line no-undef
    $NativeApp?.setUserId(user?.jsonFlags?.responsibleForPromoterEntityId?.toString() || '')
  // eslint-disable-next-line no-empty
  } catch (_) {}
}
