import http from '@/core/http'
import { map } from 'lodash'

const url = 'historico-vendas'

const state = {
  itens: [],
  item: {
    entityId: '',
    codBilhete: '',
    nrJogo: ''  
  },
  lista: '',
  itemAddPermission: [],
  acl: null,
  itemSelect: [],
  totalItens: 0,
  transmissoes: {
    results: []
  },
  listGames: [],
  listPrizes: [],
  listGamesWinners: [],
  listCaixa: [],
  hasMonthOrDate: 1,
  listStatistics: {
    gameStatistics: []
  }
}

const actions = {
  getItens: async ({ commit }, payload) => {
    const result = await http.get(url, { params: payload })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },

  // eslint-disable-next-line
  getRelatorioGecom: async ({ commit, state }, payload) => {
    const result = await http.get(`novo-gecom/`, { params: payload })
    commit('LISTA_TRANSMISSOES', result.data.resultMap)
    // commit('PRIZES_LIST', resultPrizesGrouped)
    return Promise.resolve(result)
  },
  getRelatorioGecomGeral: async ({ commit }, payload) => {
    const result = await http.get(`novo-gecom/`, { params: payload })

    commit('LISTA_TRANSMISSOES', result.data.resultMap)
    // commit('PRIZES_LIST', resultPrizesGrouped)
    return Promise.resolve(result)
  },

  getListJogos: async ({ commit }, payload) => {
    const result = await http.get(`historico-vendas/filtro-vendas/palpites`, { params: payload }, { headers: { moduloname: 'relatorios' } })
    commit('LIST_GAMES', result.data)
    return Promise.resolve(result)
  },

  getListJogosWinners: async ({ commit }, payload) => {
    const result = await http.get(`historico-vendas/filtro-vendas/winners`, { params: payload }, { headers: { moduloname: 'relatorios' } })
    commit('LIST_GAMES_WINNERS', result.data)
    return Promise.resolve(result)
  },
  
  getEstatística: async ({ commit }, payload) => {
    const result = await http.get(`historico-vendas/estatistica`, { params: payload }, { headers: { moduloname: 'relatorios' } })
    commit('LIST_STATISTICS', result.data)
    return Promise.resolve(result)
  },

  getListCaixa: async ({ commit }, payload) => {
    const result = await http.get(`entradas`, { params: payload }, { headers: { moduloname: 'g-fin' } })
    commit('LIST_CAIXA', result.data)
    return Promise.resolve(result)
  },

  cadastrarItem: async (_, payload) => {
    const result = await http.post(url, payload)
    return Promise.resolve(result)
  },

  editarItem: async (_, payload) => {
    const result = await http.put(url, payload)
    return Promise.resolve(result)
  },

  deletarItem: async (_, payload) => {
    const result = await http.delete(url, {params: payload})
    return Promise.resolve(result)
  },

  getItem: ({ commit }, payload) => {
    commit('LISTA_ITEM', payload)
  },

  limparItem: ({ commit }) => {
    commit('LIMPAR_ITEM')
  },

  setHasMonthOrDate: ({ commit }, payload) => {
    commit('SET_HAS_MONTH_OR_DATE', payload)
  },

  clearItens: ({ commit }) => {
    commit('LIMPAR_ITEM')
  }
}

const mutations = {
  'LISTA_ITENS' (state, payload) {
    state.itens = payload.resultMap
    state.totalItens = payload.totalItens
  },

  'LISTA_TRANSMISSOES' (state, payload) {
    state.transmissoes = payload
  },

  'PRIZES_LIST' (state, payload) {
    state.listPrizes =  payload
  },

  'LIST_GAMES_WINNERS' (state, payload) {
    state.listGamesWinners =  payload.resultMap
  },

  'LISTA_ITEM' (state, payload) {
    state.item = Object.assign({}, payload)
  },

  'LIST_GAMES' (state, payload) {
    state.listGames = payload.resultMap
  },

  'LIMPAR_ITEM' (state) {
    state.item = {
      name: '',
      produtoId: '',
      product: {
        name: ''
      }
    },
    state.transmissoes = {
      results: []
    }
  },

  'LISTA_ITENS_SELECT' (state, payload) {
    state.itemSelect = payload
  },

  'SET_HAS_MONTH_OR_DATE' (state, payload) {
    state.hasMonthOrDate = payload
  },

  'LIST_CAIXA' (state, payload) {
    state.listCaixa = payload.resultMap
  },

  'LIST_STATISTICS' (state, payload) {
    state.listStatistics = payload.result
  }
}

const getters = {
  listaItens: state => state.itens,
  totalItens: (state) => state.totalItens,
  item: state => state.item,
  transmissoes: state => state.transmissoes,
  listGames: state => state.listGames,
  listPrizes: state => state.listPrizes,
  listGamesWinners: state => state.listGamesWinners,
  listCaixa: state => state.listCaixa,
  listStatistics: state => state.listStatistics,
  listaItensSelect: (state) => {
    return map(state.itens, item => {
      return {
        text: item.name,
        value: item.id
      }
    })
  }
}

export default { namespaced: true, state, actions, mutations, getters }