import http from '@/core/http'

const state = { result: [] }

const actions = {
  getDados: async ({ commit }, payload) => {
    commit('ADD_RELATORIO', [])
    const result = await http.get('/gefin/relatorios/index', {params: payload })
    commit('ADD_RELATORIO', result.data)
    return Promise.resolve(result.data.data)
  },

  deleteCategory: async (_, payload) => {
    const result = await http.delete('gefin-mais/categorias', { params: payload })
    return Promise.resolve(result.data.data)
  }
}

const mutations = {
  'ADD_RELATORIO' (state, payload) {
    state.result = payload 
  }
}

const getters = { result: state => state.result }

export default { namespaced: true, state, actions, mutations, getters }