export default [
  {
    path: 'consultas/ganhadores',
    name: 'ganhadores',
    component: () => import('./index'),
    children: [],
    meta: {
      visible: true,
      title: 'Jogos',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Consultas', link: 'consultas' },
        { name: 'Ganhadores', },
      ]
    }
  },
]