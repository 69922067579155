export default [{  
  path: 'd-fin/gefin-relatorios/relatorios-bi-revendas',
  name: 'relatorios-bi-revendas',
  component: () => import('./index'),
  meta: {
    visible: true,
    title: 'Relatório BI Revendas',
    order: '10',
    breadcrumb: [
      { name: 'Home', link: 'home' },
      { name: 'D-FIN', link: 'd-fin' },
      { name: 'Relatórios', link: 'gefin-relatorios' },
      { name: 'Relatório BI Revendas' }
    ]
  }
}]
