export default [
  {
    path: 'consultas/historico-requisicao-premio-web',
    name: 'historico-requisicao-premio-web',
    component: () => import('./index'),
    children: [],
    meta: {
      visible: true,
      title: 'Histórico de Requisição de Prêmios Web',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Consultas', link: 'consultas' },
        { name: 'Histórico de Requisição de Prêmios Web' }
      ]
    }
  },
]