// import { defaults } from 'lodash'
import axios from 'axios'
import Baseconfig from '@/core/config'
// eslint-disable-next-line no-unused-vars
import Events from '@/core/service/events'
import storage from '@/core/service/localStorage'
// eslint-disable-next-line
import store from '@/core/store'
// import router from '@/core/router'
// eslint-disable-next-line no-unused-vars
import { last, size } from 'lodash'
// eslint-disable-next-line no-unused-vars
axios.defaults.baseURL = Baseconfig.api

const CLIENTE = axios.create({})
// eslint-disable-next-line no-unused-vars
const naoEmitEvento = (val) => { // rotas para não emitir evendo loadre ou nao cancelar ele
  // eslint-disable-next-line no-unused-vars
  if (val === 'list-agencias') return false
  return true
}

CLIENTE.interceptors.request.use((config) => {
  const userStorage  = storage.get('auth-token')
  config.headers['token'] = userStorage.auth
  config.headers['jwt'] = storage.get('jwt')

  // vem do projeto php input hidden antes de iniciar a aplicação
  let user = document.getElementById('username')
  let pass = document.getElementById('password')
  
  let moduleName = String(window.location.href.replace(Baseconfig.url, '')).split('/')
  let exceptions = ['null/', 'home/', 'auth/', 'autenticacao/']
  if (size(moduleName[0]) === 0) {
    config.headers.pathDefault = config.url
  } else {
    let formatModule = moduleName[0] + '/'
    config.headers.pathDefault = String(`${exceptions.indexOf(formatModule)  === -1 ? formatModule : ''}${config.url}`).trim()
  }

  config.headers.username = user === null ? '' : user.value
  config.headers.password = pass === null ? '' : pass.value

  Events.$emit('before-request')
  return config

}, (error) => {
  Events.$emit('request-error')
  return Promise.reject(error.response.data)
})

CLIENTE.interceptors.response.use((config) => {
  // eslint-disable-next-line no-unused-vars
  // let endPoint = config.config.url.split('/')
  // if (naoEmitEvento(last(endPoint)))
  if (config.headers['refresh_token']) {
    storage.set('jwt', config.headers['refresh_token'])
  }

  Events.$emit('alter::date::server', config.data.date)
  Events.$emit('after-response')
  return config
}, (error) => {
  // Events.$emit('response-error')
  // return Promise.reject(error.response.data)
  if (!error.response) {
    // storage.remove('auth-token')
    // router.push('/auth')
    // location.reload(true)
    
    Events.$emit('snackbarCadastro::msg', {
      toggle: true,
      type: 'error',
      msg: 'Erro de conexão com o servidor!'
    })
    return
  }

  if (error.response) {
    if (error.response.status === 401 && error.response.data.error === 'token invalido') {
      storage.remove('auth-token')
      storage.remove('jwt')
      window.location.href = 'auth'
    }

    if (error.response.status === 401) {
      Events.$emit('snackbarCadastro::msg', {
        toggle: true,
        type: 'error',
        msg: `Você não tem permissão de acessa essa rota: ${error.response.config.url}`
      })
    }

    if (error.response.status === 418) {
      Events.$emit('snackbarCadastro::msg', {
        toggle: true,
        type: 'error',
        msg: `Sessão expirada!`
      })
      storage.remove('auth-token')
      storage.remove('jwt')
      window.location.href = 'auth'
    }

    if (error.response.status === 404) {
      Events.$emit('snackbarCadastro::msg', {
        toggle: true,
        type: 'error',
        msg: `Endereço não encontrado: ${error.response.config.url}`
      })
    }

    if (error.response.status === 500) {
      Events.$emit('snackbarCadastro::msg', {
        toggle: true,
        type: 'error',
        msg: 'Erro de ACL'
      })
    }

    if (
      error.response.data.code === 'ECONNREFUSED' ||
      error.response.code === 'ECONNREFUSED' ||
      error.response.data.code === 'ETIMEDOUT'
    ) {
      Events.$emit('snackbarCadastro::msg', {
        toggle: true,
        type: 'error',
        msg: 'Erro de conexão com servidor'
      })
    }

    // Events.$emit('response-error')
    return Promise.reject(error.response.data)
  }
})

export default CLIENTE
