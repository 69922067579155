export default [
  {
    path: 'gestao/terminais/instalacao',
    name: 'instalacao',
    component: () => import('./index'),
    meta: {
      visible: true,
      title: 'Instalação',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Gestão', link: 'gestao' },
        { name: 'Terminais', link: 'terminais' },
        { name: 'Instalação' }
      ]
    }
  }
]