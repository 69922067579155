import http from '@/core/http'
import { map } from 'lodash'
// import { convertMinutesToHours } from '@/core/service/utils'
// eslint-disable-next-line
import moment from 'moment'

const endPoint = 'loterias/concursos-recorrentes'

const convertWeekDaysToObj = weekDays => {
  let array = []
  if (!weekDays.length) return null

  weekDays.forEach((item, index) => {
    if (parseInt(item)) array.push(index + 1)
  })

  return array
}

const state = {
  itens: [],
  item: {
    weekDays: [],
    dtStart: '',
    startInterval: '',
    durationInterval: '',
    drawInterval: 0,
    sweepstakeQty: 1,
    automaticDraw: false,
    isInstantaneous: false,
    isIntraday: false,
    intervalExtraConfig: {
      maxSweepstakesPerDay: undefined
    },
    config: {
      image: '',
      imageCupom: '',
      imageCupomVerso: '',
      regulationSweepstakes: '',
      price: 0,
      sizeLot: '',
      dtEnd: '',
      dtDraw: '',
      cardConfig: {
        numCols: 5,
        numLines: 5,
        maxNumber: 90,
        centralNumberBonus: false,
        expectedSales: '',
        payoutPercentage: '',
      },
      accumulation: {
        maxBalls: '',
        percentage: '',
        initialValue: ''
      }
    },
  },
}

const actions = {
  addHolidaySweepstakeRecurrence: async ({ commit }, payload) => {
    const result = await http.post('/produtos/feriados/concursos', payload)
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },
  getItens: async ({ commit }, payload) => {
    const result = await http.get(endPoint, { params: payload, headers: {moduloname: 'gestao' } })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },
  cadastrarItem: async (_, payload) => {
    const result = await http.post(endPoint, payload)
    return Promise.resolve(result)
  },

  editarItem: async (_, payload) => {
    const result = await http.put(endPoint, payload)
    return Promise.resolve(result)
  },

  deletarItem: async (_, payload) => {
    const result = await http.delete(endPoint, {params: payload})
    return Promise.resolve(result)
  },
  getMethods: async ({ commit }, payload) => {
    const result = await http.get(`produtos/concursos/metodos-verificado`, payload)
    commit('LIST_METHODS', result.data)
    return Promise.resolve(result)
  },
  setItem: async ({ commit }, payload) => {
    commit('SET_ITEM', payload)
    return Promise.resolve(true)
  },

  limparItem: ({ commit }) => {
    commit('LIMPAR_ITEM')
  },

  clearItens: ({ commit }) => {
    commit('CLEAR_ITENS')
    commit('LIMPAR_ITEM')
  }
}

const mutations = {
  'LIST_METHODS' (state, payload) {
    state.methods = payload
  },
  'LISTA_ITENS' (state, payload) {
    state.itens = payload.resultMap
    state.totalItens = payload.totalItens
  },

  'SET_ITEM' (state, payload) {
    const data = Object.assign({}, payload)
    state.item = data
    state.item.weekDays = convertWeekDaysToObj(data.weekDays.split(''))
    state.item.dtStart = moment(data.dtStart).format('DD/MM/YYYY HH:mm')
    state.item.sweepstakeQty = parseInt(data.sweepstakeQty)
  },

  'LIMPAR_ITEM' (state) {
    state.item = {
      sweepstakeId: '',
      recurringInterval: '',
      weekDays: [],
      dtStart: '',
      automaticDraw: false,
      isInstantaneous: false,
      isIntraday: false,
      intervalExtraConfig: {
        maxSweepstakesPerDay: undefined
      },
      config: {
        image: '',
        imageCupom: '',
        imageCupomVerso: '',
        regulationSweepstakes: '',
        price: 0,
        sizeLot: '',
        dtEnd: '',
        dtDraw: '',
        cardConfig: {
          numCols: 5,
          numLines: 5,
          maxNumber: 90,
          centralNumberBonus: false,
          expectedSales: '',
          payoutPercentage: '',
        },
        accumulation: {
          maxBalls: '',
          percentage: '',
          initialValue: ''
        }
      },
    }
  },

  'LISTA_ITENS_SELECT' (state, payload) {
    state.itemSelect = payload
  },

  'CLEAR_ITENS' (state) {
    state.itens = []
    state.itensSelect = []
    state.totalItens = 0
  }
}

const getters = {
  listaItens: state => state.itens,
  totalItens: (state) => state.totalItens,
  item: state => state.item,
  listaItemSelectMethods: state => map(state.methods, item => {
    return {
      text: item.name,
      value: item.id
    }
  }),
  listaItensSelect: (state) => {
    return map(state.itens, item => {
      return {
        text: item.cod,
        value: item.id
      }
    })
  }
}

export default { namespaced: true, state, actions, mutations, getters }