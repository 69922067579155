import http from '@/core/http'
import { map } from 'lodash'

const url = 'entidades'

const state = {
  itens: [],
  entitiesSelect: [],
  listEntitiesDre: [],
  listEntityTypes: [],
  totalItens: 0
}

const actions = {
  addFiltroAtual: ({commit}, payload) => {
    commit('ADD_FILTRO_ENTITI_ID', payload || 6)
  },
  
  getEntities: async (_, payload) => {
    const headers = { moduloname: 'gestao' }
    const result = await http.get(url, { params: payload, headers })
    return Promise.resolve(result.data)
  },

  obterItens: async ({ commit }, payload) => {
    const headers = { moduloname: 'gestao' }
    const result = await http.get(url, { params: payload, headers })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result.data)
  },

  getListEntitiesTree: async ({ commit }, payload) => {
    const headers = { moduloname: 'gestao' }
    const result = await http.get('entidades', { params: payload, headers })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result.data.resultMap.map(item => ({
      text: `(${item.cod}) - ${item.name}`,
      value: item.id
    })))
  },

  getListEntitiesTreeGecom: async ({ commit }, payload) => {
    const headers = { moduloname: 'gestao' }
    const result = await http.get('entidades', { params: payload, headers })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result.data.resultMap.map(item => ({
      text: `(${item.cod}) - ${item.name}`,
      value: item.cod
    })))
  },

  getListTree: async ({ commit }, payload) => {
    const headers = { moduloname: 'gestao' }
    const result = await http.get('entidades', { params: payload, headers })

    function siglaEntityType (entityTypeId) {        
      switch (entityTypeId) {
        case 2:
          return 'SP'
        case 3:
          return 'PR'
        case 4:
          return 'RV'
        default:
          return 'SS'
      }
    }

    result.data.resultMap.forEach(it => {
      it.label = Array.prototype.map.call(it.parentPath, function(x) {
        return x === '.' ? '&nbsp;&nbsp;' : ''
      }).join('')
      it.labelComplet = it.label + (it.label.length > 0 ? `${siglaEntityType(it.entityTypeId)}-(${it.cod})-${it.name}` : it.name)
      it.label += it.label.length > 0 ? ` ${it.cod}` : it.cod
    })


    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result.data.resultMap.map(item => ({
      text: `(${item.cod}) - ${item.name}`,
      value: item.id
    })))
  },

  getListEntitiesTreeLancamentoFinanceiro: async ({ commit }, payload) => {
    const headers = { moduloname: 'gestao' }
    const result = await http.get('entidades/lancamento-financeiro', { params: payload, headers })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result.data.data.map(item => ({
      text: `(${item.cod}) ${item.name ? `- ` + item.name : ''}` || item.cod,
      value: item.id
    })))
  },

  getListPromotor: async ({ commit }, payload) => {
    const headers = { moduloname: 'gestao' }
    const result = await http.get('entidades/user-promotor', { params: payload, headers })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result.data.data.map(item => ({
      text: `(${item.cod}) ${item.name ? `- ` + item.name : ''}` || item.cod,
      value: item.id
    })))
  },


  getListDRE: async ({ commit }, payload) => {
    const headers = { moduloname: 'gestao' }
    const result = await http.get('entidades', { params: payload, headers })
    commit('LISTA_ENTITIES_TREE_DRE', result.data)
    return Promise.resolve(result.data.resultMap.map(item =>
    {
        return {
          text: `(${item.cod}) ${item.name ? `- ` + item.name : ''}` || item.cod,
          value: item.parentPath
        }
      }
    ))
  },

  getEntitiesTypes: async ({ commit }, payload) => {
    const headers = { moduloname: 'gestao' }
    const result = await http.get('entity-types', { params: payload, headers })
    commit('LIST_ENTITY_TYPES', result.data)
    return Promise.resolve(result.data)
  },
}

const mutations = {
  'LISTA_ITENS' (state, payload) {
    state.itens = payload.data
    if (payload.resultMap) {  
      state.itens = payload.resultMap
    }
    state.totalItens = payload.totalItens
  },
  'ADD_FILTRO_ENTITI_ID' (state, payload) {
    state.filtro = payload
  },

  'LISTA_ITENS_SELECT' (state, payload) {
    state.entitiesSelect = payload
  },

  'LISTA_ENTITIES_TREE' (state, payload) {
    state.entitiesSelect = payload
  },

  'LIST_ENTITY_TYPES' (state, payload) {
    state.listEntityTypes = payload.resultMap
  },

  'LISTA_ENTITIES_TREE_DRE' (state, payload) {
    state.listEntitiesDre = payload.resultMap
  }
}

const getters = {
  listaItens: state => state.itens,
  totalItemSelect: (state) => state.totalItens,
  listaItensSelect: (state) => {
    return map(state.itens, item => {
      return {
        text: `(${item.cod}) - ${item.name}` || item.cod,
        value: item.id
      }
    })
  },
  listaItensSelectUser: (state) => {
    return map(state.itens, item => {
      return {
        labelComplet: item.labelComplet,
        text: `(${item.cod}) - ${item.name}` || item.cod,
        value: item.id
      }
    })
  },
  entitiesSelect: state => state.entitiesSelect,
  listEntityTypes: state => state.listEntityTypes.map(item => ({ text: item.name, id: item.id })),
  listEntitiesDre: state => state.listEntitiesDre.map(item => ({ text: item.name, id: item.parentPath }))
}

export default { namespaced: true, state, actions, mutations, getters }