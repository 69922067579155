// eslint-disable-next-line no-unused-vars
import http from '@/core/http'
import { map } from 'lodash'

const url = 'ganhadores-admin-print'

const state = {
  conteudo: '',
  concursos: []
}

const actions = {
  getHtml: async ({ commit }, payload) => {
    commit('ADD_HTML', { data: '' })
    const result = await http.get(url, { params: { module: 'consultas', ...payload } })
    commit('ADD_HTML', result.data)
    return Promise.resolve(result)
  },
  requestRelatorio: async (_ , payload) => {
    const result = await http.get(`${url}/download`, { params: {...payload}  })
    if(result.status !== 200) throw new Error('Pagina não existe')
    return Promise.resolve(result)
  },
  getConcursos: async ({ commit }, payload) => {
    const headers = {
      moduloname: 'gestao',
    }
    const result = await http.get('produtos/concursos', { params: payload, headers })
    commit('LISTA_CONCURSOS', result.data)
  }
}

const mutations = {
  'ADD_HTML' (state, payload) {
    state.conteudo = payload.data
  },
  'LISTA_CONCURSOS' (state, payload) {
    state.concursos = payload.resultMap
  }
}

const getters = {
  listSelectConcursos: state => {
    const filterConcurso = state.concursos.filter(item => item.hash)
    const lista = map(filterConcurso, item => {
      return  {
        text: item.cod,
        value: item.cod
      }
    })

    lista.unshift({ text: 'Todos', value: null })

    return lista
  }
}

export default { namespaced: true, state, actions, mutations, getters }