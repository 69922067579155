export default [
  {
    path: 'gestao/loterias/registrar-recorrentes',
    name: 'registrar-recorrentes',
    component: () => import('./index'),
    meta: {
      visible: true,
      title: 'Registrar Recorrentes',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Gestão', link: 'gestao' },
        { name: 'Loterias', link: 'loterias' },
        { name: 'Registrar Recorrentes' }
      ]
    }
  }
]