import http from '@/core/http'
import { map } from 'lodash'
import moment from 'moment'

const state = {
  holidays: []
}

const actions = {
  getHolidays: async ({commit}, payload) => {
    const result = await http.get('/feriados', { params: payload })
    commit('HOLIDAYS', result.data.result)
  }
}

const mutations = {
  'HOLIDAYS' (state, payload) {
    state.holidays = payload
  }
}

const getters = {
  holidays: state => {
    return map(state.holidays, holiday => {
      return {
        id: holiday.id,
        ref_date: moment(holiday.ref_date).format('DD/MM/YYYY'),
        description: holiday.description
      } 
    })
  }
}

export default { namespaced: true, state, actions, mutations, getters }