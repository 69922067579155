export default [{
  path: 'd-fin/gefin-relatorios/relatorios-categorias',
  name: 'relatorios-categorias',
  component: () => import('./index'),
  meta: {
    visible: true,
    title: 'Relatório Categorias',
    order: '4',
    breadcrumb: [
      { name: 'Home', link: 'home' },
      { name: 'D-FIN', link: 'd-fin' },
      { name: 'Relatórios', link: 'gefin-relatorios' },
      { name: 'Relatórios Categorias' }
    ]
  }
}]
