export default [{
  path: 'd-fin/gefin-mais/categorias',
  name: 'categorias',
  component: () => import('./index'),
  meta: {
    visible: true,
    title: 'Categorias',
    order: '02',
    breadcrumb: [
      { name: 'Home', link: 'home' },
      { name: 'GEFIN', link: 'gefin' },
      { name: 'Mais', link: 'gefin-mais' },
      { name: 'Categorias' }
    ]
  }
}]
