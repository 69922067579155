import http from '@/core/http'

const state = {
  date: ''
}

const mutations = {
  SET_DATE: (state, date) => {
    state.date = date
  }
}

const actions = {
  setDate: ({ commit }, date)  => {
    commit('SET_DATE', date)
  },
  
  getDateServer: async ({ commit }) => {
    const result = await http.get('date')
    commit('SET_DATE', result.data.data.now)
  }
}

const getters = {
  getDate: state => state.date
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
