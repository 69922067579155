import http from '@/core/http'
// eslint-disable-next-line
import { map } from 'lodash'

const state = {
  data: [],
  listMemos: [],
  totalItens: 0
}

const actions = {
  getData: async ({ commit }, payload) => {
    const result = await http.get('crm', { params: payload })
    commit('SET_DATA', result.data)
    return Promise.resolve(result)
  },

  changeStatus : async (_, payload) => {
    const result = await http.put('crm', payload)
    return Promise.resolve(result)
  },

  salvarMemo : async (_, payload) => {
    const result = await http.post('crm', payload)
    return Promise.resolve(result)
  },

  getMemosByEntity : async ({ commit }, payload) => {
    const result = await http.get('crm/memos', { params: payload })
    commit('ADD_ITEMS_MEMOS', result.data)
    return Promise.resolve(result)
  },

  getItem: ({ commit }, payload) => {
    commit('LISTA_ITEM', payload)
  },

  limparItem: ({ commit }) => {
    commit('LIMPAR_ITEM')
  },

  setHasMonthOrDate: ({ commit }, payload) => {
    commit('SET_HAS_MONTH_OR_DATE', payload)
  }
}

const mutations = {
  'SET_DATA' (state, payload) {
    console.log('payload', payload)
    state.data = payload.results
    state.totalItens = payload.totalItens
  },

  'ADD_ITEMS_MEMOS' (state, payload) {
    state.listMemos = payload.results
  }
}

const getters = {
  listaItens: state => state.data,
  listMemos: state => state.listMemos,
  totalItens: (state) => state.totalItens,
  transmissoes: state => state.transmissoes,
}

export default { namespaced: true, state, actions, mutations, getters }