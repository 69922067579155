export default [
    {
      path: 'd-fin/gefin-relatorios',
      name: 'gefin-relatorios',
      component: () => import('./index'),
      children: [],
      meta: {
        visible: false,
        title: 'Relatórios',
        order: '4',
        breadcrumb: [
          { name: 'Home', link: 'home' },
          { name: 'D-FIN', link: 'd-fin' },
          { name: 'Relatórios' }
        ]
      }
    },
  ]