export function phone(val) {
  if (!val) return

  const numberArray = val.split(' ')
  
  if (numberArray.length === 3) {
    if (numberArray[2].length === 9) {
      return numberArray[2].replace(/(\d{5})(\d{4})/, (regex, arg1, arg2) => `(${numberArray[1]}) ${arg1} - ${arg2}`)
    } else {
      return numberArray[2].replace(/(\d{4})(\d{4})/, (regex, arg1, arg2) => `(${numberArray[1]}) ${arg1} - ${arg2}`)
    }
  }

  const isCelular = val.length === 11;
  if (isCelular) {
    return val.replace(/(\d{2})(\d{5})(\d{4})/, (regex, arg1, arg2, arg3) => `(${arg1}) ${arg2} - ${arg3}`)
  }

  if (!isCelular) {
    return val.replace(/(\d{2})(\d{4})(\d{4})/, (regex, arg1, arg2, arg3) => `(${arg1}) ${arg2} - ${arg3}`)
  }
}

export function phone2(val) {
  if (!val) return
  const number = val.substr(2)
  const isCelular = number.length === 11;
  if (isCelular) {
    return number.replace(/(\d{2})(\d{5})(\d{4})/, (regex, arg1, arg2, arg3) => `(${arg1}) ${arg2} - ${arg3}`)
  }

  if (!isCelular) {
    return number.replace(/(\d{2})(\d{4})(\d{4})/, (regex, arg1, arg2, arg3) => `(${arg1}) ${arg2} - ${arg3}`)
  }
  // return `(${val.substr(2, 2)}) ${val.substr(4)}`
}