export default [
  {
    path: 'g-fin/lancamento-financeiro',
    name: 'lancamento-financeiro',
    component: () => import('./index'),
    meta: {
      visible: true,
      title: 'Lançamento Financeiro',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Financeiro', link: 'financeiro' },
        { name: 'Lançamento Financeiro' }
      ]
    }
  }
]