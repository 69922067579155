export default [
  {
    path: 'gestao/terminais/chips',
    name: 'chips',
    component: () => import('./index'),
    meta: {
      visible: true,
      title: 'Chips',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Gestão', link: 'gestao' },
        { name: 'Terminais', link: 'terminais' },
        { name: 'Chips' }
      ]
    }
  }
]