import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import VueI18n from 'vue-i18n'
import variables from '../../assets/styles/helpers/_variables.scss';

import pt from 'vuetify/es5/locale/pt'
import en from 'vuetify/es5/locale/en'

Vue.use(Vuetify);
Vue.use(VueI18n)

const messages = {
  en: {
    $vuetify: {
      ...en
    },
  },
  pt: {
    $vuetify: {
      ...pt
    }
  }
}

// console.log(messages)

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'pt', // set locale
  messages, // set locale messages
})

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    themes: { 
      light: {
        primary: variables.colorPrimary,
        secondary: variables.colorSecondary,
        accent: variables.colorAccent,
        error: variables.colorError,
        // error: variables.colorError,
        info: variables.colorInfo,
        success: variables.colorSuccess,
        warning: variables.colorWarning,
      },
      dark: {
        primary: '#00F',
      }
    }
  }
});
