export default {       
  path: '',       
     
  // /** LOCAL */     
  // url: 'http://localhost:8081/', // LOCAL     
  // api: 'http://localhost:3003/', // LOCAL     
  // urlAWS: 'https://s3.amazonaws.com/s3-dev-images-public',     
     
  /** DEV */     
  // url: 'https://devgestao.lotounica.com.br/',    // DEV     
  // api: 'https://wsg-dev.lotounica.com.br/',    // DEV     
  // urlAWS: 'https://s3.amazonaws.com/s3-dev-images-public',     
       
  /** QA */     
  // url: 'https://qagestao.lotounica.com.br/',    // QA  
  // api: 'https://wsg-qa.lotounica.com.br',    // QA  
  // urlAWS: 'https://s3.amazonaws.com/s3-dev-images-public',     
     
     
  /** ALFA */     
  // url: 'https://alphag2.sounica.com/',   // ALPHA       
  // api: 'https://wsa-gestao.sounica.com/',    // ALPHA        
  // urlAWS: 'https://s3-prd-images-pub.s3.us-west-2.amazonaws.com',  
         
  /** GEFIN */     
  // url: 'https://gefin.lotounica.com.br/',   // GEFIN  
  // api: 'https://wsgefin.lotounica.com.br/',    // GEFIN      
  // urlAWS: 'https://s3.amazonaws.com/s3-dev-images-public',       
     
     
  /** PRD */     
  // url: 'https://g2.sounica.com/',   // PRD  
  // api: 'https://api.sounica.com/',    // PRD  
  // urlAWS: 'https://s3-prd-images-pub.s3.us-west-2.amazonaws.com',     
  
  // /** BARRA */     
  url: 'https://gestao.plataforma.site/',   // PRD  
  api: 'https://8bnwbd74n1.execute-api.us-east-1.amazonaws.com/', // PRD  
  urlAWS: 'https://s3-prd-images-pub.s3.us-west-2.amazonaws.com',  
  
  /** DESCARGA */  
  // url: 'https://descarga.plataforma.site/',   // PRD  
  // api: 'https://fodhs22gdi.execute-api.us-east-1.amazonaws.com/', // PRD    
  // // api: 'http://localhost:3003/',    // LOCAL      
  // urlAWS: 'https://s3-prd-images-pub.s3.us-west-2.amazonaws.com',  
     
     
  keyPair: 'BKnGtB4hjk8kD1nqUfAxOnHML4HGytEhwQQUfIXIMtVAlBQiNAt70n3TIzlipW_Sni4XrzCWjIOv-pAE28ecvLs',         
  apiViaCep: 'https://viacep.com.br/ws/',             
  apiBankList: 'https://brasilapi.com.br/api/banks/v1',             
}       
