export default [
  {
    path: 'gestao/entidades/usuarios-web',
    name: 'usuarios-web',
    component: () => import('./index'),
    children: [],
    meta: {
      visible: true,
      title: 'Usuários',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Gestão', link: 'gestao' },
        { name: 'Rede', link: 'entidades' },
        { name: 'Usuários Web', },
      ]
    }
  },
]