export default [
  {
    path: 'relatorios/premio-pago-central',
    name: 'premio-pago-central',
    component: () => import('./index'),
    children: [],
    meta: {
      visible: true,
      title: 'Prêmio Pago na Central',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Relatórios', link: 'relatorios' },
        { name: 'Prêmio Pago na Central' }
      ]
    }
  },
]