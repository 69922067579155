export const nomeUrl = (val, tipo) => { // trata urls para que quando for iniciar uma busca por nome busque pelo nome certo
  if (tipo === 'n') { // normal
    if (String(val).toLowerCase() === 'revendedor') return 'Revendedor'
    if (String(val).toLowerCase() === 'supervisor') return 'Regional'
    if (String(val).toLowerCase() === 'promotor') return 'Promotor'
  }
  if (tipo === 'p') { // plural
    if (String(val).toLowerCase() === 'revendedor') return 'Revendedores'
    if (String(val).toLowerCase() === 'supervisor') return 'Regional'
    if (String(val).toLowerCase() === 'promotor') return 'Promotores'
    if (String(val).toLowerCase() === 'extrato') return 'Extrato'
  }
  if (tipo === 'nivel') { // plural
    if (String(val).toLowerCase() === 'vendedor') return 'Promotores'
    if (String(val).toLowerCase() === 'administrador') return 'Supervisor'
    if (String(val).toLowerCase() === 'supervisor') return 'Supervisores'
  }
  return val
}
