import http from '@/core/http'
import { size } from 'lodash'

const endPoint = 'tipos-entradas'

const state = {
  itens: [],
  item: {
    type: '',
    module: '',
    description: '',
  },
  lista: '',
  itemAddPermission: [],
  acl: null,
  itemSelect: [],
  totalItens: 0,
  itemsPaths: []
}

const actions = {
  getItens: async ({ commit }, payload) => {
    const result = await http.get(endPoint, { params: payload })
    if (result.data.resultsEntryTypesPaths) {
      commit('LISTA_ITENS_PATHS', result.data.resultsEntryTypesPaths)
    } else {
      commit('LISTA_ITENS', result.data)
    }

    return Promise.resolve(result)
  },

  cadastrarItem: async (_, payload) => {
    const result = await http.post(endPoint, payload)
    return Promise.resolve(result)
  },

  editarItem: async (_, payload) => {
    const result = await http.put(endPoint, payload)
    return Promise.resolve(result)
  },

  deletarItem: async (_, payload) => {
    const result = await http.delete(endPoint, {params: payload})
    return Promise.resolve(result)
  },

  getItem: ({ commit }, payload) => {
    commit('LISTA_ITEM', payload)
  },

  limparItem: ({ commit }) => {
    commit('LIMPAR_ITEM')
  }
}

const mutations = {
  'LISTA_ITENS' (state, payload) {
    state.itens = payload.resultMap
    state.totalItens = payload.totalItens
  },

  'LISTA_ITEM' (state, payload) {
    state.item = Object.assign({}, payload)
  },

  'LIMPAR_ITEM' (state) {
    state.item = {
      type: '',
      module: '',
      description: '',
    }
  },

  'LISTA_ITENS_SELECT' (state, payload) {
    state.itemSelect = payload
  },

  'LISTA_ITENS_PATHS' (state, payload) {
    state.itemsPaths = payload
  }
}

const getters = {
  listaItens: state => state.itens,
  listaPaths: state => {
    let dados = {}
    if (!size(state.itemsPaths)) return {}
    dados = [...state.itemsPaths.CC, ...state.itemsPaths.CD, ...state.itemsPaths.PG]
    return dados
  },
  totalItens: (state) => state.totalItens,
  item: state => state.item,
  listaItensSelect: state => state.itens.map(item => ({
    text: item.description,
    value: item.id
  })) ,

  listaItensSelectPaths: state => {
    if (size(state.itemsPaths)) {
      if (size(state.itemsPaths.PG)) {
        let PG = state.itemsPaths.PG ? state.itemsPaths.PG.filter(it => it.type).map(item => ({
          text: item.description,
          value: item.id,
          type: item.type
        })) : []

        let arrayRetorno = []
        arrayRetorno.push(...PG)

      return arrayRetorno
      } else {
        let cc = state.itemsPaths.CC ? state.itemsPaths.CC.filter(it => it.type).map(item => ({
          text: item.description,
          value: item.id,
          type: item.type
        })) : []
      
        let cd = state.itemsPaths.CD ? state.itemsPaths.CD.filter(it => it.type).map(item => ({
          text: item.description,
          value: item.id,
          type: item.type
        })) : []
    
  
        let arrayRetorno = []
        arrayRetorno.push({'header': 'Crédito'})
        arrayRetorno.push(...cc)
        arrayRetorno.push({'header': 'Débito'})
        arrayRetorno.push(...cd)
  
        return arrayRetorno
      }

    }

    return []
  }
}

export default { namespaced: true, state, actions, mutations, getters }