export default [
  {
    path: '/termo-consentimento',
    name: 'TermoConsentimento',
    component: () => import('./index'),
    children: [],
    meta: {
      visible: true,
      title: 'TermoConsentimento'
    }
  },
]