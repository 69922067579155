import http from '@/core/http'
import { filter } from 'lodash'

const state = {
  contactTypes: [],
  contacts: [],
  caixas: {
    entities: []
  }
}

const actions = {
  getContactTypes: async ({ commit }) => {
    const result = await http.get('gecom-promotores')
    commit('GET_CONTACT_TYPES', result.data.data[0])
    return Promise.resolve(result.data.data[0])
  },

  getCaixas: async ({ commit }) => {
    const result = await http.get('gecom-promotores')
    commit('SET_CAIXAS', result.data.data)
    return Promise.resolve(result.data.data)
  },

  submit: async (_, payload) => {
    let result =  {}
    if (payload.isEdit) result = await http.put('gefin-contatos', payload.data)
    else result = await http.post('gefin-contatos', payload.data)
    return Promise.resolve(result.data.data[0])
  },

  getContacts: async ({ commit }) => {
    const result = await http.get('gefin-contatos')
    commit('GET_CONTACTS', result.data.data[0])
    return Promise.resolve(result.data.data[0])
  },

  deleteContact: async (_, payload) => {
    const result = await http.delete('gefin-contatos', { params: payload })
    return Promise.resolve(result.data.data)
  }
}

const mutations = {
  'GET_CONTACT_TYPES' (state, payload) { state.contactTypes = payload },
  'GET_CONTACTS' (state, payload) { state.contacts = payload },
  'SET_CAIXAS' (state, payload) { state.caixas = payload }
 }

const getters = {
  contactTypes: state => state.contactTypes,
  allContacts: state => state.contacts,
  customers: state => filter(state.contacts, item => item.contactTypeId === 1),
  providers: state => filter(state.contacts, item => item.contactTypeId === 2),
  others: state => filter(state.contacts, item => item.contactTypeId === 3),
  caixas: state => state.caixas
}

export default { namespaced: true, state, actions, mutations, getters }