// eslint-disable-next-line no-unused-vars
import http from '@/core/http'

const state = {
  url: ''
}

const actions = {
  async getUrlKibana ({commit}) {
    const result = await http.get('home', { params: { name: 'KIBANA_CONFIG'} })
    commit('ADD_URL_KIBANA', result.data.urlKibana)
    return Promise.resolve(result)
  },
}

const mutations = {
  'ADD_URL_KIBANA' (state, payload) {
    state.url = payload
  }
}

const getters = {
  urlkibana: state => state.url
}

export default { namespaced: true, state, actions, mutations, getters }