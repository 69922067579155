export default [
    {
      path: 'relatorios/relatorios-instalacoes',
      name: 'relatorios-instalacoes',
      component: () => import('./index'),
      meta: {
        visible: true,
        title: 'Arrecadação',
        breadcrumb: [
          { name: 'Home', link: 'home' },
          { name: 'Relatórios', link: 'relatorios' },
          { name: 'Instalações'},
        ]
      }
    }
  ]