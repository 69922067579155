// eslint-disable-next-line no-unused-vars
import http from '@/core/http'

const url = 'comissoes-promotores'

const state = {
  conteudo: ''
}

const actions = {
  getHtml: async ({ commit }, payload) => {
    commit('ADD_HTML', { data: '' })
    const result = await http.get(url, { params: { module: 'consultas', ...payload } })
    commit('ADD_HTML', result.data)
    return Promise.resolve(result)
  },
  requestRelatorio: async (_ , payload) => {
    const result = await http.get(`${url}/download`, { params: {...payload}  })
    if(result.status !== 200) throw new Error('Pagina não existe')
    return Promise.resolve(result)
  }
}

const mutations = {
  'ADD_HTML' (state, payload) {
    state.conteudo = payload.data
  }
}

export default { namespaced: true, state, actions, mutations }