export default [
  {
    path: 'relatorios/historico-vendas',
    name: 'historico-vendas',
    component: () => import('./index'),
    children: [],
    meta: {
      visible: true,
      title: 'Histórico de Vendas',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Relatórios', link: 'relatorios' },
        { name: 'Histórico de Vendas', },
      ]
    }
  },
]