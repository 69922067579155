export default [
  {
    path: 'gestao/tipos-indices',
    name: 'tipos-indices',
    component: () => import('./index'),
    meta: {
      visible: false,
      title: 'Tipos de índices',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Gestão', link: 'gestao' },
        { name: 'Tipos de Índices' }
      ]
    }
  }
]