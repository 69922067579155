export default [
  {
    path: 'relatorios/gecom',
    name: 'gecom',
    component: () => import('./index'),
    children: [],
    meta: {
      visible: true,
      title: 'Gecom',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'Relatórios', link: 'relatorios' },
        { name: 'Gecom' },
      ]
    }
  },
]