import http from '@/core/http'
import { map } from 'lodash'

const url = 'jogos'

const state = {
  itens: [],
  item: {
    entityId: '',
    codBilhete: '',
    nrJogo: ''
  },
  lista: '',
  itemAddPermission: [],
  acl: null,
  itemSelect: [],
  totalItens: 0
}

const actions = {
  getItens: async ({ commit }, payload) => {
    const result = await http.get(url, { params: payload })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },

  cadastrarItem: async (_, payload) => {
    const result = await http.post(url, payload)
    return Promise.resolve(result)
  },

  editarItem: async (_, payload) => {
    const result = await http.put(url, payload)
    return Promise.resolve(result)
  },

  deletarItem: async (_, payload) => {
    const result = await http.delete(url, {headers: { moduloname: 'gestao' }, params: payload})
    return Promise.resolve(result)
  },

  getBilhete: async (_, payload) => {
    const result = await http.get('jogos/bilhete/', {headers: { moduloname: 'gestao' }, params: payload})
    return Promise.resolve(result)
  },

  getItem: ({ commit }, payload) => {
    commit('LISTA_ITEM', payload)
  },
  limparItem: ({ commit }) => {
    commit('LIMPAR_ITEM')
  }
}

const mutations = {
  'LISTA_ITENS' (state, payload) {
    state.itens = payload.resultMap
    state.totalItens = payload.totalItens
  },

  'LISTA_ITEM' (state, payload) {
    state.item = Object.assign({}, payload)
  },

  'LIMPAR_ITEM' (state) {
    state.item = {
      entityId: '',
      codBilhete: '',
      nrJogo: ''
    }
  },

  'LISTA_ITENS_SELECT' (state, payload) {
    state.itemSelect = payload
  }
}

const getters = {
  listaItens: state => state.itens,
  totalItens: (state) => state.totalItens,
  item: state => state.item,
  listaItensSelect: (state) => {
    return map(state.itens, item => {
      return {
        text: item.name,
        value: item.id
      }
    })
  }
}

export default { namespaced: true, state, actions, mutations, getters }