import Vue from 'vue'
import numeral from 'numeral'
// import { size } from 'lodash'

// seta configuracoes globais

export function number (val) {
  const locale = String(Vue.config.language).replace(`_`, `-`).toLowerCase()
  numeral.locale(locale)
  return  numeral(String(val).replace('.', ',')).format('0[,]00') === '0' ? '-' : numeral(String(val).replace('.', ',')).format('0[,]00')
  // let format = String(value).split(',')
  // if (parseInt(format[1]) === 0) return value
  // return format[0] + ',' + String(format[1]).substr(0, 2)
}