import { initializeApp } from 'firebase/app'
import { getMessaging } from 'firebase/messaging'

const config = {
  apiKey: 'AIzaSyC3LBOdqL9nba9QTxBS4bF7tblHViM2U8Q',
  authDomain: 'look-7ce30.firebaseapp.com',
  projectId: 'look-7ce30',
  storageBucket: 'look-7ce30.appspot.com',
  messagingSenderId: '1042087557141',
  appId: '1:1042087557141:web:de4ebe0d425cba34c0f61c',
  measurementId: 'G-VM1PJZLRTJ'
}
const firebase = initializeApp(config)

export const messaging = getMessaging(firebase)
