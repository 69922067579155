// eslint-disable-next-line no-unused-vars
import http from '@/core/http'
import router from '@/core/router'
import storage from '@/core/service/localStorage'
// eslint-disable-next-line no-unused-vars
import { groupBy, map, find, size, compact, filter } from 'lodash'

const state = {
  itens: [],
  item: {
    id: '',
    name: '',
    date: '',
    parentId: ''
  },
  lista: '',
  itemAddPermission: [],
  acl: null,
  aclId: null,
  totalItens: 0,
  totalItensRoles: 0
}

const actions = {
  permiteCategoria: async ({ getters }, payload) => { // permite acesso as categorias baseado na rota
    // console.log('permiteCategoria', payload, acl)
    let acl = getters.listAcl
    if (size(acl) === 0) return Promise.resolve(result)
    let result = acl[payload] !== undefined ? true : false
    if (!result) throw new Error ('Não permitido')
    return Promise.resolve(result)
  },
  permiteRota: async ({ getters }, payload) => {
    // console.log('veio do middleware rota permiteRota', payload)
    let acl = getters.listAcl[payload.module]
    if (acl === undefined) throw new Error ('Error ao tentar acessar modulo')
    let find = filter(acl, e => e.module === payload.rota)[0]
    if (find  === undefined) throw new Error('Rota não encontrada')
    if (find.allowed === false) throw new Error('Acesso negado a rota')
    return Promise.resolve(find.allowed)
  },
  cadastrarItem: async (_, payload) => {
    const result = await http.post('/autenticacao/roles', payload)
    // if (!result.data.success) throw result
    return Promise.resolve(result)
    
  },

  getItens: async ({ commit }, payload) => {
    const result = await http.get('/autenticacao/roles', { params: payload })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },

  editarItem: async (_, payload) => {
    const result = await http.put('/autenticacao/roles', payload)
    return Promise.resolve(result)
  },

  deletarItem: async (_, payload) => {
    const result = await http.delete('/autenticacao/roles', {params: payload})
    return Promise.resolve(result.data)
  },

  buscarItem: async ({ commit }, payload) => {
    const result = await http.get('/autenticacao/roles/view', payload)
    commit('BUSCAR_ITEM', result.data)
  },

  buscarGrupo: async ({ commit }, payload) => {
    const result = await http.post('/autenticacao/roles/show', payload, {headers: { moduleName: 'cadastro' } })
    commit('LISTA_ITEM', result.data)
  },

  getItem: ({ commit }, payload) => {
    commit('LISTA_ITEM', payload)
  },

  addPermission: ({ commit }, payload) => {
    commit('ITEM_ADD_PERMISSION', payload)
    router.push('/cadastros/permissoes')
  },

  getAcl: async ({ commit, state }, payload) => {
    if (state.acl) {
      return Promise.resolve({ existe: true })
    }

    const userStorage  = storage.get('auth-token')
    commit('ID_ALC_ADD', userStorage.id)
    const result = await http.get('autenticacao/usuarios/acl', payload)
    commit('ALC_ADD', result.data.data)
    return Promise.resolve(result.data.data)
  },

  clearAcl ({ commit }) {
    commit('CLEAR_ACL')
  },

  limparItem: ({ commit }) => {
    commit('LIMPAR_ITEM')
  },

  filterItens: async({ commit }, payload) => {
    const result = await http.get('/autenticacao/roles', { params: payload })
    commit('LISTA_ITENS', result.data)
  },

  clearItens: ({ commit }) => {
    commit('CLEAR_ITENS')
    commit('LIMPAR_ITEM')
  },
}

const mutations = {
  'LISTA_ITENS' (state, payload) {
    state.itens = payload.resultMap
    state.totalItens = payload.totalItens
  },

  'LISTA_ITEM' (state, payload) {
    state.item = Object.assign({}, payload)
  },

  'LIMPAR_ITEM' (state) {
    state.item = {
      id: '',
      name: '',
      date: '',
      parentId: ''
    }
  },

  'BUSCAR_ITEM' (state, paylod) {
    state.lista = paylod.itens
    state.totalItensRoles = paylod.totalItens
  },

  'ITEM_ADD_PERMISSION' (state, payload) {
    state.itemAddPermission = payload
  },
  'ALC_ADD' (state, paylod) {
    state.acl = paylod
  },

  'CLEAR_ACL' (state) {
    state.acl = null
  },

  'CLEAR_ITENS' (state) {
    state.itens = []
    state.totalItens = 0
  },

  'ID_ALC_ADD' (state, payload) {
    state.aclId = payload
  }
}

const getters = {
  aclID: state => state.aclId,
  acl: state => state.acl,
  listaItens: state => state.itens,
  totalItens: (state) => state.totalItens,
  listaItensSelect: (state) => {
    let data = map(state.itens, item => {
      return {
        text: item.name,
        value: item.id
      }
    })

    data.unshift({ text: 'Sem grupo', value: null })

    return data
  },
  item: state => state.item,
  listAcl: state => { // agrupa pelo nome do grupo
    if(state.acl === null) return []
    let group = groupBy(state.acl, val => val.group)
    return group
  },
  moduloName: () => (rota) => {
    // console.log(rota)
    let name = String(rota).split('.')
    return name[0]
  },
  // verifica ações de cada modulo e grupo
  permiteAcao: (state, getters) => (route, action = '') => {
    let name = ''
    let rota = ''
    let grupo = getters.listAcl
    if (size(grupo) === 0) return null
    name = compact(String(route.path).split('/'))
    rota = compact(map(name, (v, k) => {
      if (k > 0) return v
    })).join('/')

    if (name[1]) {
      rota = `/${rota}/${action}`
    }
    // console.log(rota)
    let result = find(grupo[name[0]], e => e.module === rota)
    if (size(result) === 0) return false
    return result.allowed
  }
}

export default { namespaced: true, state, actions, mutations, getters }