import http from '@/core/http'
import { map } from 'lodash'

const url = 'entidades/promotor'
const URL_SUPERVISOR = 'entidades/supervisor'

const state = {
  itens: [],
  item:  {
    id: '',
    cod: '',
    name: '',
    email: '',
    cnpj: '',
    document: '',
    address: '',
    cep: '',
    phone: '',
    city: '',
    state: '',
    neighborhood: '',
    number: '',
    complement: '',
    responsible: {
      name: '',
      email: '',
      cpf: '',
      phone: '',
      phoneComercial: '',
      cep: '',
      address: '',
      state: '',
      city: '',
      neighborhood: '',
      number: '',
      complement: '',
    },
    jsonFlags: {
      isInterior: false,
      isResetBalance: false
    }
  },
  lista: '',
  // itemAddPermission: [],
  // acl: null,
  itemSelect: [],
  totalItens: 0,
  totalItemSelect: 0
}

const actions = {
  getItens: async ({ commit }, payload) => {
    const headers = { moduloname: 'gestao' }
    const result = await http.get(`${url}`, { params: payload, headers })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },

  cadastrarItem: async (_, payload) => {
    const result = await http.post(`${url}`, payload)
    // if (!result.data.success) throw result
    return Promise.resolve(result)
  },

  editarItem: async (_, payload) => {
    const result = await http.put(`${url}`, payload)
    if (!result.data.id) throw result
    return Promise.resolve(result)
  },

  deletarItem: async (_, payload) => {
    const result = await http.delete(`${url}`, {params: payload })
    // if (!result.data.success) throw result
    return Promise.resolve(result)
  },

  buscarItem: async ({ commit }, payload) => {
    const result = await http.get(`${url}/view`, payload)
    commit('LISTA_ITEM', result.data)
  },

  getItem: ({ commit }, payload) => {
    commit('LISTA_ITEM', payload)
  },

  limparItem: ({ commit }) => {
    commit('LIMPAR_ITEM')
  },

  clearItens: ({ commit }) => {
    commit('CLEAR_ITENS')
    commit('LIMPAR_ITEM')
  },

  obterItensSelect: async ({ commit }, payload) => {
    const result = await http.get(`${URL_SUPERVISOR}`, { params: payload  })
    commit('LISTA_ITENS_SELECT', result.data)
  },
}

const mutations = {
  'LISTA_ITENS' (state, payload) {
    state.itens = payload.resultMap
    state.totalItens = payload.totalItens
  },

  'LISTA_ITEM' (state, payload) {
    state.item = Object.assign({}, payload)
  },

  'LIMPAR_ITEM' (state) {
    state.item = {
      id: '',
      cod: '',
      name: '',
      cnpj: '',
      phone: '',
      cep: '',
      address: '',
      city: '',
      state: '',
      neighborhood: '',
      number: '',
      complement: '',
      responsible: {
        name: '',
        cpf: '',
        phone: '',
        phoneComercial: '',
        cep: '',
        address: '',
        state: '',
        city: '',
        neighborhood: '',
        number: '',
        complement: '',
      },
      jsonFlags: {
        isInterior: false,
        isResetBalance: false
      }
    }
  },

  'LISTA_ITENS_SELECT' (state, payload) {
    state.itemSelect = payload.resultMap
    state.totalItemSelect = payload.totalItens
  },

  'CLEAR_ITENS' (state) {
    state.itens = []
    state.itensSelect = []
    state.totalItens = 0
  }
}

const getters = {
  listaItens: state => state.itens,
  totalItens: (state) => state.totalItens,
  totalItemSelect: (state) => state.totalItemSelect,
  item: state => state.item,
  listaItensSelect: (state) => {
    return map(state.itemSelect, item => {
      return {
        text: `${item.cod} - ${item.name}`,
        value: item.id
      }
    })
  }
}

export default { namespaced: true, state, actions, mutations, getters }