export default [{
    path: 'd-fin/gefin-relatorios/relatorios-contas',
    name: 'relatorios-contas',
    component: () => import('./index'),
    meta: {
      visible: true,
      title: 'Relatórios Contas',
      order: '3',
      breadcrumb: [
        { name: 'Home', link: 'home' },
        { name: 'D-FIN', link: 'd-fin' },
        { name: 'Relatorios', link: 'gefin-relatorios' },
        { name: 'Relatórios Contas' }
      ]
    }
  }]
