export default [{  
  path: 'esportes/bi-sport-bet',
  name: 'bi-sport-bet',
  component: () => import('./index'),
  meta: {
    visible: true,
    title: 'Relatório SportBet - BI',
    order: '15',
    breadcrumb: [
      { name: 'Home', link: 'home' },
      { name: 'Esportes', link: 'esportes' },
      { name: 'Relatório SportBet - BI' }
    ]
  }
}]