import http from '@/core/http'
import { map } from 'lodash'

const url = 'calculations/comission-osc'

const state = {
  items: [],
  item:  {
    value: '',
    entities:[
      { entity: '' }
    ]
  },
  lista: '',
  itemAddPermission: [],
  itemSelect: [],
  totalItens: 0,
  totalItemSelect: 0,
  listCalculationType: []
}

const actions = {
  getItens: async ({ commit }, payload) => {
    const result = await http.get(`${url}/view`, { params: payload })
    delete result.data.date
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },

  getListCalculationTypes: async ({ commit }, payload) => {
    const result = await http.get(`${url}/list-calculation-types`, { params: payload })
    delete result.data.date
    commit('LISTA_CALCULATION_TYPES', result.data)
    return Promise.resolve(result)
  },

  cadastrarItem: async (_, payload) => {
    const result = await http.post(url, payload)
    return Promise.resolve(result)
  },

  editarItem: async (_, payload) => {
    const result = await http.put(url, payload)
    return Promise.resolve(result)
  },

  deletarItem: async (_, payload) => {
    const result = await http.delete(url, { params: payload })
    return Promise.resolve(result.data)
  },

  getItem: async ({ commit }, payload) => {
    const result = await http.get(`${url}/view`, { params: payload })
    commit('LISTA_ITEM', result.data)
    return Promise.resolve(result)
  },

  limparItem: ({ commit }) => {
    commit('LIMPAR_ITEM')
  },

  clearItens: ({ commit }) => {
    commit('CLEAR_ITENS')
    commit('LIMPAR_ITEM')
  }
}

const mutations = {
  'LISTA_ITENS' (state, payload) {
    state.items = payload
    state.totalItens = payload.length - 1
  },

  'LISTA_CALCULATION_TYPES' (state, payload) {
    state.listCalculationType = payload
  },

  'LISTA_ITEM' (state, payload) {
    state.item = Object.assign({}, payload)
  },

  'LIMPAR_ITEM' (state) {
    state.item = {
      value: ''
    }
  },

  'LISTA_ITENS_SELECT' (state, payload) {
    state.itemSelect = payload.resultMap
    state.totalItemSelect = payload.totalItens
  },

  'CLEAR_ITENS' (state) {
    state.itens = []
    state.totalItens = 0
    state.itemSelect = []
  }
}

const getters = {
  items: state => map(state.items, item => item),
  listaItens: state => state.itens,
  totalItens: (state) => state.totalItens,
  totalItemSelect: (state) => state.totalItemSelect,
  item: state => state.item,
  listaItensSelect: (state) => {
    return map(state.itemSelect, item => {
      return {
        text: item.name,
        value: item.id
      }
    })
  },
  listCalculationType: state => map(state.listCalculationType, item => ({ text: item.name, value: item.calculationTypeId })),
}

export default { namespaced: true, state, actions, mutations, getters }