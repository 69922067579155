export function documentWithType(val, type = 'cpf') {
  switch (type) {
    case 'cpf':
      // eslint-disable-next-line no-useless-escape
      if (val) return String(val).replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g,"\$1.\$2.\$3\-\$4")
      return 
      case 'cnpj':
      // eslint-disable-next-line no-useless-escape
      return String(val).replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"\$1.\$2.\$3\/\$4\-\$5")
  }
}

export function document(val) {
  const qtdNumeros = String(val).length
  switch (qtdNumeros) {
    case 11:
      // eslint-disable-next-line no-useless-escape
      if (val) return String(val).replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g,"\$1.\$2.\$3\-\$4")
      return 
      case 14:
      // eslint-disable-next-line no-useless-escape
      return String(val).replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"\$1.\$2.\$3\/\$4\-\$5")
  }
}