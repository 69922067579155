const state = {
  objFilter: {},
}

const actions = {
  setObjFilter: ({ commit }, payload) => {
    commit('SET_OBJ_FILTER', payload)
  },

  clearObjFilter: ({ commit }, payload) => {
    commit('CLEAR_OBJ_FILTER', payload)
  }
}

const mutations = {
  'SET_OBJ_FILTER' (state, payload) {
    state.objFilter = payload
    delete state.objFilter.page
    delete state.objFilter.pageSize
  },

  'CLEAR_OBJ_FILTER' (state) {
    state.objFilter = {}
  }
}

const getters = {
  objFilter: state => state.objFilter,
}

export default { namespaced: true, state, actions, mutations, getters }