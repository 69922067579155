import http from '@/core/http'
import axios from 'axios'
import config from '@/core/config'

// eslint-disable-next-line
import { map, size, cloneDeep } from 'lodash'

const url = 'entidades/revenda'

const state = {
  itens: [],
  // item:  {
  //   id: '',
  //   cod: '',
  //   email: '',
  //   name: '',
  //   document: '',
  //   address: '',
  //   cep: '',
  //   phone: '',
  //   city: '',
  //   state: '',
  //   neighborhood: '',
  //   number: '',
  //   complement: '',
  //   lat: '',
  //   lng: '',
  //   birth: '',
  //   pix: {
  //     bank: {
  //       code: '',
  //       name: ''
  //     },
  //     keyCode:  {
  //       key: '',
  //       keyEmail: ''
  //     }
  //   },
  //   typeKey: 'CPF/CNPJ'
  // },
  item: {
    parentId: '',
    cod: '',
    name: '',
    email: '',
    cnpj: '',
    cpf: '',
    rg: '',
    birth: '',
    gender: '',
    maritalStatus: '',
    nameSpouse: '',
    birthSpouse: '',
    phone: '',
    familyIncome: '',
    pix: {
      bank: '',
      typeKey: 'celular',
      keyCode: {
        key: '',
        keyEmail: ''
      }
    },
    adresses: {
      item1: {
        typeAddress: 'commercial',
        typeSituation: '',
        cep: '',
        address: '',
        neighborhood: '',
        number: '',
        state: '',
        city: '',
        cepValidate: false,
        complement: ''
      }
    },
    accounts: {
      item1: {
        typeAccount: '',
        bank: '',
        agency: '',
        account: ''
      }
    },
    children:  {},
    photos: {
      item1: {
        description: '',
        image: '',
        nameImage: '',
        type: ''
      }
    },
    fotosDeletadas: [],
    jsonFlags: {
      payerOfPrizesByPix: false
    }
  },
  lista: '',
  itensSelect: [],
  itensPoducts: [],
  itemEntityProducts: {
    productId: '',
    entityId: '',
    comission: 0.00,
    debtLimit: 0.00,
  },
  itensEntityProducts: [],
  totalItens: 0,
  totalItemSelect: 0,
  listaCaixa: [],
  totalCaixa: 0,
  listBanks: [],
  qtdItemForm: 1,
  qtdItemFormChildren: 0
}

const actions = {
  getItens: async ({ commit }, payload) => {
    const headers = { moduloname: 'gestao' }
    const result = await http.get(`${url}`,  { params: payload, headers })
    commit('LISTA_ITENS', result.data)
    return Promise.resolve(result)
  },

  cadastrarItem: async (_, payload) => {
    const result = await http.post(`${url}`, payload)
    return Promise.resolve(result)
  },

  editarItem: async (_, payload) => {
    const result = await http.put(`${url}`, payload)
    return Promise.resolve(result.data)
  },

  deletarItem: async (_, payload) => {
    const result = await http.delete(`${url}`, { params: payload })
    return Promise.resolve(result.data)
  },

  buscarItem: async ({ commit }, payload) => {
    const result = await http.post(`${url}/view`, payload, { moduloname: 'gestao' })
    commit('LISTA_ITEM', result.data)
  },

  getItem: ({ commit }, payload) => {
    commit('LISTA_ITEM', payload)
  },

  limparItem: ({ commit }) => {
    commit('LIMPAR_ITEM')
  },
  obterItensSelect: async ({ commit }, payload) => {
    if (payload.hasOwnProperty('_filter')) {
      payload['_filter'] = Object.assign(payload['_filter'], { 'entityTypeId_In': '3' })
    } else {
      payload['_filter'] = { 'entityTypeId_In': '3' }
    }

    const headers = { moduloname: 'gestao' }
    const result = await http.get('entidades', { params: Object.assign(payload, { order: { 'cod::int': 'ASC' }}), headers })
    commit('LISTA_ITENS_SELECT', result.data)
    return Promise.resolve(result)
  },
  
  // Entitys Products
  obterEntityProduct: async ({ commit }, payload) => {
    const result = await http.get('produtos/product-entities', {params: payload})
    const resultMap = map(result.data.data, item => {
      return {
        id: item.id,
        productId: item.productId,
        entityId: item.entityId,
        comission: parseFloat(item.comission),
        debtLimit: parseFloat(item.debtLimit),
        product: item.product
      }
    })
    commit('LISTA_ENTITIES_PRODUCTS', resultMap)
    return Promise.resolve(result)
  },

  cadastrarEntityProduct: async (_, payload) => {
    const result = await http.post(`produtos/product-entities`, payload)
    // if (!result.data.success) throw result
    return Promise.resolve(result)
  },

  editEntityProduct: async (_, payload) => {
    const result = await http.put(`produtos/product-entities`, payload)
    // if (!result.data.success) throw result
    return Promise.resolve(result)
  },

  editEntityProductLimit: async (_, payload) => {
    const result = await http.put(`produtos/product-entities/edit-limit`, payload)
    // if (!result.data.success) throw result
    return Promise.resolve(result)
  },

  getEntityProduct: ({ commit }, payload) => {
    commit('LISTA_ENTITIE_PRODUCT', payload)
  },

  obterItensSelectProducts: async({commit}) => {
    const result = await http.get('produtos/products')
    commit('LISTA_PRODUCTS', result.data.resultMap)
  },

  deletarItemEntityProduct: async (_, payload) => {
    const result = await http.delete(`produtos/product-entities`, { params: payload })
    // if (!result.data.success) throw result
    return Promise.resolve(result)
  },

  limparItemEntityProduct: ({ commit }) => {
    commit('LIMPAR_ITEM_ENTITY_PRODUCT')
  },
  
  clearItens: ({ commit }) => {
    commit('CLEAR_ITENS')
    commit('LIMPAR_ITEM_ENTITY_PRODUCT')
    commit('LIMPAR_ITEM')
  },

  getCaixas: async ({ commit }, payload) => {
    if (!payload) payload = {}

    // let _filter = { 'deleted_>=':'01/01/1971' }

    // if (payload.hasOwnProperty('_filter')) {
    //   payload._filter = merge(payload._filter, _filter)
    // }

    const result = await http.get('entidades/caixa', { params: Object.assign(payload, {})})
    commit('LISTA_CAIXA', result.data)
  },

  addFilterListCaixa ({ commit }, payload) {
    commit('ADD_FILTER_LIST_CAIXA', payload)
  },

  getBankList: async ({ commit }) => {
    const listBanks = await axios.get(config.apiBankList)
    commit('ADD_LIST_BANKS', listBanks.data)
    return Promise.resolve(listBanks)
  },

  setQtdItemForm: async ({ commit }) => {
    commit('SET_QTD_ITEM_FORM')
  },

  setQtdItemFormChildren: async ({ commit }) => {
    commit('SET_QTD_ITEM_FORM_CHILDREN')
  },


  solicitarSenha: async (_, payload) => {
    const result = await http.post(`entidades/revenda/solicitar-codigo`, payload)
    // if (!result.data.success) throw result
    return Promise.resolve(result)
  },

  validarSenha: async (_, payload) => {
    const result = await http.post(`entidades/revenda/validar-codigo`, payload)
    // if (!result.data.success) throw result
    return Promise.resolve(result)
  }
}

const mutations = {
  'LISTA_ITENS' (state, payload) {
    state.itens = payload.resultMap
    state.totalItens = payload.totalItens
  },

  'LISTA_CAIXA' (state, payload) {
    state.listaCaixa = payload.resultMap
    state.totalCaixa = payload.totalItens
  },

  'ADD_FILTER_LIST_CAIXA' (state, payload) {
    state.filterListCaixa = payload
  },

  'LISTA_ITEM' (state, payload) {
    const item = Object.assign({}, payload)
    if (item.pix) {
      const keycode = item.pix.keyCode.key
      item.pix.keyCode.keyEmail = item.pix.keyCode.type === 'e-mail' ? keycode : ''
      item.pix.keyCode.key = item.pix.keyCode.type !== 'e-mail' ? keycode : ''
    } else {
      item.pix = {
        typeKey: 'celular',
        bank: {
          code: '',
          name: ''
        },
        keyCode:  {
          key: '',
          keyEmail: ''
        }
      }
    }

    
    const addresses = {}
    item.adresses && item.adresses.forEach((it, idx) => {
      addresses[`item${idx+1}`] = {
        typeAddress: it.typeAddress,
        typeSituation: it.typeSituation,
        cep: it.cep,
        address: it.address,
        neighborhood: it.neighborhood,
        number: it.number,
        state: it.state,
        city: it.city,
        cepValidate: false,
        complement: it.complement
      }
    })

    item.adresses = size(addresses) ? addresses : {
      item1: {
        typeAddress: '',
        typeSituation: '',
        cep: item.cep,
        address: item.address,
        neighborhood: item.neighborhood,
        number: item.number,
        state: item.state,
        city: item.city,
        cepValidate: false
      }
    }

    const accounts = {}
    item.accounts && item.accounts.forEach((it, idx) => {
      accounts[`item${idx+1}`] = {
        typeAccount: it.typeAccount,
        bank: it.bank,
        agency: it.agency,
        account: it.account
      }
    })

    item.accounts = size(accounts) ? accounts : {
      item1: {
        typeAccount: '',
        bank: '',
        agency: '',
        account: ''
      }
    }


    const children = {}
    item.children && item.children.forEach((it, idx) => {
      children[`item${idx+1}`] = {
        name: it.name,
        birth: it.birth
      }
    })

    item.children = children
    state.qtdItemFormChildren = size(children)

    const photos = {}
    item.photos && item.photos.forEach((it, idx) => {
      const image = cloneDeep(it)
      photos[`item${idx+1}`] = {
        description: image.description,
        originalImage: image.image,
        image: getUrlImage(image.image),
        nameImage: image.nameImage,
        type: 'image',
        isRegister: true
      }
    })

    item.photos = size(photos) ? photos : {
      item1: {
        description: '',
        image: '',
        nameImage: '',
        isRegister: false
      }
    }

    state.qtdItemForm = size(photos)

    item.fotosDeletadas = []
    item.phone = item.phone ? item.phone.substr(2) : ''
    state.item = item
  },

  'LIMPAR_ITEM' (state) {
    state.item = {
      parentId: '',
      cod: '',
      name: '',
      email: '',
      document: '',
      cnpj: '',
      cpf: '',
      rg: '',
      birth: '',
      gender: '',
      maritalStatus: '',
      nameSpouse: '',
      birthSpouse: '',
      phone: '',
      familyIncome: '',
      pix: {
        bank: '',
        typeKey: 'e-mail',
        keyCode: {
          key: '',
          keyEmail: ''
        }
      },
      adresses: {
        item1: {
          typeAddress: 'commercial',
          typeSituation: '',
          cep: '',
          address: '',
          neighborhood: '',
          number: '',
          state: '',
          city: '',
          cepValidate: false,
          complement: ''
        }
      },
      accounts: {
        item1: {
          typeAccount: '',
          bank: '',
          agency: '',
          account: ''
        }
      },
      children:  {},
      photos: {
        item1: {
          description: '',
          image: '',
          nameImage: '',
          type: ''
        }
      },
      jsonFlags: {
        payerOfPrizesByPix: false
      }
    }
  },

  'LISTA_ITENS_SELECT' (state, payload) {
    state.itensSelect = payload.resultMap
    // state.totalItemSelect = payload.totalItens
  },

  'LISTA_PRODUCTS' (state, payload) {
    state.itensPoducts = payload
  },

  'LISTA_ENTITIES_PRODUCTS' (state, payload) {
    state.itensEntityProducts = payload
  },

  'LISTA_ENTITIE_PRODUCT' (state, payload) {
    state.itemEntityProducts = Object.assign({}, payload)
  },

  'LIMPAR_ITEM_ENTITY_PRODUCT' (state) {
    state.itemEntityProducts = {
      productId: '',
      entityId: '',
      comission: 0.00,
      debtLimit: 0.00,
    }
  },

  'CLEAR_ITENS' (state) {
    state.itens = []
    state.itensSelect = []
    state.itensPoducts = [],
    state.itensEntityProducts = [],
    state.totalItens = 0
  },

  'ADD_LIST_BANKS' (state, payload) {
    state.listBanks = payload
  },

  'SET_QTD_ITEM_FORM' (state) {
    state.qtdItemForm++
  },

  'SET_QTD_ITEM_FORM_CHILDREN' (state) {
    state.qtdItemFormChildren++
  }
}

const getters = {
  listaItens: state => state.itens,
  totalItens: (state) => state.totalItens,
  totalItemSelect: (state) => state.totalItemSelect,
  item: state => state.item,
  itemEntityProducts: state => state.itemEntityProducts,
  listItensEntitiesPoducts: state => state.itensEntityProducts,
  listaItensSelect: state => state.itensSelect,
  listaItensSelectCredenciador: state => state.itensSelect.map( item => ({
    text: `(${item.cod}) - ${item.name}`,
    value: item.id
  })),
  listProducts: (state) => {
    return map(state.itensPoducts, item => {
      return {
        text: item.name,
        value: item.id
      }
    })
  },
  listaCaixa: state => state.listaCaixa,
  totalCaixa: state => state.totalCaixa,
  filterListCaixa: state => state.filterListCaixa,
  listBanks: state => {
    if (Array.isArray(state.listBanks)) {
    return state.listBanks.filter(it => it.code).map(it => ({
        code: it.code, 
        name: it.name, 
        text: it.code + ' - ' + it.fullName
      }))
    }
    return [{
      code: '',
      name: '',
      text: '',
    }]
  },
  qtdItemForm: state => state.qtdItemForm,
  qtdItemFormChildren: state => state.qtdItemFormChildren
  // typeKey: state => state.typeKey,
}


function getUrlImage(image) {
  return image.indexOf(';base64') === -1 ? `${config.urlAWS}/${image}` : image
}

// function documentOurPhone(keyCode) {
//   return (isValidCpfOrCnpj(keyCode) || isValidPhone(keyCode)) ? keyCode : ''
// }

// function email(keyCode) {
//   return (isValidEmail(keyCode)) ? keyCode : ''
// }

// function getType(keyCode) {
//   if (isValidCpfOrCnpj(keyCode)) return 'CPF/CNPJ'
//   if (isValidPhone(keyCode)) return 'celular'
//   return 'e-mail'
// }

export default { namespaced: true, state, actions, mutations, getters }